import Loading from "../../components/loading.jsx"

function Video(){
        return <>
           <Loading />   
        </>
    }

export default Video;


