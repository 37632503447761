import {useState} from 'react';
import api from "../../services/api.js"
import {Link} from "react-router-dom";
import "./alterar.css";


function Alterar(){
             
        const [Var_Input, set_Var_Input] = useState("");
        const [Var_Input2, set_Var_Input2] = useState("");
        const [Var_Input3, set_Var_Input3] = useState("");
     
        function func_Alterar(){
            api.put("/empresa_alterar/" + Var_Input , {               
                localidade: Var_Input2,
                uf: Var_Input3
            })
            .then((retorno) => {
                   alert("Alteração Realizada")
            })
            .catch(err => console.log(err));
        }
    
        return <div>              

            <div>
                <h1>Alterar</h1>  
                <Link to="/" className="btn-voltar">Voltar</Link>                        
                <br></br>
                <br></br>

                <h3>Digite o Id da Empresa </h3> 
                <input type="text" onChange={(e)=>set_Var_Input(e.target.value)} />                                        
                <br></br> <br></br>

                <h3>Nome da Empresa a Alterar</h3> 
                <input type="text" onChange={(e)=>set_Var_Input2(e.target.value)} />                                        
                <br></br> <br></br>

                <h3>UF da Empresa a Alterar</h3> 
                <input type="text" onChange={(e)=>set_Var_Input3(e.target.value)} />                                        
                <br></br> <br></br>

                <button type="button" className="btn-voltar" onClick={(e) =>func_Alterar(Var_Input)}>Alterar Registro</button>
                <br></br> <br></br> 
          

            </div>  

    
        </div>

}

export default Alterar;

