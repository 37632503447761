import {Link} from "react-router-dom";
import "./vendas.css";
import api from "../../services/api.js";
import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Swal from 'sweetalert2';    //Alertas
import {Navigate} from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Dock } from "react-dock";
import { mask, unMask } from 'remask';  //mascaras
import Lista_Empregados from "../lista_empregados/lista_empregados.jsx";
import Lista_Produtos from "../lista_produtos/lista_produtos.jsx";
import Lista_Clientes from "../lista_clientes/lista_clientes.jsx";

import {useRef} from 'react';

function Vendas(){
    

    const {id_usuario, nome, focus, setFocus, setShow3, setShow4, setShow2, setShow, Logado, nome_empregado_cont, cod_empregado_cont, setCod_empregado_cont, 
         nome_cliente_cont, cod_cliente_cont, setCod_cliente_cont, nome_produto_cont, cod_produto_cont, setProduto_cont, setCod_produto_cont, set_Nome_cliente_cont} = use(MeuContexto);
    
    const [v_produto, setV_produto] = useState("");      
    const [v_comissao, setV_comissao] = useState("");
    const [v_perc_desc_max, setV_perc_desc_max] = useState("");
    const [v_preco_venda_formatada, setV_preco_venda_formatada] = useState('R$ 0,00'); 
    const [v_preco_venda_formatada_2, setV_preco_venda_formatada_2] = useState('R$ 0,00'); 
    const [v_preco_venda, setV_preco_venda] = useState("");  

    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString();

    const [Var_Input5, set_Var_Input5] = useState("");
    const [Var_Input6, set_Var_Input6] = useState("");

    const [Var_Input9, set_Var_Input9] = useState("");

    const [dinheiro, setDinheiro] = useState(0);
    const [cartao, setCartao] = useState(0);
    const [PIX, setPIX] = useState(0);
    const [vale, setVale] = useState(0);

    const [total_venda, setTotal_venda] = useState(0);
    const [total_venda_reais, setTotal_venda_reais] = useState('R$ 0,00');
    const [total_pagamento, setTotal_pagamento] = useState(0);
    const [total_pagamento_reais, setTotal_pagamento_reais] = useState('R$ 0,00');
   
    const [valor_digitado, setValor_digitado] = useState({ input_cartao: "", input_pix: "", input_dinheiro: "", input_vale: "" });

    const [abre_tela, setAbre_tela] = useState(false);

    const [linkar, setLinkar] = useState('');


    //const Func_Ao_Mudar_Input = (e) => {
    //    setValor_digitado({ ...valor_digitado, [e.target.name]: e.target.value})        
    //} 

    const [valor_digitado_cartao, setValor_digitado_cartao] = useState('');

    const Func_Ao_Mudar_Input_Cartao = (e) => {
        setValor_digitado_cartao(e.target.value);        
    }

    const [valor_digitado_pix, setValor_digitado_pix] = useState('');

    const Func_Ao_Mudar_Input_Pix = (e) => {
        setValor_digitado_pix(e.target.value);        
    }

    const [valor_digitado_dinheiro, setValor_digitado_dinheiro] = useState('');

    const Func_Ao_Mudar_Input_Dinheiro = (e) => {
        setValor_digitado_dinheiro(e.target.value);        
    }

    const [valor_digitado_vale, setValor_digitado_vale] = useState('');

    const Func_Ao_Mudar_Input_Vale = (e) => {
        setValor_digitado_vale(e.target.value);        
    }

    
        //trocar proprietades dos inputs
         const myinput = useRef(); 

         const inputfocus = () => {            
            //myinput.current.value = cod_cliente_cont;   
            //set_Var_Input5(cod_cliente_cont);
            myinput.current.focus();                     
         }

         const myinput_1 = useRef();

         const inputfocus_1 = () => {
            //myinput_1.current.value = cod_empregado_cont;
            //set_Var_Input7(cod_empregado_cont);            
            myinput_1.current.focus();            
         }

         const myinput_2 = useRef();

         const inputfocus_2 = () => {
            myinput_2.current.value = cod_produto_cont;
            set_Var_Input9(cod_produto_cont);
            myinput_2.current.focus();            
         }

         
         const inputfocus_B = () => {
            myinput_2.current.focus();            
         }

         const inputfocus_C = () => {
            myinput_2.current.value = "";           
         }


         const myinput_x1 = useRef();

         const inputfocus_X = () => {
            myinput_x1.current.value = "";           
         }


        

      

        useEffect(() =>{

            func_Receber_lista_Venda_Cliente(cod_cliente_cont);

                       

            setDinheiro(0);
            setCartao(0);
            setPIX(0);
            setVale(0);
            setTotal_pagamento(0);
            setTotal_venda(0);

            if (Logado == false){
                Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                 
            } 

            //setFocus('0');
                               
            // So roda 1 vez ao abrir a tela

            //pra tecla de atalho            
            //document.addEventListener('keydown', detecta_aperta_teclado, true)
            
          
            if (cod_cliente_cont != 0) {

                func_traz_cliente(cod_cliente_cont); 
                func_Receber_lista_Cliente(cod_cliente_cont);
                func_Receber_lista_Venda_Cliente(cod_cliente_cont);
            }            
            
           
        },[]);
       

        useEffect(() =>{
            if (focus==="1"){           
                inputfocus(); 

            } 
        
            if (focus==="2"){           
                inputfocus_1();
                
                if (cod_empregado_cont != '') {    
                    func_traz_comissionado(cod_empregado_cont); 
                }


            }

            if (focus==="3"){ 
                func_traz_comissionado(cod_empregado_cont);          
                inputfocus_2();               
            }

        },[focus]); 

 
        
        async function limpa_dados_apos_pagamento() {
                    
            inputfocus(); 

            set_Var_Input5('');
            set_Var_Input7('');
            set_Var_Input9('');
            set_Var_Input6('');
            set_Var_Input8('');
            setV_produto("");
            setV_comissao("");
            setV_perc_desc_max("");
            setV_preco_venda_formatada("");
            setV_preco_venda_formatada_2("");

            setCod_cliente_cont("");

            setTotal_pagamento("");
            setDinheiro(0);
            setCartao(0);
            setPIX(0);
            setVale(0);
            setTotal_pagamento(0);
            setTotal_pagamento_reais('R$ 0,00');
            setTotal_venda(0);

            await func_Traz_saldo();

            await func_Deleta_Vendas_de_Tab_Venda()
            
            await func_Receber_lista_Cliente()

            await func_Receber_lista_Venda_Cliente();

            setValor_digitado_cartao("");
            setValor_digitado_pix("");
            setValor_digitado_dinheiro("");
            setValor_digitado_vale("");

        }

        async function func_Deleta_Vendas_de_Tab_Venda(){
            
            api.delete("/deletar_vendas_do_cliente/" + Var_Input5)            
            .then((retorno) => {                  
                        func_Receber_lista_Cliente();
                        func_Receber_lista_Venda_Cliente();    
                }) 
                .catch(err => console.log(err));
        }
            

        const detecta_aperta_teclado = (e) => { 
                           
           
               if (e.key === "Shift") {

                  

                inputfocus();   //setfous                 

                set_Var_Input5('');
                set_Var_Input7('');
                set_Var_Input9('');
                set_Var_Input6('');
                set_Var_Input8('');
                setV_produto("");
                setV_comissao("");
                setV_perc_desc_max("");
                setV_preco_venda_formatada("");
                setV_preco_venda_formatada_2("");
                setCod_cliente_cont("");
                setTotal_pagamento("");
                func_Receber_lista_Cliente();
                func_Receber_lista_Venda_Cliente();
                setTotal_pagamento_reais('R$ 0,00');                 
                  
                } 
                
        }  
   

 //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
      {/*}
       // function clickCadastrarCliente(){
       //     setShow(true);  
       //     <Cadastro_de_Clientes /> 
       // } 
        
        function clickSelecionarCliente(){
            setShow2(true);            
            <Lista_Clientes />
            
        } 

        function clickSelecionarProfissional(){
            setShow3(true);
            <Lista_Empregados />             
        }

        function clickSelecionarProdutos(){
            setShow4(true);
            <Lista_Produtos />             
        }
    */}
//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

function func_Abre_Form_Cliente() {
   
    setLinkar('Pagina_Lista_Cliente');
    setShow2(true); 

}

function func_Abre_Form_Empregados() {
   
    setLinkar('Pagina_Lista_Empregados');
    setShow3(true); 

}

function func_Abre_Form_Produtos() {
   
    setLinkar('Pagina_Lista_Produtos');
    setShow4(true); 

}

//kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk


function Acao_01(ObterDados){                     
    
            //set_Var_Input5(setCod_cliente_cont);  //Levar o codigo para o Input 
                          
            var Var_1 = ObterDados.get("input_codigo");

            setCod_cliente_cont(Var_1);

                   

            if (Var_1 === "") {   
                
                    func_Abre_Form_Cliente();                                        
                    
                    setFocus('0');  //ao abrir o list Cliente Zera o focus                   
                    func_Receber_lista_Cliente(Var_1);
                    func_Receber_lista_Venda_Cliente(); 
                                      
                    
            } else 
            {                
                    myinput.current.value = Var_1;
                    set_Var_Input5(Var_1);

                    inputfocus_1();  // vai para                 
                    func_traz_cliente(Var_1); 
                    func_Receber_lista_Cliente(Var_1);
                    func_Receber_lista_Venda_Cliente(); 
                                  
            } 
            
            //func_Total_2(Var_1);

            
            set_Var_Input7('');
            set_Var_Input9('');
            set_Var_Input6('');
            set_Var_Input8('');
            setV_produto("");
            setV_comissao("");
            setV_perc_desc_max("");
            setV_preco_venda_formatada("");
            setV_preco_venda_formatada_2("");            
            setTotal_pagamento("");
            setTotal_pagamento_reais('R$ 0,00'); 

            inputfocus_C();
            
            setCod_produto_cont("");
            
          
                                    
           
}


function Acao_02(ObterDados){ 
    
    set_Var_Input7(setCod_empregado_cont);    
    
    var Var_2 = ObterDados.get("input_profissional") 

    setCod_empregado_cont(Var_2); 

    setCod_produto_cont("");
    
    inputfocus_C();
    

    if (Var_2 === "") {         
            func_Abre_Form_Empregados();
            setShow3(true);
            setFocus('1');  //ao abrir o list Cliente Zera o focus           
    } else
    {       
            inputfocus_2();  // vai para                 
            func_traz_comissionado(Var_2);     
    }    
}

function Acao_03(ObterDados){ 
    
    set_Var_Input9(setCod_produto_cont);                
    
    var Var_3 = ObterDados.get("input_produto")        

    if (Var_3 == "") { 
            func_Abre_Form_Produtos();          
            setShow4(true);               
            setFocus('2');            
    } else
    {       
            func_traz_produto(Var_3);                 
    }     
    setCod_produto_cont("");   
}




const func_Total_2 = async (Var_1) => {        
  
    try{       
        
        set_Var_Input9('');

        let Var_Aqui='';            

        if (cod_cliente_cont > 0) {
            Var_Aqui = cod_cliente_cont;
        } 

        if (Var_Input5 > 0) {
            Var_Aqui = Var_1;
        } 

            await api.post("/traz_total" , {
                cod_cliente:  Var_Aqui               
           })
           .then((response) => {                                                   
               
                setTotal_venda(response.data.total_venda);       
                setTotal_venda_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.total_venda))                      
                //Tot_venda_reais=(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.total_venda))                      
           })                          

            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                    console.log(error);
            }                         
    }

   


var V_Dinheiro=dinheiro;

function Acao_04_A(ObterDados){              
        const V1 = parseFloat(ObterDados.get('input_dinheiro'));  
        V_Dinheiro = parseFloat(ObterDados.get('input_dinheiro'));              
        setDinheiro(V1);        
        const V2 = (V1 + cartao + PIX + vale)
        setTotal_pagamento(V2);
        setTotal_pagamento_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(V2));

        if (V2 == total_venda) {
                
                limpa_dados_apos_pagamento();
            } 
        if (V2 != total_venda) {
            Swal.fire({icon: 'error',  title: 'Valor Pago', text: 'Diferente das Vendas!'}) ;                       
            } 
    }

var V_Cartao=cartao;

function Acao_04_B(ObterDados){    
        const V1 = parseFloat(ObterDados.get('input_cartao'));
        V_Cartao = parseFloat(ObterDados.get('input_cartao'));
        setCartao(V1);
        const V2 = (dinheiro + V1 + PIX + vale)
        setTotal_pagamento(V2);
        setTotal_pagamento_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(V2));

        

        if (V2 == total_venda) {
                
                limpa_dados_apos_pagamento();
            } 
        if (V2 != total_venda) {
            Swal.fire({icon: 'error',  title: 'Valor Pago', text: 'Diferente das Vendas!'}) ;
            } 
    }

var V_Pix=PIX;

function Acao_04_C(ObterDados){    
    const V1 = parseFloat(ObterDados.get('input_pix'));
    V_Pix = parseFloat(ObterDados.get('input_pix'));
    setPIX(V1);
    const V2 = (dinheiro + cartao + V1 + vale)
    setTotal_pagamento(V2);
    setTotal_pagamento_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(V2));

    if (V2 == total_venda) {
            
            limpa_dados_apos_pagamento();
        } 
    if (V2 != total_venda) {
        Swal.fire({icon: 'error',  title: 'Valor Pago', text: 'Diferente das Vendas!'}) ; 
        }     
    }

var V_Vale = vale;

function Acao_04_D(ObterDados){    
    const V1 = parseFloat(ObterDados.get('input_vale'));
    V_Vale = parseFloat(ObterDados.get('input_vale'));
    setVale(V1);
    const V2 = (dinheiro + cartao + PIX + V1)
    setTotal_pagamento(V2);
    setTotal_pagamento_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(V2));

    if (V2 == total_venda) {
            
            limpa_dados_apos_pagamento();
        } 
    if (V2 != total_venda) {
        Swal.fire({icon: 'error',  title: 'Valor Pago', text: 'Diferente das Vendas!'}) ; 
        }
    }

      
   

const func_traz_cliente = async (Var_1) => {

        try{
        
          await api.post("/traz_cliente" , {
               cod: Var_1
          })
          .then((response) => {                                                   
              
            set_Var_Input5(response.data.cod);  

            set_Var_Input6(response.data.nome);           
              
          })         
          
    
       } catch(error){  
                        inputfocus();          
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'Código do Cliente Inválido!'}) ;
                        
                     }
       }

const func_traz_comissionado = async (Var_2) => {

        try{
        
          await api.post("/traz_comissionado" , {
               cod_empregado: Var_2
          })
          .then((response) => {                                                   
              
            set_Var_Input7(response.data.cod_empregado);  

            set_Var_Input8(response.data.nome_empregado);           
              
          })         
          
    
       } catch(error){  
                        inputfocus_1();          
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'Código do Comissionado Inválido!'}) ;
                        
                     }
    }

var X_preco="";
var X_comissao="";
var X_cod_produto="";

const func_traz_produto = async (Var_3) => {        

        try{
        
          await api.post("/traz_produto" , {
               cod_produto: Var_3               
          })
          .then((response) => {                                                   
              
            set_Var_Input9(response.data.cod_produto);           
            setV_produto(response.data.produto);            
            setV_preco_venda(response.data.preco_venda);
            setV_comissao(response.data.comissao);
            setV_perc_desc_max(response.data.perc_desc_max);
            setV_preco_venda_formatada_2(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.preco_venda));
            setV_preco_venda_formatada(response.data.preco_venda);
            X_preco = response.data.preco_venda;
            X_comissao = response.data.comissao;
            X_cod_produto = response.data.cod_produto;
                              
          }) 

                    if (X_preco>0){
                        func_Incluir_Venda(Var_3);
                    }         
          
    
       } catch(error){  
                        //inputfocus();          
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'Código do Serviço Inválido!'}) ;
                        //inputfocus_2();                      
                     }
       }


const func_Incluir_Venda = async () => {

        try{
        
           await api.post("/cadastrar_venda" , {             
                preco_venda: X_preco,
                comissao: X_comissao,
                desconto : '0',      // implementar depois com inclusive com Dec. Max Permitido
                cod_cliente : Var_Input5,
                cod_comissionado : Var_Input7,
                cod_produto : X_cod_produto,
                caixa : id_usuario 
                })  

          .then((response) => {                                                   
                  //Swal.fire({icon: 'error',  title: 'Erro', text: 'Sucesso'}) ;  
                  func_Receber_lista_Cliente();
                  func_Receber_lista_Venda_Cliente();          
          })  
          
          //await func_Receber_lista_Cliente();
          //await func_Receber_lista_Venda_Cliente();
    
       } catch(error){  
                        inputfocus();          
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'Cadastramento Não Realizado'}) ;

                        setV_produto('');  
                        setV_preco_venda('');
                        setV_comissao('');
                        setV_perc_desc_max('');
                        setV_preco_venda_formatada("");
                        setV_preco_venda_formatada_2("");

                        set_Var_Input9("");
                        
                     }
       }



const onChangeCodigo = e => {
           // set_Var_Input5(e.target.value);  
            set_Var_Input6('');       
    };

const [Var_Input7, set_Var_Input7] = useState("");
const [Var_Input8, set_Var_Input8] = useState(""); 


const onChangeProfi = e => {
            set_Var_Input7(e.target.value);
            set_Var_Input8('');  
    };


const onChangeProduto = e => {
        set_Var_Input9(e.target.value);
        setV_produto('');  
        setV_preco_venda('');
        setV_comissao('');
        setV_perc_desc_max('');
        setV_preco_venda_formatada("");
        setV_preco_venda_formatada_2("");

};

    //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

    const [lista, setLista] = useState([]);

    const func_Receber_lista_Cliente = async (Var_1) => {
  
        try{
            
            set_Var_Input9('');

            let Var_Aqui='';            

            if (cod_cliente_cont > 0) {
                Var_Aqui = cod_cliente_cont;
            } 

           
            if (Var_1 > 0) {
                Var_Aqui = Var_1;
            }           

          

                  //Consulta detalhe clientes indicados
                  await api.get("/listar_vendas_grid_1?cod_cliente=" +  Var_Aqui)   
                  
                  .then((response) => { 
                      setLista(response.data);          
                      
                  }) 
                  await func_Total();               
    
                //Se der ERRO em qualquer das Consultas
                } catch(error){          
                        console.log(error);
                }               
                            
        }

     //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

     const [lista_2, setLista_2] = useState([]);

     const func_Receber_lista_Venda_Cliente = async () => {

        try{               

            await api.get("/listar_vendas_clientes")
                .then((response) => { 
                 setLista_2(response.data);                     
            })            
           

             //Se der ERRO em qualquer das Consultas
              } catch(error){          
              console.log(error);
        }


        

          
    }   

     //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk


     const func_Total = async () => {
        
  
        try{

           
            
            set_Var_Input9('');

            let Var_Aqui='';            

            if (cod_cliente_cont > 0) {
                Var_Aqui = cod_cliente_cont;
            } 

            if (Var_Input5 > 0) {
                Var_Aqui = Var_Input5;
            }  
            
            

            

                
                await api.post("/traz_total" , {
                    cod_cliente:  Var_Aqui               
               })
               .then((response) => {                                                   
                   
                setTotal_venda(response.data.total_venda);                                         
            
                setTotal_venda_reais(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.total_venda))                      
              
                })

                               
    
                //Se der ERRO em qualquer das Consultas
                } catch(error){          
                        console.log(error);
                }  
                
                
                            
        }

        //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

        function clickDeletar(e) {
            
            Swal.fire({icon: 'question',  //success, error, warning, info, question 
            title: 'Pergunta', 
            text: 'Deseja realmente Excluir esse Registro?',
            showDenyButton: true,
            denyButtonText:"Não",
            confirmButtonText: "Sim",
            confirmButtonColor: "blue",

        }).then(response => {
            if(response.isConfirmed){

            api.delete("/deletar_venda/" + e)            
                .then((retorno) => {                               
                             
                            
                            func_Receber_lista_Cliente(); 

                            func_Receber_lista_Venda_Cliente(); 
                            
                            inputfocus_B();

                            //Swal.fire({icon: 'success',  title: 'Exclusão', text: 'Realizada com Sucesso'})
        
                    }) 
                    
                    

                    .catch(err => console.log(err));                   

            }else if(response.isDenied){
                
                inputfocus_B();

                // Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})

            }               
        })


        }


     const columns_5 = [
        {
            cell: (row) => <>                       
       
                 <button className="btn btn-outline-primary" type="button" onClick={() => clickDeletar(row.id_venda)} > X </button>
            </>,
            width: "50px",
            compact: true,
            left: true   //Colocar Botão a Direita
          },          
        {
            name: 'SERVIÇO',
            selector: row => row.produto,
            maxwidth: "300px",   //largura 
            compact: true,
            sortable: false            //cria ordenação
        },        
        {
            name: 'DESC.',          
            selector: row => row.preco_venda,
            width: "100px",  
            sortable: false,            //cria ordenação 
            right: true,
            compact: true,
            format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.desconto)
        },        
        {
            name: 'PREÇO',          
            selector: row => row.preco_venda,
            width: "100px",  
            sortable: false,            //cria ordenação 
            right: true,
            compact: true,  
            format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.preco_venda)
        },
        {
            cell: (row) => <>       
                 <button className="btn btn-outline-primary" type="button" onClick={() => clickDesconto("IDIOTA" + row.id_venda + "SERVICO" + row.produto + "PRECO" + row.preco_venda + "DESCONTO" + row.perc_desc_max + "FIM")} > - </button>
            </>,
            width: "50px",
            compact: true,          
            right: true
        },
          {
            name: '',
            selector: row => row.id_venda,
            width: "0px",   //largura
            compact: true,            
            sortable: false            //cria ordenação 
            
        },
        {
            name: '',
            selector: row => row.perc_desc_max,
            width: "0px",   //largura
            compact: true, 
            sortable: false            //cria ordenação 
            
        }
       
    ]
    //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
    const [id, setId] = useState(""); 
    const [produto, setProduto] = useState("");
    const [preco, setPreco] = useState("");
    const [max, setMax] = useState("");

    function clickDesconto(e){
        setAbre_tela(true);

        //pegar o Código
        var PosInicial=e.indexOf("IDIOTA")+6
        var PosFinal=e.indexOf("SERVICO")     
        setId(e.substring(PosInicial, PosFinal))

        var PosInicial=e.indexOf("SERVICO")+7
        var PosFinal=e.indexOf("PRECO")     
        setProduto(e.substring(PosInicial, PosFinal))

        var PosInicial=e.indexOf("PRECO")+5
        var PosFinal=e.indexOf("DESCONTO")     
        //setPreco(e.substring(PosInicial, PosFinal))

        setPreco(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.substring(PosInicial, PosFinal)));

        var PosInicial=e.indexOf("DESCONTO")+8
        var PosFinal=e.indexOf("FIM")     
        setMax(e.substring(PosInicial, PosFinal))

    }

    function clickFechar(){
        setAbre_tela(false);
    }


    //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

    const columns_2 = [

 
        {
            name: 'Código',
            selector: row => row.cod_cliente,
            width: "100px",   //largura
            sortable: false,            //cria ordenação 
            compact: true
            
        },            
        {
            name: 'CLIENTE',
            selector: row => row.nome,
            width: "330px",   //largura 
            sortable: false,
            compact: true           //cria ordenação
        },        
        {
            name: 'PREÇO',          
            selector: row => row.tot_cliente,
            width: "110px",  
            sortable: false,            //cria ordenação 
            right: true,
            compact: true,
            format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.tot_cliente)
        }
    ]

    
    //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
    const [saldo, setSaldo] = useState("0"); 

    var V_Id_Contabil = "";

    async function func_Traz_saldo(){

                var Var_Saldo='0';

                try{
                            await api.post("/traz_saldo")     
                            .then((response) => {                 
                                setSaldo(response.data.saldo); 
                                Var_Saldo = response.data.saldo; 
                                Var_Saldo = Var_Saldo + total_venda;           
                            })               
                    } catch(error){          
                        console.log(error);
                    }

                try{
                      await api.post("/cadastrar_pagamento" , { 
                            saldo: Var_Saldo,
                            valor_total_pago: total_venda,
                            cartao: V_Cartao,
                            pix : V_Pix,
                            dinheiro : V_Dinheiro,
                            vale : V_Vale,
                            caixa : id_usuario 
                            })
                                            

                    .then((retorno) => { 
                        
                        V_Id_Contabil = retorno.data.id_contabil;
                        
                                                                  
                    })

                    } catch(error){          
                        console.log(error);
                    }

               //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

               
                    await api.put("/altera_id_contabil/" + Var_Input5 , {               
                        id_contabil: V_Id_Contabil
                    })
                    .then((retorno) => {
                        
                    })
                     .catch(err => console.log(err));

                //kkkkkkkk-  Inclusão de Tab_Venda em Tab_Vendas_fechadas   -kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk


                await api.post("/Dados_Em_Vendas_Fechadas/" + Var_Input5 )
                .then((retorno) => {
                    
                })
                 .catch(err => console.log(err));

                
                
                //kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

     }

     function Entra_tela(){

        

        return <Dock position="right"
                isVisible={abre_tela}
                fluid={false}
                size={700}     
                >
                
                <br></br>
    
                <div id="css_400" className="container-fluid h-100 pt-4 sidebar p-4 ">
                    <h2>Descontos:</h2>
                    <br></br>
                    <h4>Serviço: {produto}</h4>
                    <h4>Preço: {preco}</h4>
                    <h4>Desconto Máximo: {max}%</h4>
                    <br></br>   
                    <br></br>  
                    <br></br>  
                    <br></br>               
                    <h4>Clique no Desconto a Fornecer ao Item Acima:</h4>                       
                    <br></br> 
                    <div id="css_125">
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click5(max)} type="button" >5%</button>
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click10(max)} type="button">10%</button>   
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click15(max)} type="button">15%</button>                            
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click20(max)} type="button">20%</button>                             
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click25(max)} type="button">25%</button>                             
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div id="css_125">
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click30(max)} type="button" >30%</button>
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click35(max)} type="button">35%</button>   
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click40(max)} type="button">40%</button>                            
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click45(max)} type="button">45%</button>                             
                        </div>
                        <div>
                                <button id="css_126" className="btn btn-outline-primary" onClick={() => click50(max)} type="button">50%</button>                             
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <div id="css_125">
                        <div>
                        <button  id="css_127" className="btn btn-outline-primary" onClick={() => clickZerar()} type="button">Zerar Desconto</button>  
                        </div>
                        <div>
                        <button  id="css_127" className="btn btn-outline-primary" onClick={() => clickFechar()} type="button">Fechar</button>     
                        </div>
                    </div>

                             
                </div>
        </Dock>    
     }
     
     async function alteracao_desconto(desc){

         await api.put("/Atualiza_Desconto", {               
            pega_id_venda: id,
            pega_desconto: desc            
            })
            .then((retorno) => {
               // alteracao Realizada
            })
            .catch(err => console.log(err));
            
            await func_Receber_lista_Cliente();
            await func_Receber_lista_Venda_Cliente();

            setAbre_tela(false);
            
    
        }
         
    async function click5(max) {
        if (parseInt(max)<5) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})           
        }else{   
            let desc = 5;         
            alteracao_desconto(desc);
        }
    } 
    
    function click10(max) {
        if (parseInt(max)<10) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 10;         
            alteracao_desconto(desc);
        }
    }

    function click15(max) {
        if (parseInt(max)<15) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 15;         
            alteracao_desconto(desc);
        }

    }
    function click20(max) {
        if (parseInt(max)<20) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 20;         
            alteracao_desconto(desc);
        }

    }
     
    function click25(max) {
        if (parseInt(max)<25) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 25;         
            alteracao_desconto(desc);
        }
    }    
         
    function click30(max) {
        if (parseInt(max)<30) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 30;         
            alteracao_desconto(desc);
        }
    }
         
    function click35(max) {
        if (parseInt(max)<35) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 35;         
            alteracao_desconto(desc);
        }
    }  
       
    function click40(max) {
        if (parseInt(max)<40) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 40;         
            alteracao_desconto(desc);
        }
    }
    function click45(max) {
        if (parseInt(max)<45) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 45;         
            alteracao_desconto(desc);
        }
    }
     
    function click50(max) {
        if (parseInt(max)<50) {
            setAbre_tela(false);
            Swal.fire({icon: 'error',  title: 'Desconto', text: 'Não Permitido'})
        }else{   
            let desc = 50;         
            alteracao_desconto(desc);
        }
    }    

    function clickZerar() {
            let desc = 0;         
            alteracao_desconto(desc);       
    } 

    function Zera_E_Sai() {

        //inputfocus();   //setfous 
        
        setFocus(1);

        set_Var_Input5('');
        set_Var_Input7('');
        set_Var_Input9('');
        set_Var_Input6('');
        set_Var_Input8('');
        setV_produto("");
        setV_comissao("");
        setV_perc_desc_max("");
        setV_preco_venda_formatada("");
        setV_preco_venda_formatada_2("");
        setCod_cliente_cont("");
        setTotal_pagamento("");
        func_Receber_lista_Cliente();
        func_Receber_lista_Venda_Cliente();
        setTotal_pagamento_reais('R$ 0,00'); 

        setLinkar('Pagina_Menu');  

    }
         



       
             
    
    return (  
   

        <div id="tela_maior">             
                

                {/*<Lista_Produtos />     */}               

                {Logado === false ? <Navigate to='/' /> : null } 

            <div id="tela_esquerda">                               
                              
                       
                            <div id="css_51"> 
                                 <div>
                                      <h5 id="css_52">{nome}</h5>   
                                 </div>
                                 <div>
                                      <h5 id="css_52">{dataFormatada}</h5>    
                                 </div>

                                 <div>                                       
                                      <button className="btn btn-outline-primary" type="button" onClick={() => Zera_E_Sai()} id="largura_botoes_V35" >SAIR</button>
                
                                      {linkar === 'Pagina_Menu' ? <Navigate to='/principal' /> : null} 
                              
                                </div>
                                                                
                            </div>  
                            
                             <br></br>
                             <small className="text-secondary">Código do Cliente</small>       
                             <br></br>
                             <form id="css_50" action={Acao_01}> 
                                <div>
                                    
                                    <input type="text" name="input_codigo" autoFocus onChange={onChangeCodigo} defaultValue={Var_Input5} ref={myinput}/>
                                    {linkar === 'Pagina_Lista_Cliente' ? <Navigate to='/listar_clientes' /> : null}             
                                                                
                                </div>
                                <div>
                                    <h5 id="css_texto_inicial">{Var_Input6}</h5>
                                </div>
                            </form>

                             <br></br>
                             <small className="text-secondary">Código do Comissionado</small>                             
                             <br></br>

                             <form id="css_50" action={Acao_02}> 
                                <div>

                                    <input type="text" name="input_profissional" onChange={onChangeProfi} defaultValue={Var_Input7} ref={myinput_1}/>
                                    {linkar === 'Pagina_Lista_Empregados' ? <Navigate to='/listar_empregados' /> : null}             
                                    
                                </div>
                                <div>
                                       <h5 id="css_texto_inicial">{Var_Input8}</h5>
                                </div>
                            </form>                            
                             
                             <br></br>
                             <small className="text-secondary">Código do Produto</small>                             
                             <br></br>

                             <form id="css_50" action={Acao_03}> 

                                <div>

                                        <input type="text" name="input_produto" onChange={onChangeProduto} defaultValue={Var_Input9} ref={myinput_2}/>
                                        {linkar === 'Pagina_Lista_Produtos' ? <Navigate to='/listar_produtos' /> : null}             
                      
              
                                </div>
                                <div>
                                        <h5 id="css_texto_inicial">{v_produto}</h5>
                                </div>                                
                            
                            </form> 

                            <br></br> 
                            <br></br>
                            

                                 <div id="css_57">
                                    <div>
                                       <h5 id="css_53">Preço: {v_preco_venda_formatada_2}</h5>  
                                    </div>
                                    <div>
                                        <h5 id="css_53">Comissão: {v_comissao}%</h5>   
                                    </div>                                  
                                    <div>
                                    <h5 id="css_53">Desc. Máx.: {v_perc_desc_max}%</h5>
                                    </div>                   
                                    
                                    
                                 </div>

                                    <div id="css_56">
                                        <div>
                                            <h4 id="css_53">Total: {total_venda_reais} </h4>
                                        </div>
                                        <div>
                                            <h4 id="css_53">Pago: {total_pagamento_reais}</h4>   
                                        </div>

                                        
                                        

                                    </div>

                            <br></br>

                            <div id="css_55">


                                <form action={Acao_04_B}>
                                    <div>                                        

                                            <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_cartao" placeholder='CARTÃO'   value={valor_digitado_cartao} onChange={(e) => Func_Ao_Mudar_Input_Cartao(e)}/> 
                                    </div>
                                </form> 

                                <form action={Acao_04_C}>
                                    <div>                                        
                                            <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_pix" placeholder='PIX'  value={valor_digitado_pix} onChange={(e) => Func_Ao_Mudar_Input_Pix(e)}/> 
                                    </div>
                                </form> 

                                <form action={Acao_04_A}>
                                    <div>                                        
                                            <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_dinheiro" placeholder='DINHEIRO'  value={valor_digitado_dinheiro} onChange={(e) => Func_Ao_Mudar_Input_Dinheiro(e)}/> 
                                    </div>
                                </form> 

                                <form action={Acao_04_D}>
                                    <div>                                        
                                            <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_vale" placeholder='VALE'  value={valor_digitado_vale} onChange={(e) => Func_Ao_Mudar_Input_Vale(e)}/> 
                                    </div>
                                </form> 

                            </div>                                                          
                            

                                    <div id="grid_lado_esquerdo">

                                        <DataTable  columns = {columns_2}                            
                                            data={lista_2}    //dados de um DB
                                            pagination = {false}         //Põe a paginação                                       
                                            no noDataComponent={"Nenhum Registro Encontrado"}
                                            fixedHeader={true}  //fixa o cabeçalho se for true
                                            selectableRowsHighlight  //escurece a linha quando o maus em cima
                                            highlightOnHover         //junto com o de cima                            
                                        /> 

                                    </div>                           

                            
                         
                 
                         {focus}
                        
                                             

            </div>
            <div id="tela_direita">

                <Entra_tela />

                <div id="grid_lado_direito">

                    <DataTable  columns={columns_5}                            
                        data={lista}    //dados de um DB
                        pagination = {false}         //Põe a paginação                                       
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 

                </div>

            </div>
               
        </div>   
        
    )

}    

export default Vendas;


//    <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_cartao" placeholder='CARTÃO' value={valor_digitado.input_cartao} onChange={(e) => Func_Ao_Mudar_Input(e)}/> 
                              
//    <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_pix" placeholder='PIX'  value={valor_digitado.input_pix} onChange={(e) => Func_Ao_Mudar_Input(e)}/> 
                                
//    <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_dinheiro" placeholder='DINHEIRO'  value={valor_digitado.input_dinheiro} onChange={(e) => Func_Ao_Mudar_Input(e)}/> 
                    
//    <input className="btn btn-outline-primary text-start"  id="css_54" type="text" name="input_vale" placeholder='VALE'  value={valor_digitado.input_vale} onChange={(e) => Func_Ao_Mudar_Input(e)}/> 
     
