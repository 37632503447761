
import "./encerramento.css";


function Encerramento(){ 
  
    return (    
        
    <div>        

        <div id='css_encerramento'>

            <div>

                <h1>Aplicativo Encerrado</h1>                        
               
            </div>

        </div>      
       
             
    </div> 

    )
}

export default Encerramento;

