
import { motion } from "framer-motion";


const parent = {
  initial: {},
  animate: { transition: { staggerChildren: 0.04,  staggerDirection: -1 }}
};

const letter = {
    initial: { opacity: 0, x: -0.5},
    animate: { opacity: 1, x: 0, transition: { duration: 0.4 } } 
  };


const AnimatedPage1 = ({children}) => {
    return (
        <motion.div
                variants={parent}
                initial="initial"
                animate="animate"                
                exit={{ opacity: 0, transition: { duration: 0.4 } }}            
        >          
                <motion.div variants={letter}>
                     {children}   
                </motion.div>

        </motion.div>
    )
}

export default AnimatedPage1;
