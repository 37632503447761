
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Login from "./pages/login/login.jsx";
//import Principal from "./pages/principal/principal.jsx";
import Cadastro from "./pages/cadastro/cadastro.jsx";

import MeuContexto from "./context/context.jsx";
import { useState } from "react";

import Teste from "./pages/teste/teste.jsx";
import Consultar from "./pages/1_consultar/consultar.jsx";
import Deletar from "./pages/2_deletar/deletar.jsx";
import Alterar from "./pages/3_alterar/alterar.jsx";
import Incluir from "./pages/4_incluir/incluir.jsx";
import Pedidos from "./pages/5_pedidos/pedidos.jsx";
import Video from "./pages/video/video.jsx";
import Alertas from "./pages/alertas/alertas.jsx";
import Teste_2 from "./pages/teste-2/teste-2.jsx";
import Encerramento from "./pages/encerramento/encerramento.jsx";
import Comissao from "./pages/comissao/comissao.jsx";
import Historico from "./pages/historico/historico.jsx";
import Perfil from "./pages/perfil/perfil.jsx";
import Consulta_1 from "./pages/amarok_consulta_1/amarok_consulta1.jsx";
import Consulta_2 from "./pages/amarok_consulta_2/amarok_consulta2.jsx";

import Login_Vitrine from "./pages_vitrine/login/login.jsx";
import Principal from "./pages_vitrine/principal/principal.jsx";
import Estudo from "./pages_vitrine/estudo/estudo.jsx";
import Vendas from "./pages_vitrine/vendas/vendas.jsx";
import Lista_Empregados from "./pages_vitrine/lista_empregados/lista_empregados.jsx";
import Lista_Produtos from "./pages_vitrine/lista_produtos/lista_produtos.jsx";
import Lista_Clientes from "./pages_vitrine/lista_clientes/lista_clientes.jsx";
import Cadastro_de_Clientes from "./pages_vitrine/cadastro_clientes/cadastro_de_clientes.jsx";
import Pagamentos from "./pages_vitrine/pagamentos/pagamentos.jsx";
import Pagamentos_Consulta from "./pages_vitrine/pagamentos/pagamentos_consulta.jsx";
import Caixa_Fechar from "./pages_vitrine/caixa_fechar/caixa_fechar.jsx";
import Comissoes from "./pages_vitrine/comissoes/comissoes.jsx";
import Lista_Devedores from "./pages_vitrine/lista_devedores/listar_devedores.jsx";
import Lista_Fechamento from "./pages_vitrine/lista_fechamento/lista_fechamento.jsx";
import Balanco from "./pages_vitrine/balanco/balanco.jsx";
import FuncHome from "./pages/home/home.jsx";
import Historico_Clientes from "./pages_vitrine/historico/historico.jsx";
import { Estudo_2 } from "./pages_vitrine/estudo/estudo_2.jsx";
import { Cliente_Login } from "./pages_vitrine_cliente/clientes_login/cliente_login.jsx";
import { Clientes_Menu } from "./pages_vitrine_cliente/clientes_menu/clientes_menu.jsx";
import Encerrar from "./pages_vitrine_cliente/encerrar/encerrar.jsx";
import { Indicar_Clientes } from "./pages_vitrine_cliente/indicar_clientes/indicar_clientes.jsx";

import { Estudo_fetch } from "./pages_vitrine/estudo/react19/1_use_api/use_api.jsx";

import { Alterar_Senha } from "./pages_vitrine_cliente/alterar_senha/alterar_senha.jsx";

import { Listar_Novos_clientes } from "./pages_vitrine/lista_novos_clientes/lista_novos_clientes.jsx";

import { Listar_Ult_Atendimento } from "./pages_vitrine/listar_ult_atendimento/listar_ult_atendimento.jsx";

import Sub_Menu from "./pages_vitrine/sub_menu/sub_Menu.jsx";


function Rotas(){
    const location = useLocation()
    const [chave, setChave] = useState('')
    const [nome, setNome] = useState('')
    const [profissao, setProfissao] = useState('')
    const [id_usuario, setId_usuario] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [cpf, setCPF] = useState('')
    const [hotel, setHotel] = useState('')
    const [fone, setFone] = useState('')
    const [tipoPIX, SetTipoPIX] = useState('')
    const [PIX, setPIX] = useState('') 
    const [ShowDock1, setShowDock1] = useState(false)
    const [ShowDock2, setShowDock2] = useState(false)
    const [Logado, setLogado] = useState(false) 
    const [observacao5, setObservacao5] = useState(false)
    const [nome_cliente_cont, setNome_Cliente_cont] = useState('')
    const [cod_cliente_cont, setCod_cliente_cont] = useState('')
    const [nome_empregado_cont, setNome_empregado_cont] = useState('')
    const [cod_empregado_cont, setCod_empregado_cont] = useState('')
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [nome_produto_cont, setProduto_cont] = useState('')
    const [cod_produto_cont, setCod_produto_cont] = useState('')
    const [focus, setFocus] = useState('0')

    const [url, setUrl] = useState('http://bhoras.com.br:3000')

    const [estagio, setEstagio] = useState('0')

    
      

    return ( 
       
            <AnimatePresence mode='wait'>

           

            <MeuContexto value={{chave, setChave, 
                                              nome, setNome,
                                              profissao, setProfissao,
                                              id_usuario, setId_usuario,
                                              email, setEmail,
                                              senha, setSenha,
                                              cpf, setCPF,
                                              hotel, setHotel,
                                              fone, setFone,
                                              tipoPIX, SetTipoPIX,
                                              PIX, setPIX,
                                              ShowDock1, setShowDock1,
                                              ShowDock2, setShowDock2,
                                              Logado, setLogado,
                                              observacao5, setObservacao5,
                                              nome_cliente_cont, setNome_Cliente_cont, 
                                              cod_cliente_cont, setCod_cliente_cont,
                                              nome_empregado_cont, setNome_empregado_cont, 
                                              cod_empregado_cont, setCod_empregado_cont,
                                              show, setShow,
                                              show2, setShow2,
                                              show3, setShow3,
                                              show4, setShow4,
                                              nome_produto_cont, setProduto_cont,
                                              cod_produto_cont, setCod_produto_cont,
                                              focus, setFocus, 
                                              url, setUrl,
                                              estagio, setEstagio,                                                                                                                                  
                                                                                           
                                              }}>
            
                        <Routes location={location} key={location.pathname}>

                            <Route path="/" element={<Login_Vitrine />}  />
                            <Route path="/principal" element={<Principal />}  />
                            <Route path="/estudo" element={<Estudo />}  />
                            <Route path="/vendas" element={<Vendas />}  />
                            <Route path="/listar_empregados" element={<Lista_Empregados />}  />
                            <Route path="/listar_produtos" element={<Lista_Produtos />}  />
                            <Route path="/listar_clientes" element={<Lista_Clientes />}  />
                            <Route path="/Cadastro_de_Clientes" element={<Cadastro_de_Clientes />}  />
                            <Route path="/pagamentos" element={<Pagamentos />} />  
                            <Route path="/Pagamentos_Consulta" element={<Pagamentos_Consulta />} /> 
                            <Route path="/Caixa_Fechar" element={<Caixa_Fechar />} />
                            <Route path="/Comissoes" element={<Comissoes />} />
                            <Route path="/Lista_Devedores" element={<Lista_Devedores />} />
                            <Route path="/Lista_Fechamento" element={<Lista_Fechamento />} />
                            <Route path="/balanco" element={<Balanco />} />
                            <Route path="/Historico_Clientes" element={<Historico_Clientes/>} />                           

                            <Route path="/teste" element={<Teste />}  /> 
                            
                            <Route path="/encerramento" element={<Encerramento />}  /> 
                            <Route path="/comissao" element={<Comissao />}  /> 
                            <Route path="/historico" element={<Historico />}  />
                            <Route path="/perfil" element={<Perfil />}  />
                            <Route path="/cadastro" element={<Cadastro />}  />
                        
                            <Route path="/consultar" element={<Consultar />}  /> 
                            <Route path="/incluir" element={<Incluir />}  />            
                            <Route path="/deletar" element={<Deletar />}  />
                            <Route path="/alterar" element={<Alterar />}  />                    
                            <Route path="/pedidos" element={<Pedidos />}  />
                            <Route path="/video" element={<Video />}  />
                            <Route path="/alertas" element={<Alertas />}  /> 
                            <Route path="/teste2" element={<Teste_2 />}  /> 
                            <Route path="/consulta1" element={<Consulta_1 />}  /> 
                            <Route path="/consulta2" element={<Consulta_2 />}  />
                            <Route path="/consulta2" element={<Consulta_2 />}  />
                            <Route path="/FuncHome" element={<FuncHome />}  />
                            <Route path="/Estudo_2" element={<Estudo_2 />}  />
                            <Route path='/cliente_login' element={<Cliente_Login />}  />
                            <Route path='/Clientes_Menu' element={<Clientes_Menu />}  /> 
                            <Route path='/Encerrar' element={<Encerrar />}  /> 
                            <Route path='/Indicar_Clientes' element={<Indicar_Clientes />}  />
                            <Route path='/Estudo_fetch' element={<Estudo_fetch />}  />
                            <Route path='/Alterar_Senha' element={<Alterar_Senha />}  />
                            <Route path='/Listar_Novos_clientes' element={<Listar_Novos_clientes />}  />  
                            <Route path='/func_GRID' element={<func_GRID />}  /> 
                            <Route path='/Listar_Ult_Atendimento' element={<Listar_Ult_Atendimento />}  />  
                            <Route path='/Sub_Menu' element={<Sub_Menu />}  />                                            

                            //Sub_Menu                                                        
                    
                        </Routes> 

                </MeuContexto>

            </AnimatePresence>          
   
    )
}


export default Rotas;