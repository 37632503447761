
import {useState} from 'react';

import api from "../../services/api.js"

import Swal from 'sweetalert2';

import DataTable from 'react-data-table-component';

import{use} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import {Navigate} from "react-router-dom";

import "./amarok_consulta2.css";



function Consulta_2(){

    const {nome, id_usuario, Logado} = use(MeuContexto);

    const [lista, setLista] = useState([]);
    const [lista_2, setLista_2] = useState([]);
   

    useEffect(() =>{

        func_Receber();

        if (Logado == false){
            Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
      }

    },[]);    

        const func_Receber = async () => {

            try{               

                      await api.get("/consulta_operadores")
                          .then((response) => { 
                           setLista(response.data);                     
                      })   

                       //Se der ERRO em qualquer das Consultas
                        } catch(error){          
                        console.log(error);
            }

                     
      
        }

        
       

    const columns = [
  
        {
            name: 'NOME',
            selector: row => row.OPERADOR,
            width: "100px",   //largura 
                   
            sortable: false            //cria ordenação
        }, 
        
       

    

        {
            name: 'ESTAGIO',          
            selector: row => row.ESTAGIO,
            width: "190px",  
            sortable: false            //cria ordenação 
        },
        {
            name: 'TOT',
            selector: row => row.QUANTIDADE,
            width: "80px",  
            sortable: false,            //cria ordenação
            right: true
            //format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.QUANTOS)
        }
  ]
       
    
        return <div> 



            {Logado === false ? <Navigate to='/' /> : null } 
 

                   <div>
                      <h4 id="cabeca_2">Operadores - Estágio de Trabalho</h4> 
                   </div>
                                    
                                
                       
                    <div  id="planilha_7x">
                        <div>
                            <DataTable columns={columns}                            
                            data={lista}    //dados de um DB                      
                            
                            no noDataComponent={"Nenhum Registro Encontrado"}
                            fixedHeader={true}  //fixa o cabeçalho se for true
                            selectableRowsHighlight  //escurece a linha quando o maus em cima
                            highlightOnHover         //junto com o de cima  
                            striped    //Muda cor linhas impares
                                                  
                            />                         
                        </div> 
                    </div>
                        

        </div>

}

export default Consulta_2;


{/*}
{
    name: 'CÓD',
    selector: row => row.CODIGO,
    width: "70px",   //largura 
    
    sortable: false            //cria ordenação
},

*/}