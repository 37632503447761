import { useState } from "react";

import {Navigate} from "react-router-dom";

import "./navbar.css";

function Navbar_Voltar(){

    const [linkar, setLinkar] = useState('');

    function func_Linka_Comissao(){
        setLinkar('Pagina_Principal');
        } 

    return <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-black ps-4 pe-1">
    <div className="container-fluid">
        <a className="navbar-brand" href="/">

              <img className="mb-4" id="abelha" src="imagens/abelha.png" alt=""/>

           
        </a>

        <h2>Planilha de Proprietários Amarok</h2>

        <div className="nav-item me-3">                  

                    <button className="btn btn-outline-primary" onClick={func_Linka_Comissao} type="button"  id="css_botao_sair">Voltar</button>
                     {linkar === 'Pagina_Principal' ? <Navigate to='/comissao' /> : null} 
                    
        </div> 
    </div>
</nav>
}

export default Navbar_Voltar;


// <Link className="btn btn-outline-primary" aria-current="page" to="/encerramento">Saída</Link>