import './login.css';
import {Link, Navigate}  from 'react-router-dom';
import AnimatedPage1 from "../../AnimatedPage1.jsx";
import React, { useState} from 'react';

import { useEffect } from "react";

import api from "../../services/api.js"

//import{useContext} from 'react';
import{use} from 'react';
import MeuContexto from '../../context/context.jsx';

import Swal from 'sweetalert2'; //Alertas



function Login_Vitrine(){   


  const [Var_email, set_Var_email] = useState(""); 

  const [Var_senha, set_Var_senha] = useState("");
  
  const [sucesso, setSucesso] = useState('');  
  
  //const { setNome, setId_usuario, setEmail, setSenha, setLogado } = useContext(MeuContexto)
  const { setNome, setId_usuario, setEmail, setSenha, setLogado } = use(MeuContexto)
 

const func_Login = async () => {

 

try{

      await api.post("/Login_de_Usuarios_Vitrine" , {
        email: Var_email,
        senha: Var_senha
      })
      .then((response) => {                                                   
          
          setNome(response.data.nome);          
          setId_usuario(response.data.id_usuario); 
          setEmail(response.data.email);
          setSenha(response.data.senha);
          setSucesso('S');
          setLogado(true);

          //console.log(response.data.nome);  
          
      })  
      
      
      

   } catch(error){
          setSucesso('N');
          Swal.fire({icon: 'error',  title: 'Erro', text: 'Usuário ou Senha Inválidos!'})          
      }
   }


    
  return <div className='form-container'> 

    <AnimatedPage1>   

    <form className="form-signin">   

      <div id="css_parte_1">       
           <img className="mb-4" id="abelha" src="imagens/abelha.png" alt=""/>
      </div>

      <div id="css_parte_2">           


            <h1 className="h3 mb-3 mt-3 fw-normal">Plataforma Vitrine</h1>
                   <h1 className="h3 mb-3 mt-3 fw-normal">Login</h1>
                  <div id="id_perfil_5">                       
                            <input className="btn btn-outline-primary text-start mb-5" id='css_senha' type="text" placeholder='Usuário' onChange={(e)=>set_Var_email(e.target.value)}/>                            
                  </div> 
                 
                  <div id="id_perfil_5">                              
                            <input className="btn btn-outline-primary text-start mb-5" id='css_senha' type="text" placeholder='Senha' onChange={(e)=>set_Var_senha(e.target.value)}/>                            
                  </div> 
                                   


            <div className="form-floating">

                <button onClick={(e) =>func_Login(Var_email, Var_senha)} className="w-320 btn btn-outline-primary btn-lg" id="css_botao" type="button">Acessar</button>
                {sucesso === 'S' ? <Navigate to='/principal' /> : null}                    
                      
            </div> 


      </div>
 

    </form>  

    </AnimatedPage1>
    
  </div>
}

export default Login_Vitrine;

