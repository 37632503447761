import { use, Suspense } from "react";
import { URL_2, headers_2 } from "../../../../../services/api";
import Swal from 'sweetalert2';


const fetchPosts = async () => {  
 
  const params = {     
    cod: '4000',   
  };

  try {
    
      const res = await fetch(URL_2 + "/traz_cliente", 
        {method:'POST', 
        headers: headers_2,
        body: JSON.stringify( params )        
        });   

        return res.json();   
      
    } catch (error) {
      
      Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;
     
    }

  };

const PostItens = () => {

  const posts = use(fetchPosts());

  //alert(posts.nome);

  return(
    <div>     
          O Nome É: {posts.nome}
    </div>

  )
};


const Use_fetch_traz_valor = () => {
  return(
    <Suspense fallback="Carregando Dados...">
        <PostItens />
    </Suspense>  
  )   
}

export default Use_fetch_traz_valor;




