import { use, Suspense } from "react";
import DataTable from 'react-data-table-component';
import { URL_2, headers_2 } from "../../../../../services/api";
import Swal from 'sweetalert2';

const fetchPosts = async () => {  
 
  const params = {
    data_inicio: '2024-07-17 00:00:00', 
    data_fim: '2024-07-19 00:00:00',   
  };

  try {
    
      const res = await fetch(URL_2 + "/listar_pagamentos", 
        {method:'POST', 
        headers: headers_2,
        body: JSON.stringify( params )        
        });   

        return res.json();   
      
    } catch (error) {
      
      Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;
     
    }

  };

const PostItens = () => {

  const posts = use(fetchPosts());

  const columns = [ 
    {
      name: 'Descricao',  
      compact: true,        
      selector: row => row.desc_pagamento,
      width: "250px"               
    },                
    {
        name: 'Data',  
        compact: true,        
        selector: row => row.dia,
        width: "120px"               
    },                
    {
        name: 'Valor',  
        compact: true,  
        selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor),
        right: true,
        width: "150px"               
    }
    ]

  return(
    <div>
      <div>                        
          <DataTable  columns={columns}                            
              data={posts}    //dados de um DB                         
              no noDataComponent={"Nenhum Registro Encontrado"}
              fixedHeader={true}  //fixa o cabeçalho se for true
              selectableRowsHighlight  //escurece a linha quando o maus em cima
              highlightOnHover         //junto com o de cima                            
          /> 
      </div>
    </div>

  )
};


const Use_fetch = () => {
  return(
    <Suspense fallback="Carregando Dados...">
        <PostItens />
    </Suspense>  
  )   
}

export default Use_fetch;



