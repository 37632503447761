import api from "../../services/api.js";

import{useState} from 'react';

import { useActionState } from "react";

import { useFormStatus} from 'react-dom';

import Swal from 'sweetalert2';

import {useRef} from 'react';

export function Estudo_2() {
    
    const [Var_Input5, set_Var_Input5] = useState("");
    const [Var_Input6, set_Var_Input6] = useState("");    

    const myinput = useRef(); 

    const inputfocus = () => {            
       //myinput.current.value = cod_cliente_cont;   
       //set_Var_Input5(cod_cliente_cont);
       myinput.current.focus();                     
    }

    function Acao_01(ObterDados){                     
                                          
        var Var_1 = ObterDados.get("input_codigo");          
 
        myinput.current.value = Var_1;

        set_Var_Input5(Var_1); 

        setTimeout(() => {
            func_traz_cliente(Var_1);
        },3000)                       
    
    }


    const func_traz_cliente = async (Var_1) => {

        try{        
        await api.post("/traz_cliente" , {
             cod: Var_1
        })
        .then((response) => {
             set_Var_Input6(response.data.nome);
             if (response.data.nome==undefined) 
             {
                 inputfocus();
                 Swal.fire({icon: 'error',  title: 'Erro', text: 'Código Não Existe'}) ; 
             } 
            })     
            } catch(error){  
                        inputfocus();          
                        Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                        
            }
    }

    const func_Recebe_Resultado = async (previousState, recebe_do_form) => {
            
            const Var_Codigo = recebe_do_form.get('input_2')       
            var Var_Nome='';

            //Só parando Um tempinho
            await new Promise(resolve => setTimeout(resolve, 2000))

            try{        
            await api.post("/traz_cliente" , {
                 cod: Var_Codigo
            })
            .then((response) => {                
                    Var_Nome=response.data.nome                   

                    if (Var_Nome==undefined) 
                    {
                        inputfocus();
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'Código Não Existe'}) ; 
                    }         
            })     
            } catch(error){  
                          inputfocus();          
                          Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                       
            }              
            return {
                valor: Var_Nome,
                valor_digitado: Var_Codigo
            } 

    }

    const [traz, Acao_02] = useActionState(func_Recebe_Resultado, { valor: "", valor_digitado: ""})

        
    return (
            <>
             	             <br></br>
                             <small className="text-secondary">Código do Cliente</small>       
                             <br></br>

                            <form action={Acao_01}> 
                                <div>                                    
                                    <input type="text" name="input_codigo" defaultValue={Var_Input5} ref={myinput}/>                                               
                                    <br/>   
                                    <br/>             
                                    <button type="submit">Executar</button>
                                    <h5>{Var_Input6}</h5>
                                    <br/> 
                                </div>
                            </form> 

                            <form action={Acao_02}> 
                                <div> 
                                    <br/>
                                    <small className="text-secondary">Código do Cliente</small>       
                                    <br/>                                  
                                    <input type="text" name="input_2" defaultValue={traz.valor_digitado} ref={myinput}/>                                               
                                    <br/> 
                                    <br/>                                                                   
                                    <BOTAO/>
                                    <p> O Nome é: {traz.valor}</p>                                    
                                </div>
                            </form>

   
            </>
    )   
    
}
    
    
    function BOTAO(){    
             const { pending } = useFormStatus();        
             return(
                <div>
                    <button type='submit' disabled={pending}>
                        {pending ? "Executando..." : "Executar"}
                    </button>   
                </div>    
                )   
    }

   
    
    

