
import {Link} from "react-router-dom";

import "./lista_empregados.css";

import api from "../../services/api.js"
import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
//import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

//import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";

import { mask, unMask } from 'remask';  //mascaras


function Lista_Empregados() { 
    
    

    const {setFocus, show3, setShow3, nome_empregado_cont, setNome_empregado_cont, cod_empregado_cont, setCod_empregado_cont} = use(MeuContexto);

    const [linkar, setLinkar] = useState('');

//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

var Var_nome_empregado='';

function Acao_2(ObterDados){
   
    Var_nome_empregado = ObterDados.get("input_nome_empregado")  

    func_Receber_lista();    
    
}

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const [lista, setLista] = useState([]);

const func_Receber_lista = async () => {
   
    try{                   
            

              Var_nome_empregado = '%' + Var_nome_empregado + '%'; 

              //Consulta detalhe clientes indicados
              await api.get("/listar_empregados?nome_empregado=" +  Var_nome_empregado)
              .then((response) => { 
                  setLista(response.data);                     
              })    
             

          //Se der ERRO em qualquer das Consultas
        } catch(error){          
                console.log(error);
    }
  
        
 } 

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const columns = [

    {
        cell: (row) => <> 

             <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar( row.cod_empregado, row.nome_empregado )}  id="botao_selecionar">Selecionar</button>
             {linkar === 'Voltar_Vendas' ? <Navigate to='/vendas' /> : null}

   
            {/* <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar('cod ' + row.cod_empregado + ' nome ' + row.nome_empregado + ' FIM')}  id="botao_selecionar">Selecionar</button> */}
             

        </>,
        width: "180px",   //largura 
        left: true   //Colocar Botão a Direita
      }, 
      {
        //name: 'CÓDIGO',
        selector: row => row.cod_empregado,
        width: "150px",   //largura
        sortable: false            //cria ordenação 
        
    }, 
    {
        //name: 'CLIENTE',
        selector: row => row.nome_empregado,
        width: "400px",   //largura 
        sortable: false            //cria ordenação
    },        
    {
        //name: 'CELULAR',          
        selector: row => row.cargo,
        width: "180px",  
        sortable: false            //cria ordenação 
    }
]

// Cria a Paginação
const paginationOptions = {
rowsPerPageText: 'Registros por Página',
rangeSeparatorText: 'de',
selectAllRowsItem: true,
selectAllRowsItemText: 'Todos'
};

//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo 
 //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
const [codigo_empregado, setCodigo_empregado] = useState('');
const [nome_empregado, setNome_empregado] = useState('');


function clickEditar(cod_empregado, nome_empregado){
     
    setCodigo_empregado(cod_empregado); 
    setNome_empregado(nome_empregado);
    setShow3(false);
    setFocus("2");     
    setLinkar('Voltar_Vendas'); 

}


 //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

 function clickFechar() {

    setCodigo_empregado('');
    setNome_empregado('');    
    setShow3(false)
    setFocus("2");

}

setNome_empregado_cont(nome_empregado);
console.log(nome_empregado_cont);

setCod_empregado_cont(codigo_empregado);
console.log(cod_empregado_cont);



    
return <Dock position="left"
    isVisible={show3}
    fluid={false}
    size={1000}     
    >

{/* <div className="container-fluid h-100 pt-4 sidebar p-4"></div> */}

<div className="container-fluid h-100 pt-4 sidebar p-4 "> 

         
        
        <div id="ajuste11">
            <div>                       
                <h2 id="css_texto_inicial">Selecione o Profissional</h2>                                                   
            </div> 
            
            <div> 
                <Link to="/vendas" className="btn btn-outline-primary me-3" id="ajuste12" onClick={clickFechar} >Sair</Link>                            
                
            </div>  
        </div>
    
                          

        
        <div>
        
            <form action={Acao_2}> 

                <h6>Profissional</h6>

                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" autoFocus name='input_nome_empregado'/>     
                   
                                   
                {/*<button className="btn btn-outline-primary me-3" type="submit">Listar</button>  */}   

            </form> 

        </div>
             <h6></h6>             
        <div>
        </div> 

        <div>                        
            <DataTable  columns={columns}                            
                data={lista}    //dados de um DB
                pagination = {true}         //Põe a paginação 
                paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                no noDataComponent={"Nenhum Registro Encontrado"}
                fixedHeader={true}  //fixa o cabeçalho se for true
                selectableRowsHighlight  //escurece a linha quando o maus em cima
                highlightOnHover         //junto com o de cima                            
             /> 
        </div> 

                            
       
</div>

</Dock>


}

export default Lista_Empregados;