import {useEffect, useState} from 'react';
import api from "../../services/api.js"
import {Link} from "react-router-dom";
import "./consultar.css";

import{useContext} from 'react';
import UserContext from '../../context/context.jsx';



function Consultar(){

        const { user, setUser } = useContext(UserContext)
                
                
        //Código de Consulta Lista - Em return div 1 e div 2
        const [Var_Hoteis, set_Var_Hoteis] = useState([]);   

        function func_Listar_Hoteis(){
            api.get("/empresas?uf=")
            .then((response) => {            
                set_Var_Hoteis(response.data);

            })
            .catch(err => console.log(err))
        }

        //Código de Consulta Parametro - Em return div 3 
        const [Var_Input, set_Var_Input] = useState("");
        const [Var_Hotel, set_Var_Hotel] = useState([]);

        const [Var_Z, set_Var_Z] = useState("");

        //Uso do ASYNC e AWAIT e useEffect

        useEffect(() =>{
            func_Listar_Hotel();
        },[]);


        const delay = ms => new Promise(res => setTimeout(res, ms));
            

                const func_Listar_Hotel = async () => {

                    await delay(2000);
                    console.log("Espere 2s");                

                    try{                                                

                        await api.get("/empresas?uf=" + Var_Input)

                        .then((response) => { 
        
                            set_Var_Hotel(response.data);
        
                           
        
                            //console.log(Var_Z); 

                            console.log(Var_Hotel); 

                            })

                            //O Código converte o objeto para JSON - ao contrário use-se "parse"
                            //console.log(JSON.stringify(Var_Hotel));                  
        
                            // let YYY = Var_Hotel[0].localidade;
                            // console.log(YYY);                      
            
                    } catch (error) {
                    console.log(error);
                    }               
                }

                const func_Alterar_Nome  = async () => {
                    //set_Var_Z('Madalena');
                    setUser('Madalena');
                }

    
        return <div>
               <div>
                        <h1>Consulta - Lista</h1> 
                        <Link to="/" className="btn-voltar">Voltar</Link>
                        <br></br>
                        
                        <h2>user</h2>
                        <button className="btn-voltar" onClick={func_Alterar_Nome}>Alterar Nome</button>

                        <hr></hr>
                        <hr></hr>
                        <br></br>
               </div>
               <div>                        
                        <button className="btn-voltar" onClick={func_Listar_Hoteis}>Consultar Hotéis</button>                          
                        <ul>             
                        {        
               
                            
                            Var_Hoteis.map((e) => {            
                                return <li key="{e}">{e.localidade} - {e.uf}</li>
                            })  



                        }
                        </ul>
                        <br></br>
                        <br></br>
               </div>  

                <div>
                            <h1>Consulta - Parâmetro</h1>                          
                            <br></br>
  
                            <h3>Digite a UF </h3> 

                            <input type="text" onChange={(e)=>set_Var_Input(e.target.value)} />                        
                            <br></br> <br></br>
                            <button type="button" className="btn-voltar" onClick={(e) =>func_Listar_Hotel(Var_Input)}>Trazer Hotel</button>
                            <br></br> <br></br>                           
                            <ul>             
                            {        
                          
                                
                                Var_Hoteis.map((e) => {            
                                    return <li key="{e}">{e.localidade} - {e.uf}</li>
                                })     


                            }
                                <p>{Var_Z}</p>
                                <p>{JSON.stringify(Var_Hotel)}</p>                                

                            </ul>
                </div>  

    
        </div>

}

export default Consultar;

//<p>{JSON.stringify(Var_Hotel)}</p>
//<p>{Var_V}</p>

