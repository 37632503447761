
import "./encerrar.css";


function Encerrar(){ 
  
    return (    
        
    <div>

       
 
       

        <div id='css_encerrar'>

            <div>

                <h3>Aplicativo Encerrado</h3>                        
               
            </div>

        </div>        
     
       
             
    </div> 

    )
}

export default Encerrar;

