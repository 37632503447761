

import "./perfil.css";
import AnimatedPage1 from "../../AnimatedPage1.jsx";
//import Footer from "../footer/footer.jsx";

import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';


import Footer from "../footer/footer";
import Navbar_Voltar from "../navbar/navbar_comissao";

import {useRef} from 'react';

import { useEffect } from "react";

import api from "../../services/api.js";

import { mask, unMask } from 'remask';  //mascaras

import Swal from 'sweetalert2'; //Alertas

import Dock_Profissao from "../dock_profissao/dock_profissao";

import Dock_Hotel from "../dock_hotel/dock_hotel";

import {Navigate} from "react-router-dom";


function Perfil(){ 

    const style_1 = {fontSize:"20px", color: "primary"};
    const style_2 = {fontSize:"13px", color: "primary"};    

    const {id_usuario, nome, profissao, email, senha, cpf, hotel, fone, tipoPIX, PIX, Logado,
        setNome, setEmail, setCPF, setSenha, setFone, setPIX, SetTipoPIX, setShowDock1, setShowDock2} = use(MeuContexto); 
    
    const [TPix, setTPix] = useState('');    
    const [profi, setProfi] = useState('');

    //FUNÇÂO TESTA CPF
    function TestaCPF(strCPF) {
        var Soma;
        var Resto;

        var i;

        Soma = 0;
      if (strCPF == "00000000000") return false;
    
      for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
    
      Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
    
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
        return true;
    }
    
    //var strCPF = "22055967301";
    //console.log(TestaCPF(strCPF));

      



    //função ACAO
    const acao = () => {     

        {tipoPIX == 1 ? setTPix('CPF') : tipoPIX == 2 ? setTPix('Celular') : tipoPIX == 3 ? setTPix('Email') : setTPix('Chave') } 
       
        setProfi(profissao);    
    }
    
    useEffect(() =>{ 

        acao();
        func_Traz_Hotel();

        //Passa o Favor inicial para o edit
        set_Var_Input4(cpf);
        set_Var_Input5(fone);
        set_Var_Input6(PIX);

        //Varivel de H
        var CVCV = 1;

        {tipoPIX == 1 ?  func_Cor_CPF(): CVCV = 1}
        {tipoPIX == 2 ?  func_Cor_Celular() : CVCV = 1}
        {tipoPIX == 3 ?  func_Cor_Email() : CVCV = 1}
        {tipoPIX == 4 ?  func_Cor_Chave() : CVCV = 1}
        
        if (Logado == false){
            Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'}) }        

    },[]);

    useEffect(() =>{
        func_Traz_Hotel();
    },[hotel]);

   
    const [var_hotel, setVar_hotel] = useState('');
    
    const func_Traz_Hotel = async () => {
    try{

                await api.post("/buscar_hotel" , { 
                    id_localidade : hotel
                })
                .then((response) => { 

                    setVar_hotel(response.data.localidade);          
                    
                })      
  
            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                console.log(error);
        }
    }



    const [Var_Input1, set_Var_Input1] = useState("1");
    const [Var_Input2, set_Var_Input2] = useState("1");
    const [Var_Input3, set_Var_Input3] = useState("1");
  
    const [Var_Input4, set_Var_Input4] = useState("1");
    const [Var_Input5, set_Var_Input5] = useState("1");

    const [Var_Input6, set_Var_Input6] = useState("1");
    const [Var_Input7, set_Var_Input7] = useState("0");   //TipoPIX


    //const [valido, setValido] = useState("false");

    function func_Validar_Campos(){

        var Validado1 ='NAO';
        var Validado2 ='NAO';
        var Validado3 ='NAO';         
    
        {Var_Input5.length == 14 ?  Validado1 ='SIM' : Swal.fire({icon: 'error',  title: 'Celular', text: 'Inválido'})}        
    
        var strCPF = Var_Input4.match(/\d/g).join("");  //tira os pontos e traço do CPF
        {TestaCPF(strCPF) ?  Validado2 ='SIM' : Swal.fire({icon: 'error',  title: 'CPF', text: 'Inválido'})}


        if (Var_Input7 == '1') {
            if (Var_Input6.length == 14) {
                var strCPF = Var_Input6.match(/\d/g).join("");  //tira os pontos e traço do CPF 
                {TestaCPF(strCPF)?  Validado3 ='SIM' : Swal.fire({icon: 'error',  title: 'Problema no PIX:', text: 'CPF Inválido'})}   
            } else {
                Swal.fire({icon: 'error',  title: 'Problema no PIX:', text: 'CPF Inválido'})
            }
        }
        
        if (Var_Input7 == '2') {
            {Var_Input6.length == 14 ?  Validado3 ='SIM' : Swal.fire({icon: 'error',  title: 'Problema no PIX', text: 'Celular Inválido'})} 
         }

         if (Var_Input7 == '3') {
            Validado3 ='SIM';
         }

         if (Var_Input7 == '4') {
            Validado3 ='SIM';
         }


       /*
        var Varor_do_PIX;

        

        { Var_Input6 == '1' ?  Varor_do_PIX = PIX : Varor_do_PIX = Var_Input6};

        console.log(Varor_do_PIX.match(/\d/g).join(""))

        var strCPF = Varor_do_PIX.match(/\d/g).join("");  //tira os pontos e traço do CPF

        {strCPF ?  setValido = 'true' : setValido = 'false'}

        console.log(valido)      


        //{valido &&  Var_Input7 == 1 ? Validado3 ='SIM' : Swal.fire({icon: 'error',  title: 'CPF', text: 'Inválido no PIX'}) }
        */
       
        {Validado1 =='SIM'  &&  Validado2 =='SIM' &&  Validado3 =='SIM' ?  func_Alterar_Usuario() : Validado1 ='NAO' } 
      
    }

    

   
    function func_Alterar_Usuario(){ 

        var AlteraNome='';
        var AlteraEmail='';
        var AlteraSenha='';
        var AlteraCPF='';
        var AlteraFone='';
        var AlteraPIX='';
        var AlteraTipoPIX='';
    
        var DDFF=1;    
    
        {Var_Input1 == 1 ?  AlteraNome=nome : AlteraNome=Var_Input1}
        {Var_Input2 == 1 ?  AlteraEmail=email : AlteraEmail=Var_Input2}
        {Var_Input3 == 1 ?  AlteraSenha=senha : AlteraSenha=Var_Input3}
        {Var_Input4 == 1 ?  AlteraCPF=cpf : AlteraCPF=Var_Input4}
        {Var_Input5 == 1 ?  AlteraFone=fone : AlteraFone=Var_Input5}
        {Var_Input6 == 1 ?  AlteraPIX=PIX : AlteraPIX=Var_Input6}
    
        {Var_Input7 == 1 ?  AlteraTipoPIX='1' : DDFF=1}
        {Var_Input7 == 2 ?  AlteraTipoPIX='2' : DDFF=1}
        {Var_Input7 == 3 ?  AlteraTipoPIX='3' : DDFF=1}
        {Var_Input7 == 4 ?  AlteraTipoPIX='4' : DDFF=1}
        

        api.put("/usuarios_alterar/" + id_usuario , {           
             
            nome: AlteraNome,
            email: AlteraEmail,
            senha: AlteraSenha,
            cpf: AlteraCPF,
            fone: AlteraFone,
            pix: AlteraPIX,
            profissao: profissao,
            pix_tipo: AlteraTipoPIX,
            id_localidade: hotel

            
           

        })
        .then((retorno) => {
             Swal.fire({icon: 'success',  title: 'Sucesso', text: 'Alteração Realizada'})
        })
        .catch(err => console.log(err));


        //Alterando as Variaveis Contexto
        setNome(AlteraNome);
        setEmail(AlteraEmail);
        setSenha(AlteraSenha);
        setCPF(AlteraCPF);
        setFone(AlteraFone);
        setPIX(AlteraPIX);        
        SetTipoPIX(AlteraTipoPIX);

    }   



    //useRef  -   Altera as propriedades de um Elemento
    const elemento1 = useRef();
    const elemento2 = useRef();
    const elemento3 = useRef();
    const elemento4 = useRef();

    

    

    //Função ao Iniciar
    const func_Cor_CPF = () => {
        elemento1.current.style.backgroundColor = 'red';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'blue';         
        set_Var_Input7('1'); 
    }

    //Função ao apertar o Botão
    const func_Cor_CPF_2 = () => {
        elemento1.current.style.backgroundColor = 'red';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'blue';         
        set_Var_Input7('1'); 
        set_Var_Input6('');
    }    

    const func_Cor_Celular = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'red';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'blue'; 
        set_Var_Input7('2');                 
    }

    const func_Cor_Celular_2 = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'red';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'blue'; 
        set_Var_Input7('2'); 
        set_Var_Input6('');                
    }

    const func_Cor_Email = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'red';
        elemento4.current.style.backgroundColor = 'blue';
        set_Var_Input7('3');  
              
    }

    const func_Cor_Email_2 = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'red';
        elemento4.current.style.backgroundColor = 'blue';
        set_Var_Input7('3');  
        set_Var_Input6('');        
    }

    const func_Cor_Chave = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'red'; 
        set_Var_Input7('4'); 
            
    }

    const func_Cor_Chave_2 = () => {
        elemento1.current.style.backgroundColor = 'blue';
        elemento2.current.style.backgroundColor = 'blue';
        elemento3.current.style.backgroundColor = 'blue';
        elemento4.current.style.backgroundColor = 'red'; 
        set_Var_Input7('4');
        set_Var_Input6('');             
    }

    const onChangeCPF = e => {
            const originalValue = unMask(e.target.value);
            const maskedValue = mask(originalValue, [
                "999.999.999-99"                
            ]);
            set_Var_Input4(maskedValue);                        
    };  

    const onChangeCelular = e => {
            const originalValue = unMask(e.target.value);
            const maskedValue = mask(originalValue, [
                "(99)99999-9999"                
            ]);
            set_Var_Input5(maskedValue);        
    };

    const onChangePIX = e => {

        if (Var_Input7 == '1') {
            const originalValue = unMask(e.target.value);
            const maskedValue = mask(originalValue, [
                "999.999.999-99" 
            ]);
            set_Var_Input6(maskedValue); 
        }

        if (Var_Input7 == '2') {
            const originalValue = unMask(e.target.value);
            const maskedValue = mask(originalValue, [
                "(99)99999-9999" 
            ]);
            set_Var_Input6(maskedValue); 
        }

        if (Var_Input7 > '2') {
            set_Var_Input6(e.target.value); 
            }

               /*    Mascaras em REAIS
                import { currency } from 'remask'

                currency.mask({ locale: 'pt-BR', currency: 'BRL', value: 123456.78 })
                // => R$ 123.456,78

                currency.unmask({ locale: 'pt-BR', currency: 'BRL', value: 'R$ 123.456,78' })
                // => 123456.78
                */
  
    };
 

    const func_Altera_ShowDock1 = e => {
        
        setShowDock1(true);                    
    }

    const func_Altera_ShowDock2 = e => {
        
        setShowDock2(true);                    
    }
  
    return (    
        
        <div id='body'>

            <Navbar_Voltar />

                {Logado === false ? <Navigate to='/' /> : null } 
            
                <AnimatedPage1>                
                   

                <div id='css_principal_perfil'>

                   <div className="item item-1">
                        <h1></h1> 
                    </div>

                    <div className="item item-3">

                        <Dock_Profissao /> 
                         
                        <Dock_Hotel />                          

                        <div id="id_perfil">                            
                            <p className='Nome_Campos'>Nome</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='Nome' defaultValue={nome} onChange={(e)=>set_Var_Input1(e.target.value)}/>                            
                        </div>   
                        <div id="id_perfil">
                        <p className='Nome_Campos'>Email</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='Email' defaultValue={email} onChange={(e)=>set_Var_Input2(e.target.value)}/>    
                        </div>  
                        <div id="id_perfil">
                            <p className='Nome_Campos'>Senha</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='Senha' defaultValue={senha} onChange={(e)=>set_Var_Input3(e.target.value)}/>    
                       </div>   
                        <div id="id_perfil">
                            <p className='Nome_Campos'>CPF</p>
                            {/*<input className="id_elemento" type="text" placeholder='CPF'  defaultValue={cpf} onChange={(e)=>set_Var_Input4(e.target.value)}/>  */} 
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='CPF'  onChange={onChangeCPF} value={Var_Input4}/>         
                        
                        </div> 

                        <div id="id_perfil">
                            <p className='Nome_Campos'>Celular</p>
                           
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='Celular'  onChange={onChangeCelular} value={Var_Input5}/>
                        </div>


                        <div id="id_perfil">
                           <p className='Nome_Campos'>Tipo PIX</p> 
                            <div id='css_tipo_pix'>
                                <div className="btn btn-outline-primary" id='largura_botao_2' style={{ backgroundColor: "blue"}} onClick={(e) => func_Cor_CPF_2()} ref={elemento1}>
                                    <h6 id='m_it'>CPF</h6>
                                </div>                                 
                                 
                                <div className="btn btn-outline-primary" id='largura_botao_2'  style={{backgroundColor: "blue"}} onClick={(e) => func_Cor_Celular_2()} ref={elemento2}>
                                    <h6 id='m_it'>Celular</h6>
                                </div>
                                <div className="btn btn-outline-primary" id='largura_botao_2'  style={{backgroundColor: "blue"}} onClick={(e) => func_Cor_Email_2()} ref={elemento3}>
                                    <h6 id='m_it'>Email</h6>
                                </div>
                                <div className="btn btn-outline-primary" id='largura_botao_2'  style={{backgroundColor: "blue"}} onClick={(e) => func_Cor_Chave_2()} ref={elemento4}>
                                    <h6 id='m_it'>Chave</h6>
                                </div>     
                            </div>  
                         
                           {/*<input className="id_elemento" type="text" placeholder='TipoPIX' defaultValue={tipoPIX}/>*/}
                        </div> 

                        

                        <div id="id_perfil">
                            <p className='Nome_Campos'>PIX</p>
                            {/*<input className="id_elemento" type="text" placeholder='PIX'  defaultValue={PIX} onChange={(e)=>set_Var_Input6(e.target.value)}/>*/}
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='PIX'  onChange={onChangePIX} value={Var_Input6} />
                        </div>  

                        <div id="id_perfil">
                            <p className='Nome_Campos'>Hotel</p>
                            <button className="btn btn-outline-primary d-inline-flex align-items-center" id='largura_botao' type="button" onClick={(e) => func_Altera_ShowDock2()} >{var_hotel}</button>
                                                     
                        </div>  


                        <div id="id_perfil">
                            <p className='Nome_Campos'>Atividade</p>                            
                            <button className="btn btn-outline-primary d-inline-flex align-items-center" id='largura_botao' type="button" onClick={(e) => func_Altera_ShowDock1()}  >{profissao}</button> 
                                             
                        </div> 

                        <div className="btn_editar" id="id_perfil"> 
                            <button className="btn btn-outline-primary  mx-4" type="button" id="css_botao_sair8" onClick={(e) =>func_Validar_Campos()}>Salvar</button>
                      
                        </div>

                        
                        
                    </div>

                    <div className="item item-4">                    
          
                    </div>

                </div>
            
            </AnimatedPage1>

            {/*<Footer />   */} 
        
        </div>    

    )
}

export default Perfil;


    /*}

    <button className="btn btn-outline-primary" type="button" id="css_botao_sair" onClick={(e) =>TestaCPF()}>testa CPF</button>
                        

    value={Var_Input4}



    {tipoPIX == 1 ? <p>CPF</p> : tipoPIX == 2 ? <p>Celular</p> : tipoPIX == 3 ? <p>Email</p> : <p>Chave</p> } 
    
    
    onClick={(e) =>func_Alterar_Usuario()}


 <input className="id_elemento" type="text" placeholder='Hotel' defaultValue={var_hotel} onChange={(e) => func_Teste()}/> 

    */ 