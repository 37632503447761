import React, {useState} from 'react';
import {useRef} from 'react';



function FuncHome(){
  
    
    const [Var_Valor, set_Var_Valor] = useState(100);
    const [Var_Texto, set_Var_Texto] = useState('');
    const [Var_Pedidos, FuncAlteraPedidos] = useState([]);

    const [nota1, set_Nota1] = useState(0);
    const [nota2, set_Nota2] = useState(0);
       

    function Func_Somar(){
        set_Var_Valor(Var_Valor + 1);        
    }

    function Func_Subtrair(){
        set_Var_Valor(Var_Valor - 1);
    }

    const Func_Multiplicar = (vl) =>  set_Var_Valor(vl * 2);

    function ConsultarPedidos(){
        //get no servidor

        FuncAlteraPedidos([
            {"id_pedido":1000, "cliente":"Ocelio"},
            {"id_pedido":2300, "cliente":"Sidyna"},
            {"id_pedido":3100, "cliente":"Marina"}
        ])
 
    }

    //Média
    let media = (nota1 + nota2) / 2

    //Situação de Aprovação
    let situacao = media >= 7 ? 'Aprovado' : 'Reprovado';

    //useRef  -   Altera as propriedades de um Elemento
    const elemento = useRef();

    const elemento_2 = useRef();

    //função ACAO
    const acao = () => {
        elemento.current.focus();
        elemento.current.style.backgroundColor = 'orange';
        elemento.current.value = 'Digite ...';

        elemento_2.current.value = 'Digite ...';
        elemento_2.current.style.backgroundColor = 'blue';
        elemento_2.current.disabled=true;
    }

    const acao_2 = () => {

        elemento_2.current.value = '';
        elemento_2.current.style.backgroundColor = 'orange';
        elemento_2.current.disabled=false;
        elemento_2.current.focus();
    }

    
    return <div> 

    <button type="button" onClick={acao}>useRef 1</button>
    <button type="button" onClick={acao_2}>useRef 2</button>
           
     <h5>Insira um Valor</h5>
     <input type="text" onChange={(e)=>set_Var_Texto(e.target.value)} ref={elemento} /> 

     <h5>testar outro UseRuf</h5>
     <input type="text" onChange={(e)=>set_Var_Texto(e.target.value)} ref={elemento_2} /> 



     <h1>{Var_Texto}</h1> 
     

     <button type="button" onClick={Func_Somar}>+</button>
     <button type="button" onClick={Func_Subtrair}>-</button> 
     <button type="button" onClick={(e) => Func_Multiplicar(Var_Valor)}>Dobrar</button>          
     <button type="button" onClick={(e) => Func_Multiplicar(Var_Texto)}>Edit x 2</button> 
     <h1>{Var_Valor}</h1>     
     <button type="button" onClick={ConsultarPedidos}>Consultar Pedidos</button>        

        {
            Var_Pedidos.map(function(e){
                return <div>                
                     <small>Pedido: {e.id_pedido} - Cliente: {e.cliente} </small><br />

                    <p>{FuncAlteraPedidos.cliente}</p>

                </ div>
          })
        }  

        <hr></hr>
        <h5>Insira as Notas 1 e 2</h5>
        <input type="number" placeholder='Primeira Nota' onChange={(e)=>set_Nota1(parseFloat(e.target.value))} />  
        <input type="number" placeholder='Segunda Nota' onChange={(e)=>set_Nota2(parseFloat(e.target.value))} />  

        {nota1 && nota2 ? <h5>{situacao} com média {media}</h5> : ''}
    
    <hr></hr>


    

    
    </div>
    

    }    
    export default FuncHome;

 
    

  