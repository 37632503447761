
import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./sub_menu.css";

import{use, useState} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import Swal from 'sweetalert2'; //Alertas



function Sub_Menu(){ 

    
    const {nome, Logado, setShow, id_usuario} = use(MeuContexto);   
      
       
        useEffect(() =>{            
            if (Logado == false){
                    Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
                }  
                         
        },[]);


    const [linkar, setLinkar] = useState('');

    function func_Linka_Historico(){
        setLinkar('Historico_Clientes');
    }

    function func_Linka_Novos_Clientes(){ 
            setLinkar('Linka_Novos_Clientes');                
    }

    function func_Recuperar_Clientes(){ 
            setLinkar('Recuperar_Clientes');                 
    }

    function func_Recolhimento(){ 
        if (id_usuario != 1){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Recolhimento');   
        }          
    }

    function func_Linka_Devedores(){ 
        if (id_usuario != 1){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Lista_Devedores');   
        }          
    }

    function func_Consultar_Pagamentos(){ 
        if (id_usuario != 1){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Consultar_Pagamentos');   
        }          
    }

    function func_Voltar(){ 
        setLinkar('Voltar');   
    }
    
  
return ( 
    
         <div id="id_div_primaria">
        
            <div id="id_div_secundaria">                                    

                    {Logado === false ? <Navigate to='/' /> : null }          


                    <div id="operador_51">                    
                        <h2>{nome}</h2>                  
                    </div> 

                    <div>
                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Linka_Historico}  type="button">Histórico de Clientes</button>
                                            {linkar === 'Historico_Clientes' ? <Navigate to='/Historico_Clientes' /> : null}              
                                                
                    </div> 

                    <div>
                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Linka_Novos_Clientes} type="button">Novos Clientes</button>
                                            {linkar === 'Linka_Novos_Clientes' ? <Navigate to='/Listar_Novos_clientes' /> : null}                           
                            
                    </div>
        
                    <div>                                                
                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Recuperar_Clientes} type="button">Recuperar Clientes</button>
                                            {linkar === 'Recuperar_Clientes' ? <Navigate to='/Listar_Ult_Atendimento' /> : null}                                                                             
                    </div>

                    <div>                                                
                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Recolhimento} type="button">Recolhimento</button>
                                            {linkar === 'Recolhimento' ? <Navigate to='/Caixa_Fechar' /> : null}                                                                             
                    </div>
                    
                    <div>

                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Consultar_Pagamentos} type="button">Consultar Pagamentos</button>
                                            {linkar === 'Consultar_Pagamentos' ? <Navigate to='/Pagamentos_Consulta' /> : null} 
                                      
                                            
                    </div>

                    <div>                                                               
                                            <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Linka_Devedores} type="button">Devedores</button>
                                            {linkar === 'Lista_Devedores' ? <Navigate to='/Lista_Devedores' /> : null}                           
                    </div> 

                    <div>
                                            <button onClick={() => func_Voltar()} className="btn btn-primary ms-3"><i className="bi bi-box-arrow-left"></i></button>   
                                            {linkar === 'Voltar' ? <Navigate to='/principal' /> : null}
                    </div>               
                    
                    <div>
                            <h6>Sistema de Gestão Sidyna Lima</h6>
                    </div>            
            </div>
        </div> 

    )
}

export default Sub_Menu;