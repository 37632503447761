import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Swal from 'sweetalert2';
import {Navigate} from "react-router-dom";


export function Clientes_Menu() {

    const { nome, Logado, fone } = use(MeuContexto)

    const [linkar, setLinkar] = useState('');

    useEffect(() =>{            
        if (Logado == false){
                Swal.fire({icon: 'error',  title: 'Atenção', text: 'O Usuario Não Está Logado!'}) 
                                                  
            }                     
    },[]);

    function func_Indicar_Cliente(){        
        setLinkar('Indicar_Clientes');
    }

    function func_Alterar_Senha(){        
        setLinkar('Alterar_Senha');
    }

    return (    
        
        <div>

            {Logado === false ? <Navigate to='/encerrar' /> : null } 

            <br/>
            <p>{nome}</p>  
            <p>{fone}</p>            
            <br/>

            
                                        <div>
                                                <button  type="button">Seu Histórico</button>
                                                {linkar === 'Pagina_Comissao' ? <Navigate to='/vendas' /> : null}
                                        </div>
                                            <br/>
                                        <div>
                                                <button type="button" onClick={func_Indicar_Cliente}>Indicar Clientes</button>
                                                {linkar === 'Indicar_Clientes' ? <Navigate to='/Indicar_Clientes' /> : null}
                                
                                        </div>
                                            <br/>
                                        <div>
                                                                  
                                                                    <button type="button">Seus Créditos</button>            
                                                                  
                                        </div>
                                            <br/>
                                        <div>
                                                                  
                                                                    <button type="button">Fidelidade</button>            
                                                                  
                                       </div>
                                              <br/>
                                        <div>
                                                                    <button type="button">Prêmios</button>
                                                                    {linkar === 'Historico_Clientes' ? <Navigate to='/Historico_Clientes' /> : null}              
                                                                    
                                        </div>
                                        <br/>
                                        <div>
                                                                    <button type="button" onClick={func_Alterar_Senha}>Alterar Senha</button>
                                                                    {linkar === 'Alterar_Senha' ? <Navigate to='/Alterar_Senha' /> : null}
                                 
                                        </div>          

                 
        </div> 
    
    ) 
    
}

