import api from "../../services/api";
import { useState } from 'react';
import { useActionState } from "react";
import { useFormStatus} from 'react-dom';
import Swal from 'sweetalert2';
import {useRef} from 'react';
import { mask, unMask } from 'remask';  //mascaras
import{use} from 'react';
import MeuContexto from '../../context/context.jsx';
import {Navigate} from "react-router-dom";

export function Cliente_Login() {

    const { setNome, setCod_cliente_cont, setLogado, setFone } = use(MeuContexto) 
    
    const [linkar, setLinkar] = useState('');

    const myinput = useRef(); 

    const inputfocus = () => {
       myinput.current.focus();                     
    }

    const myinput_senha = useRef(); 

    const inputfocus_senha = () => {;
        myinput_senha.current.focus();                     
     }

    //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
    const [var_CPF, setVar_CPF] = useState("");

    const onChangeCPF = e => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "999.999.999-99"                
        ]);
        setVar_CPF(maskedValue);        
    };
    //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

    const func_login = async (previousState, traz) => {
            
            const Var_CPF = traz.get('input_CPF')
            const Var_Senha_Digitada = traz.get('input_senha')

            var Var_Nome='';
            var Var_Senha='';
            var Var_Cod='';

            //Só parando Um tempinho
            //await new Promise(resolve => setTimeout(resolve, 2000))

            try{        
            await api.post("/login_cliente" , {
                    X01: Var_CPF
            })
            .then((response) => {                
                    Var_Nome=response.data.nome
                    Var_Senha=response.data.senha 
                    Var_Cod=response.data.cod 
                    
                    setFone(response.data.celular)                  

                        if (Var_Nome==undefined) 
                        {
                            inputfocus();
                            Swal.fire({icon: 'error',  title: 'Erro', text: 'CPF Não Cadastrado!'}) ; 
                        } 
                        else if(Var_Senha!=Var_Senha_Digitada)
                        {
                                inputfocus_senha();
                                Swal.fire({icon: 'error',  title: 'Erro', text: 'Senha Não Confere!'}) ; 
                        }
                        else
                        {
                            setNome(response.data.nome)
                            setCod_cliente_cont(response.data.cod)
                            setLogado(true)
                            setLinkar('ENTRAR'); 
                        }
                             
            })     
            } catch(error){  
                          inputfocus();          
                          Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                       
            }              
            return {
                cliente: Var_Nome,               
                senha_digitado: Var_Senha_Digitada
            } 
   
    }

    const [leva, Acao_01] = useActionState(func_login, { cliente: "", senha: "", senha_digitado: ""})

        
    return (
            <>

                            <form action={Acao_01}> 
                                <div> 
                                    <br/>
                                    <small className="text-secondary">CPF</small>       
                                    <br/>                                  
                                    <input type="text" name="input_CPF" autoFocus onChange={onChangeCPF} value={var_CPF} ref={myinput}/>                                               
                                    <br/>                            
                                    <small className="text-secondary">Senha</small>       
                                    <br/>                                  
                                    <input type="text" name="input_senha" defaultValue={leva.senha_digitado} ref={myinput_senha}/>                                               
                                    <br/>  
                                    <br/>                                                                   
                                    <BOTAO/>
                                    {linkar === 'ENTRAR' ? <Navigate to='/Clientes_Menu' /> : null}
                                    <br/>
                                    <p>{leva.cliente}</p> 
                                   

                                </div>
                            </form>

                          

   
            </>
    )   
    
}
    
    
    function BOTAO(){    
             const { pending } = useFormStatus();        
             return(
                <div>
                    <button type='submit' disabled={pending}>
                        {pending ? "Verificando..." : "Login"}
                    </button>   
                </div>    
                )   
    }

   
    
    

