
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';

//<button onclick={()=>mastrarAlesta()}>Mostrar Alerta 1</button>

/*
const mastrarAlesta=()=>{
    Swal.fire({icon: 'question', 
                title: 'Pergunta', 
                text: 'O que será que será?'})} 
//success, error, warning, info, question   */

/*
        Swal.fire({icon: 'question', 
                    title: 'Pergunta', 
                    html: '<p>O que será que será <p>que andam <b>combinando</b> no breu das tocas'})}

*/

function Alertas(){

    useEffect(()=>{
        mastrarAlerta();
    }, {});

    const mastrarAlerta=()=>{
        Swal.fire({icon: 'question', 
                    title: 'Pergunta', 
                    text: 'Deseja realmente deletar esse registro',
                    showDenyButton: true,
                    denyButtonText:"Não",
                    confirmButtonText: "Sim",
                    confirmButtonColor: "blue",

                }).then(response => {
                    if(response.isConfirmed){
                        Swal.fire({icon: 'success',  title: 'Exclusão', text: 'Realizada com Sucesso'})
                    }else if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                    }               
        })   
            
    }



    return (        
        <div className="Alertas">         
         

        </div>
    );
}

export default Alertas;






