import Use_fetch from "./componentes/use_fetch"

import Use_fetch_traz_valor from "./componentes/use_fetch_traz_valor";

import{useState, use} from 'react';
import { useActionState } from "react";
import { useFormStatus} from 'react-dom';
import Swal from 'sweetalert2';
import {useRef, Suspense} from 'react';
import { URL_2, headers_2 } from "../../../../services/api";

export const Estudo_fetch = () => {

    const myinput = useRef();
    
    const [codigo, setCodigo] = useState('');

    const inputfocus = () => {            
       setCodigo(myinput.current.value)       
       //myinput.current.focus();                     
    } 
    
    function Acao_2(recebe_do_form){

        const Var_Codigo = recebe_do_form.get('input_2');                   
       
    } 

     
     const func_Recebe_Resultado = async (previousState, recebe_do_form) => {
            
      const Var_1 = recebe_do_form.get('input_1')
      var Var_Nome='';      
      
      const params = {     
        cod: Var_1,   
      };
    
      //try {   
        
          const res = await fetch(URL_2 + "/traz_cliente", 
            {method:'POST', 
            headers: headers_2,
            body: JSON.stringify( params )        
            });           
            
            const data = await res.json();

            if (!res.ok) {console.log(data.description); return;}

            if (res.status==='401') {alert('Código Não Cadastrado')}

            console.log(res.status);

            Var_Nome = data.nome;            


       // } catch (error) {  
       //       inputfocus(); 
       //       //console.log(error);         
       //       Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Ao Acessar Dados... A Internet pode Estar Desconectada!'}) ;           
       // }
      
      return {
          valor: Var_Nome,          
      } 

}

const [traz, Acao_01] = useActionState(func_Recebe_Resultado, { valor: ""})
 
 

    const [linkar, setLinkar] = useState('');

    function func_Use_fetch(){       
        setLinkar('X_01');        
    }

    return( 
        
        <div>            

            <Use_fetch_traz_valor/>

            <br/>

                
                    <div>
                        <form action={Acao_01}>

                            <small className="text-secondary">Código</small> 
                            <br></br>
                            <input type="text" name="input_1" ref={myinput}/> 
                            <br></br> 
                            < BOTAO_2/>                                                     
                            <h3>O Nome é: {traz.valor}</h3>
                            
                        </form>
                    </div>            
            
            <br/>
            <div>                             
            <button  onClick={func_Use_fetch} type="button">API com USE</button>
            <br/>

            {linkar === 'X_01' ? <Use_fetch /> : null}                           
            </div>
            
            <div>
                    <form action={Acao_2}> 
                                <div>

                                    <br/>
                                    <small className="text-secondary">Código do Cliente</small>       
                                    <br/>                                  
                                    <input type="text" name="input_2" ref={myinput}/>                                               
                                    <br/> 
                                    <br/>                                                                                                    
                                    <BOTAO/>
                                    <br/>                                  
                                                                       
                                </div>
                    </form>

            </div>

        </div>

    )
} 

function BOTAO(){    
    const { pending } = useFormStatus();        
    return(
       <div>
           <button type='submit' disabled={pending}>
               {pending ? "Executando..." : "Executar"}
           </button>   
       </div>    
       )   
}

function BOTAO_2(){    
  const { pending } = useFormStatus();        
  return(
     <div>
         <button type='submit' disabled={pending}>
             {pending ? "Execultando..." : "Consultar"}
         </button>
         {pending ? <h5>Execultando a Consulta</h5> : <h5>consulta Executada</h5>}   
     </div>    
     )   
}





 