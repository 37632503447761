
import {Link} from "react-router-dom";

import "./cadastro_de_clientes.css";

import api from "../../services/api.js"
import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
//import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

//import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";

import { mask, unMask } from 'remask';  //mascaras


function Cadastro_de_Clientes() { 
   
    const {show, setShow, nome_produto_cont, setProduto_cont, cod_produto_cont, setCod_produto_cont} = use(MeuContexto);


//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo


    
    //---------------------------------------------------------------
        // Gerar String Aleatória
        function geraStringAleatoria(tamanho) {
            var stringAleatoria = '';
            var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
            for (var i = 0; i < tamanho; i++) {
                stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }
            return stringAleatoria;    
        }

        var ChaveUsuario=geraStringAleatoria(4);

    //--------------------------------------------------------------- 
   
    var Var_cod='';
    var Var_nome='';
    var Var_celular='';
    var Var_email='';
    var Var_login='';
    var Var_senha='';


    function Acao_1(ObterDados){
   
        Var_nome = ObterDados.get("imput_nome")
        Var_celular = ObterDados.get("imput_celular")
        Var_email= ObterDados.get("imput_email")
        Var_login = ObterDados.get("imput_login")
        Var_senha = ObterDados.get("imput_senha")

        //VERIFICA SE OS CAMPOS ESTÃO VAZIOS
        if ((Var_nome == '') || (Var_celular ==  '')) 
            {
            Swal.fire({icon: 'error',  title: 'É Necessário', text: 'Preencher pelo menos o Nome e o Celular!'});
            setShow(false);
            }
        else
            {                                          
                func_Incluir();        
            }         

    }

    var Var_Repeticao='';

    const func_Incluir = async () => {


        try{

            await api.post("/verifica_repeticao" , {
              nome: Var_nome,
              chave: ChaveUsuario
              
            })
            .then((response) => {         
                Var_Repeticao = response.data.Tot_registros;                
            })      
            } catch(error){
                     Swal.fire({icon: 'error',  title: 'Erro', text: 'houve problemas!'})          
            }


            if (Var_Repeticao == '0') 
                {

                            //Nesse caso não há repetição
                             //Traz o Proximo cod
                             try{
                                await api.post("/proximo_cod")     
                                .then((response) => {                 
                                    Var_cod = response.data.proximo_cod;             
                                })               
                                    } catch(error){          
                                        console.log(error);
                            } 
                            
                            //Incluir Registro
                            try{
                                await  func_Incluir_registro()    
                                .then(() => {
                                    //nada a fazer
                                 })               
                                    } catch(error){          
                                        console.log(error);    
                            }
                }
            else
                {                    
                    
                    Swal.fire({icon: 'error',  title: 'Operação Inválida', text: 'Nome e/ou Usuario Já existem na Base de Dados.'});
                    setShow(false); 
                
                }         
                               

    }

    function func_Incluir_registro() {

        setShow(false);

        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'A Inclusão do Cliente?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",
                
              

            }).then(response => {
                if(response.isConfirmed){ 
                                           
                    
                        api.post("/cadastrar_cliente" , { 
                            cod: Var_cod,
                            nome: Var_nome,
                            celular: Var_celular,
                            email : Var_email,
                            chave : ChaveUsuario,
                            login : Var_login,
                            senha : Var_senha                                            

                        })                           

                        .then((retorno) => {                                    

                                Swal.fire({icon: 'success',  title: 'Cadastramento', text: 'Realizado com Sucesso!'})              
                                
                                //setSucesso('S');                                                                    
                                
                        })
                        .catch(err => console.log(err));                                                

                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            })                 

    }

 

       //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
            const [var_celular, setVar_Selular] = useState("");

            const onChangeCelular = e => {
                const originalValue = unMask(e.target.value);
                const maskedValue = mask(originalValue, [
                    "(99) 99999-9999"                
                ]);
                setVar_Selular(maskedValue);        
            };
        //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo


return <Dock position="right"
    isVisible={show}
    fluid={false}
    size={600}     
    >

<div className="container-fluid h-100 pt-4 sidebar p-4 ">                   

                        <div>                       
                            <h4 id="css_texto_inicial">Cadastrar Clientes</h4>                       
                        </div>                                           

                        <div className="col-12 mt-3"> 

                            <form action={Acao_1}>          

                                <small className="text-secondary">Nome</small>
                                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_nome" /> 

                                <small className="text-secondary">Celular</small>
                                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_celular" onChange={onChangeCelular} value={var_celular} /> 

                                <small className="text-secondary">E-mail</small>
                                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_email" /> 

                                <small className="text-secondary">Login</small>
                                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_login" /> 

                                <small className="text-secondary">Senha</small>
                                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_senha" /> 
                        
                                <div className="col-12 mt-3"> 

                                    <button className="btn btn-outline-primary me-3" type="submit">Cadastrar</button>
                                
                                    <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Fechar</button>
                                                    
                                </div>


                            </form>        
                                
                        </div>                     
                       
                </div>

</Dock>


}

export default Cadastro_de_Clientes;