import api from "../../services/api.js"

import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./lista_fechamento.css";

import{useState} from 'react';

import DataTable from 'react-data-table-component';

import Swal from 'sweetalert2';    //Alertas

import { Dock } from "react-dock";

import {useRef} from 'react';




function Lista_Fechamento(){

    const [dt_pedido, setDtPedido] = useState("");

    const [dt_pedido_2, setDtPedido_2] = useState("");

    const [lista, setLista] = useState([]);       

    var Data_1 = ''; 

    var Data_2 = ''; 
    

    
const func_Receber_lista = async () => {   
    try{ 
                    
              await api.post("/Listar_Fechamento" , { 
                data_inicio: Data_1,               
                data_fim: Data_2 
                })

              .then((response) => { 
                  setLista(response.data);
                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

      const [var_cartao, setVar_cartao] = useState("");
      const [var_pix, setVar_pix] = useState("");
      const [var_dinheiro, setVar_dinheiro] = useState("");
      const [var_vale, setVar_vale] = useState("");
      const [var_total, setVar_total] = useState("");

const func_Receber_Totais = async () => {   
                try{ 
                        
                  await api.post("/Listar_Fechamento_Totais" , { 
                    data_inicio: Data_1,               
                    data_fim: Data_2 
                    })
    
                  .then((response) => {
                       
                       //setTotal_venda(response.data.venda_total);
                       setVar_cartao(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.cartao));
                       setVar_pix(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.pix));
                       setVar_dinheiro(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.dinheiro));
                       setVar_vale(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.vale));
                       setVar_total(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.total));
                                
    
                    })            
                 } catch(error){          
                    console.log(error);
                    }       
          } 



const clickConsultar = async () => { 
    Data_1 = dt_pedido;
    Data_2 = dt_pedido_2;
    await func_Receber_lista();    
    await func_Receber_Totais();
}
  

const columns = [
 
    {
        name: 'DATA',        
        selector: row => row.DT,
        width: "130px",   //largura 
        right: true,
        //compact: true,
        sortable: true            //cria ordenação
    },
    {
        name: 'CLIENTE',
        selector: row => row.nome,       
        //width: "300px",   //largura 
        left: true,
        compact: true,
        sortable: true            //cria ordenação
    },
      {
          name: 'CARTÃO',
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.cartao),
          //width: "140px",   //largura 
          compact: true,
          right: true,
          sortable: true            //cria ordenação
      },        
      {
          name: 'PIX',          
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.pix),
          //width: "140px",  
          compact: true,
          right: true,
          sortable: true            //cria ordenação 
      },
      {
        name: 'DINHEIRO',          
        selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.dinheiro),
        //width: "140px", 
        compact: true, 
        right: true,
        sortable: true            //cria ordenação 
    }, 
    {
        name: 'VALE',          
        selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.vale),
        //width: "140px", 
        compact: true, 
        right: true,
        sortable: true            //cria ordenação 
    }, 
    {
        name: 'TOTAL',          
        selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.total),
        width: "140px",
        //compact: true,  
        right: true,        
        sortable: true            //cria ordenação 
    }             
   

]

  

return(

    <div id="tela_inicial_k">    

            <br></br>
           

            
            <div id="tela_55">

                    <div id="css_509">
                   
                        <div>
                            <h6>Data Inicial</h6>
                        </div>

                        <div id="css_500">   
                                <div id="css_501">                            
                                    <input type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                </div> 
                                <div>
                                    {dt_pedido}    
                                </div>                   
                        </div>

                    </div>

                    <div id="css_509">
                   
                        <div>
                            <h6>Data Final</h6>
                        </div>

                        <div id="css_500">   
                                <div id="css_501">                            
                                    <input type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                </div> 
                                <div>
                                    {dt_pedido_2} 
                                </div>
                   
                         </div> 
                   

                    </div>

                    <div>
                                   <button className="btn btn-outline-primary" id="largura_btn_50"  onClick={clickConsultar} type="button">Consultar</button>
                    </div>


                <div id="css_505_V2">
                        <h5>Cartão:</h5> 
                        <h5>{var_cartao}</h5>  
                 </div> 

                 <div id="css_505_V2">
                         <h5>PIX:</h5>
                         <h5>{var_pix}</h5>   
                 </div> 
                 <div id="css_505_V2">
                        <h5>Dinheiro:</h5>  
                        <h5>{var_dinheiro}</h5>   
                 </div> 
                 <div id="css_505_V2">
                         <h5>Vale:</h5> 
                         <h5>{var_vale}</h5>   
                 </div>
                 <div id="css_505_V2">
                         <h5>Total:</h5> 
                         <h5>{var_total}</h5>   
                 </div>
                 <div>
                       <Link to="/principal" className="btn btn-outline-primary" id="largura_btn_50Z" >Sair</Link>                          
                 </div>


            
            </div>

              <br></br>  
              <br></br>
                    
            <div> 
                    <br></br>
                                          
                    <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        //pagination = {true}         //Põe a paginação 
                        //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima 
                                                  
                    /> 
            </div>         
                 

        

    </div>  
    
)}

export default Lista_Fechamento;


//<Link to="/principal" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>

