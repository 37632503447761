import { useState} from 'react';


import { useEffect } from "react";

import "./caixa_fechar.css";

import api from "../../services/api.js";

import Swal from 'sweetalert2';    //Alertas

import {Link} from "react-router-dom";


function Caixa_Fechar() { 

    //const { id_usuario } = useContext(MeuContexto);

    const [cartao, setCartao] = useState("");  
    const [pix, setPix] = useState("");  
    const [dinheiro, setDinheiro] = useState("");  
    const [vale, setVale] = useState(""); 

        
    
    
    useEffect(() =>{
        
        func_traz_valores_a_recolher();

    },[]);

    useEffect(() =>{        
        Traz_Total_Cartao();
    },[cartao]);

    async function func_traz_valores_a_recolher() {

        //--------------------CARTAO------------------------------------
        await Traz_Total_Cartao();
        //------------------PIX-------------------------------------------------
        await Traz_Total_PIX();
        //-------------------DINHEIRO------------------------------------------------
        await Traz_Total_Dinheiro();
        //-------------------VALE-------------------------------------
        await Traz_Total_Vale();
        //-------------------------------------------------------------------
        
    }

    async function Traz_Total_Cartao() {

         //--------------------CARTAO------------------------------------
         try{

            await api.post("/traz_cartao_a_recolher" , {
                situ_cartao: 'N',
                caixa: '2'
            })
            .then((response) => {                                     
                
                setCartao(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.cartao)); 
                 
                
            })           
    
            } catch(error){                    
                    Swal.fire({icon: 'error',  title: 'Erro', text: 'Algo Inesperado Aconteceu!'})          
                }


    }

    async function Traz_Total_PIX () {

        try{

            await api.post("/traz_pix_a_recolher" , {
                situ_pix: 'N',
                caixa: '2'
            })
            .then((response) => {                                     
                setPix(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.pix));
                          
            })           
    
            } catch(error){                    
                    Swal.fire({icon: 'error',  title: 'Erro', text: 'Algo Inesperado Aconteceu!'})          
                }

    }

    async function Traz_Total_Dinheiro () {
        try{

            await api.post("/traz_dinheiro_a_recolher" , {
                situ_dinheiro: 'N',
                caixa: '2'
            })
            .then((response) => {                                     
                setDinheiro(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.dinheiro));
                          
            })           
    
            } catch(error){                    
                    Swal.fire({icon: 'error',  title: 'Erro', text: 'Algo Inesperado Aconteceu!'})          
                }

    }

    async function Traz_Total_Vale () {
        try{

            await api.post("/traz_vale_a_recolher" , {
                situ_vale: 'N',
                caixa: '2'
            })
            .then((response) => {                                     
                setVale(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.vale));
                          
            })           
    
            } catch(error){                    
                    Swal.fire({icon: 'error',  title: 'Erro', text: 'Algo Inesperado Aconteceu!'})          
                }

    }

           


    async function clickCartao() { 

        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'O Recolhimento em Cartão?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",             

            }).then(response => {
                if(response.isConfirmed){ 
                        
                    //---------------------------------------------------------------------------
                
                    try{
                                api.put("/recolher_valor_cartao" , { 
                                       caixa: '2'
                                    })
                                     
                                .then((response) => {                                     
                                    
                                    const Var_Texto = response.data.Var_Resp_BackEnd;  // Se eu precisar de uma resposta do BackEnd
                                                                        
                                    Swal.fire({icon: 'success',  title: 'Recolhimento', text: 'realizado com Sucesso!'}) ;
                                    
                                    Traz_Total_Cartao();                                   

                                })               
                                } catch(error){          
                                    console.log(error);
                                }

                                

                //------------------------------------------------------------------------                                                

                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            }) 

    }

    function clickPIX() {


        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'O Recolhimento em PIX?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",             

            }).then(response => {
                if(response.isConfirmed){ 
                        
                    //---------------------------------------------------------------------------
                
                    try{
                                api.put("/recolher_valor_pix" , { 
                                       caixa: '2'
                                    })
                                     
                                .then((response) => {                               
                                    
                                    const Var_Texto = response.data.Var_Resp_BackEnd;  // Se eu precisar de uma resposta do BackEnd
                                                                        
                                    Swal.fire({icon: 'success',  title: 'Recolhimento', text: 'realizado com Sucesso!'}) ;
                                    
                                    Traz_Total_PIX();                                    

                                })               
                                } catch(error){          
                                    console.log(error);
                                }

                                

                //------------------------------------------------------------------------                                                

                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            }) 

    }


    function clickDinheiro() {


        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'O Recolhimento em Dinheiro?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",             

            }).then(response => {
                if(response.isConfirmed){ 
                        
                    //---------------------------------------------------------------------------
                
                    try{
                                api.put("/recolher_valor_dinheiro" , { 
                                       caixa: '2'
                                    })
                                     
                                .then((response) => {                               
                                    
                                    const Var_Texto = response.data.Var_Resp_BackEnd;  // Se eu precisar de uma resposta do BackEnd
                                                                        
                                    Swal.fire({icon: 'success',  title: 'Recolhimento', text: 'realizado com Sucesso!'}) ;
                                    
                                    Traz_Total_Dinheiro();  
                                    

                                })               
                                } catch(error){          
                                    console.log(error);
                                }

                                

                //------------------------------------------------------------------------                                                

                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            }) 
    }

     
    function clickVale() {

    
        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'O Recolhimento em Vale?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",             

            }).then(response => {
                if(response.isConfirmed){ 
                        
                    //---------------------------------------------------------------------------
                
                    try{
                                api.put("/recolher_valor_vale" , { 
                                       caixa: '2'
                                    })
                                     
                                .then((response) => {                               
                                    
                                    const Var_Texto = response.data.Var_Resp_BackEnd;  // Se eu precisar de uma resposta do BackEnd
                                                                        
                                    Swal.fire({icon: 'success',  title: 'Recolhimento', text: 'realizado com Sucesso!'}) ;
                                    
                                    Traz_Total_Vale();                                     

                                })               
                                } catch(error){          
                                    console.log(error);
                                }

                                

                //------------------------------------------------------------------------                                                

                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            }) 
    }



    return (
       <div>
        
            <div id="css_62_F1">
                
                    <div>
                        
                        <h3>Situação de Caixa para Recolhimento</h3>
                                                
                    </div>

                    <div>
                         <Link to="/sub_menu" className="btn btn-outline-primary" id="largura_btn_50_F" >Sair</Link>
                         
                    </div>                        
                        
            </div>
  

            <div>

                <div id="css_60">
                    <div id="css_61">
                        <h4>CARTÃO:</h4>                     
                    </div>
                    <div id="css_61">
                        {cartao}
                    </div>
                    <div id="css_61">
                        <button className="btn btn-outline-primary" onClick={clickCartao} id="largura_botoes"  type="button">Recolher Cartão</button>
                    </div>
                </div>

                <br></br>

                <div id="css_60">
                    <div id="css_61">
                        <h4>PIX:</h4>                     
                    </div>
                    <div id="css_61">
                         {pix}
                    </div>
                    <div id="css_61">
                        <button className="btn btn-outline-primary" onClick={clickPIX} id="largura_botoes"  type="button">Recolher PIX</button>
                    </div>
                </div>

                <br></br>

                <div id="css_60">
                    <div id="css_61">
                        <h4>DINHEIRO:</h4>                     
                    </div>
                    <div id="css_61">
                         {dinheiro}
                    </div>
                    <div id="css_61">
                        <button className="btn btn-outline-primary" onClick={clickDinheiro} id="largura_botoes"  type="button">Recolher Dinheiro</button>
                    </div>
                </div>

                <br></br>

                <div id="css_60">
                    <div id="css_61">
                        <h4>VALE:</h4>                     
                    </div>
                    <div id="css_61">
                         {vale}
                    </div>
                    <div id="css_61">
                        <button className="btn btn-outline-primary" onClick={clickVale} id="largura_botoes"  type="button">Recolher Vale</button>
                    </div>
                </div>
                <br></br>
             
                
            </div>

       </div>
           
    )

}




export default Caixa_Fechar;