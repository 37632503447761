
import React from 'react';
import ReactDOM from 'react-dom/client';
import Rotas from "./rotas.js";
//import "./style/global.css";
import {  BrowserRouter as Router} from "react-router-dom";





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(    
    
          <Router>
               <Rotas />
          </Router>     
   
);