import { Suspense, use, useState } from "react"

export function UseFetchExemple () {

    const [name, setName] = useState('')    

    const api_consulta = () => {

        if (!name) return Promise.resolve()               

        return fetch(`https://pokeapi.co/api/v2/pokemon/${name}`)

        .then(res => {
            if (res.ok) return res.json()
            return { error: true, message: 'Não se Pode Encontrar o Pokemon!'}
         })
    }

    return (

        <div>
            <form>        
                    <input placeholder='Digite o Nome' value={name} onChange={e => setName(e.target.value)}/>        
            </form>

            <Suspense fallback={<div>Carregando...</div>}>
                <Funcao_Exibir CHAMA={api_consulta()} />
            </Suspense>

        </div>

    )
}

function Funcao_Exibir ({CHAMA}) {
    
    const pokemon = use(CHAMA)

    if (pokemon?.error) {
        return <div>{pokemon.message}</div>
    }

    if (!pokemon) return null

    return(
        <div>
            Resultado:
            <article>
                <h3>{pokemon.name}</h3>
                <img src={pokemon.sprites.front_default} alt={pokemon.neme} />
                <p>Peso: {pokemon.weight}kg</p>
            </article>
        </div>
    )

}

{/*
<article>
<h3>{pokemon.name}</h3>
<img src={pokemon.sprites.front_default} alt={pokemon.neme} />
<p>Peso: {pokemon.weight}kg</p>
</article>
*/}
