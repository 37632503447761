import ScaleLoader from "react-spinners/ScaleLoader";
import "./loading.css";

function Loading() {
 
  let loading = true;
 
  return (
    <div className="loader_container_1">
     
        <div className="loader_container_2">
             <ScaleLoader color={'blue'} />
        </div>

    </div>
  );
}

export default Loading;







