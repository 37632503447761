import "./footer.css";



function Footer(){

   const style_3 = {color: "primary"};

   

   return(

        <div className="Footer bg-black">
            <div>
               <h6 style={style_3}> Sistema de Gestão Amarok - Ocelio Mendonça</h6>
            </div>           

        </div>
   
   )
}

export default Footer;

//<nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary ps-3 pe-3">

//#c0f050   #d5fa06
