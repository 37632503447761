
import "./historico.css";
import AnimatedPage1 from "../../AnimatedPage1.jsx";
//import Footer from "../footer/footer.jsx";
import api from "../../services/api.js"

import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";

import Footer from "../footer/footer";
import Navbar_Voltar from "../navbar/navbar_comissao";

import Swal from 'sweetalert2'; //Alertas
import {Navigate} from "react-router-dom";



function Historico(){     

    const {nome, profissao, id_usuario, Logado} = use(MeuContexto);      

    const style_1 = {fontSize:"20px"};
    const style_2 = {fontSize:"12px"}; 

    const [valor, setValor] = useState("");
    const [valor1, setValor1] = useState("");
    const [valor2, setValor2] = useState("");
    const [valor3, setValor3] = useState("");
    const [valor4, setValor4] = useState("");
    const [valor5, setValor5] = useState("");


   
    useEffect(() =>{
        func_Historico();

        if (Logado == false){
            Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
        } 

    },[]);
    
    const func_Historico = async () => {
    try{

        var dataAtualX = new Date();  
        //console.log(dataAtual);  
        var AnoAtualX = dataAtualX.getFullYear();            
        var MesAtualX = dataAtualX.getMonth()+1; 
        
        //----------------------------------------------------------
                //Consulta Comissão Total dos Ultimos 6 Meses
                //Consulta Comissão recebida mes anterior
                var VarAno1 = 0;
                var VarMes1 = 0;
            
                {MesAtualX == 1 ? VarMes1=12 : VarMes1=MesAtualX-1} 
                {MesAtualX == 1 ? VarAno1=AnoAtualX-1 : VarAno1=AnoAtualX} 
       

                await api.post("/comissao_mes" , { 
                    MES : VarMes1,
                    ANO : VarAno1,       
                    id_usuario: id_usuario
                })
                .then((response) => { 

                    setValor(response.data.Tot_Com);          
                    
                })  
                
         //------------------------------------------------------------- 
         //Consulta Comissão recebida mes anterior   
         var VarAno2 = 0;
         var VarMes2 = 0;
     
         {VarMes1 == 1 ? VarMes2=12 : VarMes2=VarMes1-1} 
         {VarMes1 == 1 ? VarAno2=VarAno1-1 : VarAno2=VarAno1} 

         await api.post("/comissao_mes" , { 
             MES : VarMes2,
             ANO : VarAno2,       
             id_usuario: id_usuario
         })
         .then((response) => { 

             setValor1(response.data.Tot_Com);          
             
         })  
         
        //-------------------------------------------------------------  
                 //Consulta Comissão recebida mes anterior   
                 var VarAno3 = 0;
                 var VarMes3 = 0;
             
                 {VarMes2 == 1 ? VarMes3=12 : VarMes3=VarMes2-1} 
                 {VarMes2 == 1 ? VarAno3=VarAno2-1 : VarAno3=VarAno2} 
        
                 await api.post("/comissao_mes" , { 
                     MES : VarMes3,
                     ANO : VarAno3,       
                     id_usuario: id_usuario
                 })
                 .then((response) => { 
        
                     setValor2(response.data.Tot_Com);          
                     
                 })  
                 
                //-------------------------------------------------------------   
                    //Consulta Comissão recebida mes anterior   
                    var VarAno4 = 0;
                    var VarMes4 = 0;
                
                    {VarMes3 == 1 ? VarMes4=12 : VarMes4=VarMes3-1} 
                    {VarMes3 == 1 ? VarAno4=VarAno3-1 : VarAno4=VarAno3} 
        
                    await api.post("/comissao_mes" , { 
                        MES : VarMes4,
                        ANO : VarAno4,       
                        id_usuario: id_usuario
                    })
                    .then((response) => { 
        
                        setValor3(response.data.Tot_Com);          
                        
                    })  
                    
                //-------------------------------------------------------------   
                    //Consulta Comissão recebida mes anterior   
                    var VarAno5 = 0;
                    var VarMes5 = 0;
                
                    {VarMes4 == 1 ? VarMes5=12 : VarMes5=VarMes4-1} 
                    {VarMes4 == 1 ? VarAno5=VarAno4-1 : VarAno5=VarAno4} 
        
                    await api.post("/comissao_mes" , { 
                        MES : VarMes5,
                        ANO : VarAno5,       
                        id_usuario: id_usuario
                    })
                    .then((response) => { 
        
                        setValor4(response.data.Tot_Com);          
                        
                    })  
                    
                //-------------------------------------------------------------      
                    //Consulta Comissão recebida mes anterior   
                    var VarAno6 = 0;
                    var VarMes6 = 0;
                
                    {VarMes5 == 1 ? VarMes6=12 : VarMes6=VarMes5-1} 
                    {VarMes5 == 1 ? VarAno6=VarAno5-1 : VarAno6=VarAno5} 
        
                    await api.post("/comissao_mes" , { 
                        MES : VarMes6,
                        ANO : VarAno6,       
                        id_usuario: id_usuario
                    })
                    .then((response) => { 
        
                        setValor5(response.data.Tot_Com);          
                        
                    })  
                    
                //-------------------------------------------------------------    
            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                console.log(error);
        }
    }

    

    const dataAtual = new Date();    
    const AnoAtual = dataAtual.getFullYear();    
    const MesAtual = dataAtual.getMonth()+1;    

    var VarAno1 = 0;
    var VarMes1 = 0;

    {MesAtual == 1 ? VarMes1=12 : VarMes1=MesAtual-1}
    {MesAtual == 1 ? VarAno1=AnoAtual-1 : VarAno1=AnoAtual}

    var VarAno2 = 0;
    var VarMes2 = 0;

    {VarMes1 == 1 ? VarMes2=12 : VarMes2=VarMes1-1}
    {VarMes1 == 1 ? VarAno2=VarAno1-1 : VarAno2=VarAno1}

    var VarAno3 = 0;
    var VarMes3 = 0;

    {VarMes2 == 1 ? VarMes3=12 : VarMes3=VarMes2-1}
    {VarMes2 == 1 ? VarAno3=VarAno2-1 : VarAno3=VarAno2}  

    var VarAno4 = 0;
    var VarMes4 = 0;
    
    {VarMes3 == 1 ? VarMes4=12 : VarMes4=VarMes3-1}
    {VarMes3 == 1 ? VarAno4=VarAno3-1 : VarAno4=VarAno3}

    var VarAno5 = 0;
    var VarMes5 = 0;


    {VarMes4 == 1 ? VarMes5=12 : VarMes5=VarMes4-1}
    {VarMes4 == 1 ? VarAno5=VarAno4-1 : VarAno5=VarAno4}

    var VarAno6 = 0;
    var VarMes6 = 0;

    {VarMes5 == 1 ? VarMes6=12 : VarMes6=VarMes5-1}
    {VarMes5 == 1 ? VarAno6=VarAno5-1 : VarAno6=VarAno5}    

    
    const VA1=`${VarMes1}-${VarAno1}`;
    const VA2=`${VarMes2}-${VarAno2}`;
    const VA3=`${VarMes3}-${VarAno3}`;
    const VA4=`${VarMes4}-${VarAno4}`;
    const VA5=`${VarMes5}-${VarAno5}`;
    const VA6=`${VarMes6}-${VarAno6}`;
    
  
    return (    
        
    <div>

        <Navbar_Voltar />
 
        <AnimatedPage1>

        {Logado === false ? <Navigate to='/' /> : null }

        <div id='css_principal2'>

            <div id="css_abertura">                          
                <h1 style={style_1}>{nome}</h1>                
                <h1 style={style_2}>{profissao}</h1>
            </div>

            <div id='css_botoes2'>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA1}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor)}</button>    
                    </div>  
                </div>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA2}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor1)}</button>    
                    </div>  
                </div>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA3}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor2)}</button>    
                    </div>  
                </div>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA4}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor3)}</button>    
                    </div>  
                </div>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA5}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor4)}</button>    
                    </div>  
                </div>

                <div id="css_parte_3A">                    
                    <div id="css_parte_4A">                
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{VA6}</button>
                    <button className="btn btn-outline-primary btn-lg" id="css_botao_Principal" type="button">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency:  'BRL'}).format(valor5)}</button>    
                    </div>  
                </div>
                                            

            </div>

        </div>
        
        </AnimatedPage1>

        <Footer /> 
             
    </div> 

    )
}

export default Historico;