
import {Link} from "react-router-dom";

import "./lista_clientes.css";

import api from "../../services/api.js"
import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
//import Footer from "../footer/footer";

import {useRef} from 'react';

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

//import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";

import { mask, unMask } from 'remask';  //mascaras


function Lista_Clientes() {    

    const {setShow, show2, setShow2, setFocus, nome_cliente_cont, setNome_Cliente_cont, cod_cliente_cont, setCod_cliente_cont} = use(MeuContexto);
 

//trocar proprietades dos inputs
const myinput = useRef(); 

const inputfocus = () => {            
    //myinput.current.value = cod_cliente_cont;   
    //set_Var_Input5(cod_cliente_cont);
    myinput.current.focus();                     
}    



const elemento_cod_cli = useRef();

const acao = () => {
    elemento_cod_cli.current.focus();    
}

useEffect(() =>{       
    acao();   
},[]);


//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

var Var_nome_cliente='';

function Acao_2(ObterDados){
   
    Var_nome_cliente = ObterDados.get("input_nome_cliente")  

    func_Receber_lista_Cliente();     
    
}

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const [lista, setLista] = useState([]);

const func_Receber_lista_Cliente = async () => {
   
    try{ 
              Var_nome_cliente = Var_nome_cliente;              
              
              await api.get("/listar_clientes?nome=" +  Var_nome_cliente)
              .then((response) => { 
                  setLista(response.data);                     
              })    
             

          //Se der ERRO em qualquer das Consultas
        } catch(error){          
                console.log(error);
    }   
        
 } 

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const [cod, setCod] = useState('');
const [nome, setNome] = useState('');
//const [celular, setCelular] = useState('');
const [email, setEmail] = useState('');
const [login, setLogin] = useState('');
const [senha, setSenha] = useState('');

const [var_celular, setVar_Selular] = useState("");



function clickAlterar(cod, nome, celular, email, login, senha) {

    
    setCod(cod);
    setNome(nome);
    setVar_Selular(celular);   
    
    if (email == null) 
        {
            setEmail('');
        }
    else
        {                                                    
            setEmail(email);       
        } 

    if (login == null) 
        {
            setLogin('');
        }
    else
        {                                          
            setLogin(login);       
        } 

    if (senha == null) 
        {
            setSenha('');
        }
    else
        {                                          
            setSenha(senha);       
        } 

        inputfocus();

}

const columns = [

    {
        cell: (row) => <>                       
   
             <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar( row.cod, row.nome )}  id="botao_selecionar">S</button>
             {linkar === 'Voltar_Vendas' ? <Navigate to='/vendas' /> : null}           

        </>,
        width: "65px",   //largura 
        left: true   //Colocar Botão a Direita
      }, 
      {
        //name: 'CÓDIGO',
        selector: row => row.cod,
        width: "85px",   //largura
        sortable: false            //cria ordenação 
        
    }, 
    {
        //name: 'CLIENTE',
        selector: row => row.nome,
        width: "290px",   //largura 
        sortable: false            //cria ordenação
    },        
    {
        //name: 'CELULAR',          
        selector: row => row.celular,
        width: "150px",  
        sortable: false            //cria ordenação 
    },
    {
        //name: 'EMAIL',          
        selector: row => row.email,
        width: "0px",  
        compact: true,
    },
    {
        //name: 'LOGIN',          
        selector: row => row.login,
        width: "0px",  
        compact: true,
    },
    {
        //name: 'SENHA',          
        selector: row => row.senha,
        width: "0px",  
        compact: true, 
    },
    {
        cell: (row) => <>                       
   
             <button className="btn btn-outline-primary" type="button" onClick={() => clickAlterar(row.cod, row.nome, row.celular, row.email, row.login, row.senha)}  id="botao_selecionar">A</button>
             {linkar === 'Voltar_Vendas' ? <Navigate to='/vendas' /> : null}           

        </>, 
        width: "50px",   //largura 
        right: true   //Colocar Botão a Direita
      }
]

// Cria a Paginação
const paginationOptions = {
rowsPerPageText: 'Registros por Página',
rangeSeparatorText: 'de',
selectAllRowsItem: true,
selectAllRowsItemText: 'Todos'
};


 //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const [codigo_cliente, setCodigo_cliente] = useState('');
const [nome_cliente, setNome_cliente] = useState('');

const [linkar, setLinkar] = useState('');

function clickEditar(cod, nome){

    setCodigo_cliente(cod);

    setNome_cliente(nome);    

    setShow2(false);

    setFocus("1"); 
    
    setLinkar('Voltar_Vendas'); 
    
} 

function clickFechar() {

    setCodigo_cliente('');
    setNome_cliente('');

    
    setShow2(false)
    setFocus("1");

}
    

setNome_Cliente_cont(nome_cliente);
//console.log(nome_produto_cont);

setCod_cliente_cont(codigo_cliente);
//console.log(cod_produto_cont);

//---------------------------------------------------------

//const [var_celular, setVar_Selular] = useState("");

const onChangeCelular = e => {
    const originalValue = unMask(e.target.value);
    const maskedValue = mask(originalValue, [
        "(99) 99999-9999"                
    ]);
    setVar_Selular(maskedValue);        
};

//---------------------------------------------------------

//var Var_cod='';
var Var_nome='';
var Var_celular='';
var Var_email='';
var Var_login='';
var Var_senha='';


function Acao_1(ObterDados){

    Var_nome = ObterDados.get("imput_nome")
    Var_celular = ObterDados.get("imput_celular")
    Var_email= ObterDados.get("imput_email")
    Var_login = ObterDados.get("imput_login")
    Var_senha = ObterDados.get("imput_senha")

    //VERIFICA SE OS CAMPOS ESTÃO VAZIOS
    if ((Var_nome == '') || (Var_celular ==  '')) 
        {
        Swal.fire({icon: 'error',  title: 'É Necessário', text: 'Preencher pelo menos o Nome e o Celular!'});
        setShow(false);
        }
    else
        {    
                                                 
            func_Alterar_registro(Var_nome, Var_celular, Var_email, Var_email, Var_login, Var_senha);        
        }         

}

   
    function func_Alterar_registro() {

        setShow(false);

        {/*
        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Confirma', 
                text: 'A Alteração do Cliente?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",
                
              

            }).then(response => {
                if(response.isConfirmed){    */} 

                                   
                        api.put("/alterar_cliente" , { 
                            cod: cod,
                            nome: Var_nome,
                            celular: Var_celular,
                            email : Var_email,                            
                            login : Var_login,
                            senha : Var_senha                        

                        })                           

                        .then((retorno) => {                                    

                               //Swal.fire({icon: 'success',  title: 'Alteração', text: 'Realizada com Sucesso!'})
                                
                                setCodigo_cliente(cod);

                                setNome_cliente(nome);    
                            
                                setShow2(false);
                            
                                setFocus("1"); 
                                
                                setLinkar('Voltar_Vendas');                                
                                                                                                   
                                
                        })
                        .catch(err => console.log(err));                                                
                {/*
                } 
                else 
                if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                } 
                        
                
                 })   */}                

    }



const Func_Ao_Mudar_Input_Nome = (e) => {
    setNome(e.target.value);        
}

const Func_Ao_Mudar_Input_Email = (e) => {
    setEmail(e.target.value);        
}

const Func_Ao_Mudar_Input_Login = (e) => {
    setLogin(e.target.value);        
}

const Func_Ao_Mudar_Input_Senha = (e) => {
    setSenha(e.target.value);        
}


 //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
    
 return <Dock position="left"
 isVisible={show2}
 fluid={false}
 size={1200}     
 >

<div id="tam_maior_12">
    <div id="tam_esquerda_12"> 

        <div className="container-fluid h-100 pt-4 sidebar p-4 ">                         
            
            <div id="ajuste11">
                <div>                       
                    <h2 id="css_texto_inicial">Selecionar Cliente</h2>                                                   
                </div>          
                <div>                             
                    
                    <Link to="/vendas" className="btn btn-outline-primary me-3" id="ajuste12" onClick={clickFechar} >Sair</Link>            
                                                                        
                </div>  
            </div>        
                            

            
            <div>
            
                <form action={Acao_2}> 

                    <h6>Selecione o Cliente</h6>

                    <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name='input_nome_cliente' ref={elemento_cod_cli}/>     
        
                </form> 

            </div>

                <h6></h6>

            <div>
            </div> 

            <div>                        
                <DataTable  columns={columns}                            
                    data={lista}    //dados de um DB
                    pagination = {true}         //Põe a paginação 
                    paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                    no noDataComponent={"Nenhum Registro Encontrado"}
                    fixedHeader={true}  //fixa o cabeçalho se for true
                    selectableRowsHighlight  //escurece a linha quando o maus em cima
                    highlightOnHover         //junto com o de cima                            
                /> 
            </div> 

                                
            
        </div>

    </div>
    <div id="tam_direita_12">
        

        <div className="col-12 mt-3"> 

            <h2  id="css_texto_inicial">Alteração do Cliente</h2>

             <form action={Acao_1}>            

                <small className="text-secondary">Nome</small>
                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_nome" value={nome} onChange={(e) => Func_Ao_Mudar_Input_Nome(e)}  ref={myinput}/> 

                <small className="text-secondary">Celular</small>
                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_celular" onChange={onChangeCelular} value={var_celular} /> 

                <small className="text-secondary">E-mail</small>
                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_email" value={email} onChange={(e) => Func_Ao_Mudar_Input_Email(e)}/> 

                <small className="text-secondary">Login</small>
                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_login" value={login} onChange={(e) => Func_Ao_Mudar_Input_Login(e)}/> 

                <small className="text-secondary">Senha</small>
                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" name="imput_senha" value={senha} onChange={(e) => Func_Ao_Mudar_Input_Senha(e)}/> 

            

                <div className="col-12 mt-3"> 

                    <button className="btn btn-outline-primary me-3" type="submit">Alterar</button>                                
                                    
                </div>
            
            </form> 
        </div>



        <br></br>
        <br></br>
         
         <h4>Código: {cod}</h4>


    </div>
</div>


</Dock>

}

export default Lista_Clientes;


//<button className="btn btn-outline-primary me-3" id="ajuste12" onClick={() => setShow2(false)}>Sair</button>