import {Link} from "react-router-dom";

import "./balanco.css";

import api from "../../services/api.js"
import{useState} from 'react';

import { useEffect } from "react";
//import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

//import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";

import { mask, unMask } from 'remask';  //mascaras


function Balanco() { 

    const [dt_pedido, setDtPedido] = useState("");
    const [dt_pedido_2, setDtPedido_2] = useState("");
    var Data_1 = '';
    var Data_2 = ''; 
    
const clickConsultar = async () => { 
        Data_1 = dt_pedido;
        Data_2 = dt_pedido_2;
        await func_Vendas_Pagamentos();    
        await func_Prolabore();
        await func_Repasse();
        //await func_Saldo_Final();
        await func_Saldo_inicial();
        func_Receber_lista();
        
    } 

    const [Vendas, setVendas] = useState("");
    const [Pagamentos, setPagamentos] = useState("");
    const [lucro, setLucro] = useState("");
    //const [Vendas_1, setVendas_1] = useState("");
    //const [Pagamentos_1, setPagamentos_1] = useState("");

    
    const func_Vendas_Pagamentos = async () => {   
        try{            
                
          await api.post("/traz_vendas_pagamentos" , { 
            data_inicio: Data_1,               
            data_fim: Data_2 
            })

          .then((response) => {               

               setVendas(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.Total_Vendas));
               setPagamentos(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.Total_Pagamentos));
               setLucro(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(response.data.Total_Vendas)-parseFloat(response.data.Total_Pagamentos)));
               var Var_Vendas = response.data.Total_Vendas;
               var Var_Pagamentos = response.data.Total_Pagamentos;

               func_Saldo_Final(Var_Vendas, Var_Pagamentos);
 
              
            })            
         } catch(error){          
            console.log(error);
            }
            
            

            
  } 

  

  const [repasse, setRepasse] = useState("");
 

  const func_Repasse = async () => {   
    try{            
            
      await api.post("/traz_repasse_familiar" , { 
        data_inicio: Data_1,               
        data_fim: Data_2 
        })

      .then((response) => {               

        setRepasse(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.Repasse));

        //setRepasse(response.data.Repasse);
        
      
        })            
     } catch(error){          
        console.log(error);
        }       
} 

const [prolabore, setProlabore] = useState("");


  const func_Prolabore = async () => {   
    try{            
            
      await api.post("/traz_prolabore" , { 
        data_inicio: Data_1,               
        data_fim: Data_2 
        })

      .then((response) => {               

        setProlabore(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.Prolabore));
       
        //setProlabore(response.data.Prolabore)
      
        })            
     } catch(error){          
        console.log(error);
        }       
} 



const [saldo_final, setSaldo_final] = useState("");


  const func_Saldo_Final = async () => {   
    try{      
            
      await api.post("/traz_saldo_balanco" , { 
        data_inicio: Data_1,               
        data_fim: Data_2 
        })

      .then((response) => {               

        setSaldo_final(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.saldo));
        
        })            
     } catch(error){          
        console.log(error);
        }       
} 

const [saldo_inicial, setSaldo_inicial] = useState("");


  const func_Saldo_inicial = async () => {   
    try{      
            
      await api.post("/traz_saldo_ini" , { 
        data_inicio: Data_1,               
        data_fim: Data_2       
        })

      .then((response) => {               

        setSaldo_inicial(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.anterior));
        
   
        })            
     } catch(error){          
        console.log(error);
        }       
}

const [lista, setLista] = useState([]);

const func_Receber_lista = () => {   
    try{ 
                    
                api.post("/listar_balanco" , { 
                data_inicio: Data_1,               
                data_fim: Data_2                
                })

              .then((response) => { 
                  setLista(response.data);                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

      const columns = [
        
        {
          name: 'DATA',
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            height: "29px",
          }, 
          selector: row => row.DT,
          //compact: true,
          width: "150px",   //largura
          sortable: true            //cria ordenação 
          
      }, 
      {
          name: 'VENDAS',
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Vendas),
          width: "140px",   //largura 
          style: {
            backgroundColor: 'rgba(187, 204, 221, 1)',
            height: "29px",
          }, 
          //compact: true,
          right: true,
          sortable: true            //cria ordenação
      },        
      {
          name: 'PAGAM.',          
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.Pagamentos),
          //compact: true,
          width: "140px", 
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            height: "29px",
          },  
          right: true,
          sortable: true            //cria ordenação 
      },
                 
      {
          name: 'TIPO PG', 
          style: {
            backgroundColor: 'rgba(187, 204, 221, 1)',
            height: "29px",
          }, 
          //compact: true,        
          selector: row => row.Tipo,
          width: "200px"         
                
      },
                 
      {
          name: 'DETALHE', 
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            height: "29px",
          }, 
          //compact: true,        
          selector: row => row.detalhe_pagamento,
          width: "150px"         
                
      }
    

]

const estilos = {

  table: {
      style: {
      },
  },

  tableWrapper: {
      style: {
          display: "table",
      },
  },

  header: {
      style: {
          fontSize: "22px",
          minHeight: "56px",
          paddingLeft: "0px",
          paddingRight: "0px",
      },
  },

  subHeader: {
      style: {
          minHeight: "52px",
      },
  },

  head: {
      style: {
          fontSize: "14px",
          fontWeight: 500,
          minWidth: "0px",
          textAlign: "center",
      },
  },

  headRow: {
      style: {
          fontSize: '17px',
          minHeight: "50px",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
      },
      denseStyle: {
          minHeight: "32px",
      },
  },

  headCells: {
      style: {
          paddingLeft: "7px",
          paddingRight: "7px",
          fontWeight: 700,
      },
  },
  
  contextMenu: {
      style: {
          fontSize: "18px",
          fontWeight: 400,
          paddingLeft: "16px",
          paddingRight: "8px",
          transform: 'translate3d(0, -100%, 0)',
          transitionDuration: '125ms',
          transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
          willChange: 'transform',
      },
     
  },
  

  cells: {
      style: {
          paddingLeft: '7px',
          paddingRight: '7px',
          wordBreak: 'break-word',
      },     
  },

  rows: {
      style: {
          fontFamily: 'arial',
          fontSize: '14px',
          fontWeight: 0,
          minHeight: '35px',
          '&:not(:last-of-type)': {
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
          },
      },
      denseStyle: {
          minHeight: "32px",
      },
  },
}

// Cria a Paginação
const paginationOptions = {
  rowsPerPageText: 'Registros por Página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos'
  };

    
return (
    
<div>

       <br></br>

        <div id="tela_55">

                    <br></br>

                    <div id="css_509">
                   
                        <div>
                            <h4>Data Inicial</h4>
                        </div>

                        <div id="css_500">   
                                <div id="css_501">                            
                                    <input type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                </div> 
                                <div>
                                    {dt_pedido}    
                                </div>                      
                        </div>

                    </div>

                    <div id="css_509">
                   
                        <div>
                            <h4>Data Final</h4>
                        </div>

                        <div id="css_500">   
                                <div id="css_501">                            
                                    <input type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                                </div> 
                                <div>
                                    {dt_pedido_2}   
                                </div>                   
                            </div> 

                    </div>

                                

                <div id="css_500">
                       <button className="btn btn-outline-primary" id="largura_btn_50"  onClick={clickConsultar} type="button">Consultar</button>
                </div>

       

                <div id="css_500">
                       <Link to="/principal" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>
                </div>
            
        </div>

        <br></br>
        <br></br>
        
            <div id="Parte_Principal_2_Lados">


                <div id="css_505_lado_esquerdo">

                      <div id="css_505">
                              <h5>Saldo Anterior:</h5> 
                              <h5>{saldo_inicial}</h5>  
                      </div>
                      
                            
                            
                            

                      <div id="css_505">
                              <h5>Vendas:</h5> 
                              <h5>{Vendas}</h5>  
                      </div> 

                      <div id="css_505">
                              <h5>Pagamentos:</h5> 
                              <h5>{Pagamentos}</h5>  
                      </div>                     

                      

                      <div id="css_505">
                              <h5>Lucro:</h5> 
                              <h5>{lucro}</h5>  
                      </div> 

                                        

                      <div id="css_505">
                              <h5>Pro-labore:</h5> 
                              <h5>{prolabore}</h5>  
                      </div> 
                      
                      <div id="css_505">
                              <h5>Repasse:</h5> 
                              <h5>{repasse}</h5>  
                      </div>                      
                      

                      <div id="css_505">
                              <h5>Saldo Final:</h5> 
                              <h5>{saldo_final}</h5>  
                      </div> 

                </div>

                <div id="css_505_lado_direito">
                  
                <div>                        
                      <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                         
                        maxheight= "30px"
                        customStyles={estilos}
                                                  
                      /> 
                </div> 

                </div>
              
            </div> 

     
</div>


)


}

export default Balanco;