import {useState} from 'react';
import api from "../../services/api.js"
import {Link} from "react-router-dom";
import "./deletar.css";

import Swal from 'sweetalert2';


function Deletar(){
             
        const [Var_Input, set_Var_Input] = useState("");
       

        function func_Deletar(){

            Swal.fire({icon: 'question',  //success, error, warning, info, question 
                    title: 'Pergunta', 
                    text: 'Deseja realmente Excluir esse registro?',
                    showDenyButton: true,
                    denyButtonText:"Não",
                    confirmButtonText: "Sim",
                    confirmButtonColor: "blue",

                }).then(response => {
                    if(response.isConfirmed){

                        api.delete("/empresas_deletar/" + Var_Input)            
                        .then((retorno) => {                               
                                    Swal.fire({icon: 'success',  title: 'Exclusão', text: 'Realizada com Sucesso'})              
                
                            })
                            .catch(err => console.log(err));
                           

                    }else if(response.isDenied){
                           // Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                    }               
                }) 
   

        }
    
        return <div>              

            <div>
                <h1>Deletar</h1>  
                <br></br>
                <Link to="/" className="btn-voltar">Voltar</Link>                        
                <br></br>
                <br></br>
                <h3>Digite o Id do Registro </h3> 

                <input type="text" onChange={(e)=>set_Var_Input(e.target.value)} />                        
                <br></br> <br></br>
                <button type="button" className="btn-voltar" onClick={(e) =>func_Deletar(Var_Input)}>Deletar Registro</button>
                <br></br> <br></br> 

                <h5>{Var_Input}</h5>             

            </div>  

    
        </div>

}

export default Deletar;

