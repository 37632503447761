
import {Link} from "react-router-dom";
import "./comissao2.css";
import AnimatedPage1 from "../../AnimatedPage1.jsx";
import api from "../../services/api.js"
import{useContext, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";



function Comissao(){

    const {nome, id_usuario, Logado} = useContext(MeuContexto);
    
    const [show, setShow] = useState(false);
       

    var Var_Proprietario='';
    var Var_Celular='';
    var Var_Email='';
    var Var_Observacao='';
    var Var_Estagio='';
    
    
    //A Função abaico é um Form que entra da Direita pra Esquerda - Utiliza o: Dock
    function EditAmarok_2(){

                const [prop1, setProp1] = useState('');
                const [prop2, setProp2] = useState('');
                const [prop3, setProp3] = useState('');
                const [prop4, setProp4] = useState('');
                const [prop5, setProp5] = useState('');

                const onChangeProprietario = e => {
                    setProp1(e.target.value);                         
                }; 
                {prop1=='' ?  Var_Proprietario=proprietario  : Var_Proprietario=prop1}

                const onChangeCelular = e => {
                    setProp2(e.target.value);                         
                };
                {prop2=='' ?  Var_Celular=celular  : Var_Celular=prop2}                
                
                const onChangeEmail = e => {
                    setProp3(e.target.value);                         
                };  
                {prop3=='' ?  Var_Email=email  : Var_Email=prop3}              
                

                const onChangeObservacao = e => {
                    setProp4(e.target.value);                         
                };  
                {prop4=='' ?  Var_Observacao=observacao2  : Var_Observacao=prop4}              
                

                const onChangeEstagio = e => {
                    setProp5(e.target.value);                         
                }; 
                {prop5=='' ?  Var_Estagio=estagio  : Var_Estagio=prop5}               
               
              
                  
    
            return <Dock position="right"
                    isVisible={show}
                    fluid={false}
                    size={400}
     
                    >
    
                    <div className="container-fluid h-100 pt-4 sidebar p-4 ">
                   

                    <div>                       
                         <h2 id="css_texto_inicial">Editar Proprietário Amarok</h2>                       
                    </div>
    
                    <div id="css_cor_texto">
                      
                        <h4>Responsável: {nome}</h4>
                        <h4>Código: {cod}</h4>
                        <h4>Chassi: {chassi}</h4>
                        <h4>Placa: {placa}</h4>
                        <h4>CPF/CNPJ: {cpf}</h4>
  
                    </div>
    
                    <div>

                        

                        <div className="col-12 mt-3"> 
             

                            <small className="text-secondary">Proprietário</small>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" defaultValue={proprietario} onChange={onChangeProprietario}/>     
                               
                        </div>
                   
                        <div className="col-12 mt-3">                       
                            <small className="text-secondary">Celular</small>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" defaultValue={celular} onChange={onChangeCelular}/>    
                       </div>

                        <div className="col-12 mt-3">                       
                            <small className="text-secondary">E-mail</small>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" defaultValue={email} onChange={onChangeEmail}/>   
                        </div>

                        <div className="col-12 mt-3">                       
                            <small className="text-secondary">Observação</small>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" defaultValue={observacao2} onChange={onChangeObservacao}/>    
                        </div>

                        <div className="col-12 mt-3">                       
                            <small className="text-secondary">Estágio </small>
                            <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" defaultValue={estagio} onChange={onChangeEstagio}/>    
                        </div>

   

                    </div>

                    <div className="col-12 mt-3"> 

                           <button className="btn btn-outline-primary me-3" onClick={() => setShow(false)}>Fechar</button> 
                           
                           <button type="button" className="btn btn-outline-primary me-3" onClick={(e) =>func_Alterar(cod)}>Alterar Registro</button>
                           
                    </div>
    
                       
                    </div>
                      
    
            </Dock>
    }
              

    const style_1 = {fontSize:"20px", color: "primary"};
    const style_2 = {fontSize:"13px", color: "primary"}; 

    
        useEffect(() =>{

            func_Receber();

            if (Logado == false){
                Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
          }

        },[]);

        
        const [lista, setLista] = useState([]);

        const func_Receber = async () => {

            try{             
                
                      //Se for 1
                      console.log("usuario: " + id_usuario);
            
                      //Consulta detalhe clientes indicados
                      await api.get("/listar_proprietarios?USUARIO=" +  id_usuario)
                      .then((response) => { 
                          setLista(response.data);                     
                      })    
                     

                  //Se der ERRO em qualquer das Consultas
                } catch(error){          
                        console.log(error);
            }
                
        }

        console.log(lista);
            
        const [registro, setRegistro] = useState('');
        const [cod, setCod] = useState('');
        const [chassi, setChassi] = useState('');
        const [placa, setPlaca] = useState('');
        const [cpf, setCPF] = useState('');
        const [proprietario, setProprietario] = useState('');
        const [celular, setCelular] = useState('');
        const [email, setEmail] = useState('');
        const [observacao2, setObservacao2] = useState('');
        const [estagio, setEstagio] = useState('');

        function clickEditar(e){

            setShow(true)

            setRegistro(e);
            
            //Códico Proprietario
            var PosInicial=e.indexOf("cod ")+4
            var PosFinal=e.indexOf(" chassi")     
            setCod(e.substring(PosInicial, PosFinal)); 
            
            //Chassi
            var PosInicial=e.indexOf("chassi ")+7
            var PosFinal=e.indexOf(" placa")     
            setChassi(e.substring(PosInicial, PosFinal));  
            
            //Placa
            var PosInicial=e.indexOf("placa ")+6
            var PosFinal=e.indexOf(" CPF")     
            setPlaca(e.substring(PosInicial, PosFinal)); 

            //CPF ou CNPJ
            var PosInicial=e.indexOf("CPF ")+4
            var PosFinal=e.indexOf(" CONTATO")     
            setCPF(e.substring(PosInicial, PosFinal));

            //Proprietário
            var PosInicial=e.indexOf("PROPRIETARIO ")+13
            var PosFinal=e.indexOf(" CELULAR")     
            setProprietario(e.substring(PosInicial, PosFinal));

            //Celular
            var PosInicial=e.indexOf("CELULAR ")+8
            var PosFinal=e.indexOf(" EMAIL")     
            setCelular(e.substring(PosInicial, PosFinal));

            //Email
            var PosInicial=e.indexOf(" EMAIL_2 ")+9
            var PosFinal=e.indexOf(" OBSERV")     
            setEmail(e.substring(PosInicial, PosFinal));

            //Observação
            var PosInicial=e.indexOf("OBSERV ")+7
            var PosFinal=e.indexOf(" ESTAGIO")     
            setObservacao2(e.substring(PosInicial, PosFinal));

            //Estagio
            var PosInicial=e.indexOf("ESTAGIO ")+8
            var PosFinal=e.indexOf(" FIM")     
            setEstagio(e.substring(PosInicial, PosFinal));
    
        } 
        

    //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

    const columns = [

        {
            cell: (row) => <>  
                           
       
                 <button className="btn btn-outline-primary" onClick={() => clickEditar('cod ' + row.ID_PROPRIETARIO + ' chassi ' + row.CHASSI + ' placa ' + row.PLACA +
                 ' CPF ' + row.CPF_CNPJ_LIMPO + ' CONTATO ' + row.MEIO_DE_CONTATO + ' PROPRIETARIO ' + row.PROPRIETARIO +               
                 ' CELULAR ' + row.CEL_ZAP + ' EMAIL_2 ' + row.EMAIL + ' OBSERV ' + row.OBSERVACAO + ' ESTAGIO ' + row.ESTAGIO +  ' FIM')} type="button"  id="css_botao_sair">Editar</button>

            </>,
            width: "100px",   //largura 
            left: true   //Colocar Botão a Direita
          }, 
          {
            name: 'USUÁRIO',
            selector: row => row.USUARIO,
            width: "120px",   //largura
            sortable: true            //cria ordenação 
        }, 
        {
            name: 'ESTÁGIO',
            selector: row => row.ESTAGIO,
            width: "120px",   //largura 
            sortable: true            //cria ordenação
        },        
        {
            name: 'CÓD.',          
            selector: row => row.ID_PROPRIETARIO,
            width: "100px",  
            sortable: true            //cria ordenação 
        },
        {
            name: 'CHASSI',
            selector: row => row.CHASSI,
            width: "200px",  
            sortable: true            //cria ordenação
        },
        {
            name: 'PLACA',
            selector: row => row.PLACA,
            width: "120px",   
            sortable: true            //cria ordenação
        },
        {
            name: 'CPF OU CNPJ',
            width: "190px",   
            selector: row => row.CPF_CNPJ_LIMPO,
            sortable: true            //cria ordenação
        },      
        {
            name: 'PROPRIETÁRIO',
            width: "400px",   
            selector: row => row.PROPRIETARIO,
            sortable: true            //cria ordenação
        },
        {
            name: 'TIPO',
            width: "120px",
            selector: row => row.TIPO_PROPRIETARIO,
            sortable: true            //cria ordenação
        },
        {
            name: 'PROSPECTOR',
            width: "160px",
            selector: row => row.PROSPECTOR,
            sortable: true            //cria ordenação
        },
        {
            name: 'CEL-WHATSAAP',
            width: "300px",
            selector: row => row.CEL_ZAP,
            sortable: true            //cria ordenação
        },
        {
            name: 'E-MAIL',
            width: "300px",
            selector: row => row.EMAIL,
            sortable: true            //cria ordenação
        },
        {
            name: 'UF',
            width: "90px",
            selector: row => row.UF_ATUAL,
            sortable: true            //cria ordenação
        }, 
        {
            name: 'CIDADE',
            width: "300px",
            selector: row => row.CIDADE_ATUAL,
            sortable: true            //cria ordenação
        }, 
        {
            name: 'RENAVAN',
            width: "150px",
            selector: row => row.RENAVAN,
            sortable: true            //cria ordenação
        },
        {
            name: 'OBSERVAÇÃO',
            width: "800px",
            selector: row => row.OBSERVACAO,
            sortable: true            //cria ordenação
        }


]


   const paginationOptions = {
    rowsPerPageText: 'Registros por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo



const func_Alterar = async () => {

    try{             
    
              
              setShow(false); 
              await api.put("/amarok_alterar/" + cod , {               
                  PROPRIETARIO: Var_Proprietario,
                  CEL_ZAP: Var_Celular,
                  EMAIL: Var_Email,
                  OBSERVACAO: Var_Observacao,
                  ESTAGIO: Var_Estagio
                 

              })
              .then((retorno) => {
                  Swal.fire({icon: 'success',  title: 'Sucesso', text: 'Alteração Realizada'})        
                                 
              })

              await func_Receber();             

          
        } catch(error){          
                console.log(error);
    }
        
}


    //func_Receber();  
    
    
    return (  
   

        <div>

                <EditAmarok_2 />
                <AnimatedPage1>

            <div>
                
                {Logado === false ? <Navigate to='/' /> : null } 

                <div>
                 
                   <h5>Espaço para Filtrar Proprietário e CPF</h5>                    
                   
                </div>         

                <DataTable  columns={columns}                            
                            data={lista}    //dados de um DB

                            pagination = {true}         //Põe a paginação 
                            paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                            
                            no noDataComponent={"Nenhum Registro Encontrado"}
                            fixedHeader={true}  //fixa o cabeçalho se for true
                            selectableRowsHighlight  //escurece a linha quando o maus em cima
                            highlightOnHover         //junto com o de cima
                            
                            
                />           

            </div>

               </AnimatedPage1>
        </div>   
        
    )
}

export default Comissao;

//     <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder='Nome' defaultValue={nome} onChange={(e)=>set_Var_Input1(e.target.value)}/>                            
                

//      <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" placeholder={proprietario} onChange={(e)=>setProprietario(e.target.value)}/>    
                      