
import {useState} from 'react';

import api from "../../services/api.js"

import Swal from 'sweetalert2';

import DataTable from 'react-data-table-component';

import{use} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import {Navigate} from "react-router-dom";

import "./amarok_consulta1.css";



function Consulta_1(){

    const {nome, id_usuario, Logado} = use(MeuContexto);

    const [lista, setLista] = useState([]);
    const [lista_2, setLista_2] = useState([]);

    const [mensagem, setMensagem] = useState([]);
    const [mensagem_2, setMensagem_2] = useState([]);
   

    useEffect(() =>{

        func_Receber();

        if (Logado == false){
            Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
      }

    },[]);

    var Var_mensagem='2';   
    

        const func_Receber = async () => {

            try{               

                      await api.get("/consulta_atuais")
                          .then((response) => { 
                           setLista(response.data);                     
                      })   

                       //Se der ERRO em qualquer das Consultas
                        } catch(error){          
                        console.log(error);
            }

            try{               

                await api.get("/consulta_antigos")
                    .then((response) => { 
                    setLista_2(response.data);                     
                })   
                        //Se der ERRO em qualquer das Consultas
                        } catch(error){          
                        console.log(error);
            }

            //Total Atuais Proprietários
            try{
                await api.post("/total_atuais")     
                .then((response) => {                 
                    setMensagem(response.data.MENSAGEM_1);             
                })               
                    } catch(error){          
                        console.log(error);
            }

            //total Antigos Proprietarios
            try{
                await api.post("/total_antigos")     
                .then((response) => {                 
                    setMensagem_2(response.data.MENSAGEM_1);             
                })               
                    } catch(error){          
                        console.log(error);
            }


        }

        


    const columns = [
  
      
        {
            name: '',
            selector: row => row.CODIGO,
            width: "50px",   //largura 
            sortable: false            //cria ordenação 
               
        },        
        {
            name: 'ESTAGIO',          
            selector: row => row.ESTAGIO,
            width: "200px",  
            sortable: false           //cria ordenação 

            //style: {
                //backgroundColor: 'rgba(187, 204, 221, 1)',
                //color: '#202124',
                //fontSize: '24px',
                //fontWeight: 500,
            //}

            //background-color: rgb(90, 241, 76);

            //hide: 'md'  //cria ordenação   sd   sl  md
            // grow: 1     
    
        },
        {
            name: 'TOT',
            selector: row => row.QUANTOS,
            width: "80px",  
            sortable: false,            //cria ordenação
            right: true
            //format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.QUANTOS)
        }
  ]
       
    
        return <div> 



            {Logado === false ? <Navigate to='/' /> : null } 
 

                    <div id="cabecalho">
                    <h4>Clientes Atuais - Total: {mensagem}</h4>
                    </div>
                  
                                    
                                
                        <div  id="planilha">
                            <div>
                                    <DataTable  columns={columns}                            
                                    data={lista}    //dados de um DB                      
                                    
                                    no noDataComponent={"Nenhum Registro Encontrado"}
                                    fixedHeader={false}  //fixa o cabeçalho se for true
                                    selectableRowsHighlight  //escurece a linha quando o maus em cima
                                    highlightOnHover         //junto com o de cima 
                                    



                                    /> 
                            </div>
                        </div> 

                                       

                    <div id="cabecalho">
                    <h4>Clientes Iniciais - Total: {mensagem_2}</h4>
                    </div>
                                    
                                
                        <div  id="planilha">
                            <div>
                                    <DataTable  columns={columns}                            
                                    data={lista_2}    //dados de um DB                      
                                    
                                    no noDataComponent={"Nenhum Registro Encontrado"}
                                    fixedHeader={true}  //fixa o cabeçalho se for true
                                    selectableRowsHighlight  //escurece a linha quando o maus em cima
                                    highlightOnHover         //junto com o de cima 
                                    striped    //Muda cor linhas impares                        
                                    /> 
                            </div>
                        </div> 


        </div>

}

export default Consulta_1;