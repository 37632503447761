import AnimatedPage1 from "../../AnimatedPage1.jsx";
import {Link, Navigate}  from 'react-router-dom';
import api from "../../services/api.js"
import Swal from 'sweetalert2'; //Alertas
import { useState } from "react";

import "./cadastro.css";


function Cadastro(){

        
        // Gerar String Aleatória
        function geraStringAleatoria(tamanho) {
            var stringAleatoria = '';
            var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
            for (var i = 0; i < tamanho; i++) {
                stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }
            return stringAleatoria;    
        }

        var ChaveUsuario=geraStringAleatoria(4);      


        const [Var_Input1, set_Var_Input1] = useState('');
        const [Var_Input2, set_Var_Input2] = useState('');
        const [Var_Input3, set_Var_Input3] = useState('');
        const [Var_Input4, set_Var_Input4] = useState('');

        
        const func_Cadastrar = async () => {
            
            if ((Var_Input1 == '') || (Var_Input2 ==  '') || (Var_Input3 ==  '') || (Var_Input4 ==  '')) 
                Swal.fire({icon: 'error',  title: 'É Necessário', text: 'Preencher todos os Campos!'})
                else
                {
                    if ((Var_Input1 ==  Var_Input3) && (Var_Input2 ==  Var_Input4))
                    {                        
                        func_Login();                                                 
                    } 
                        else
                    {
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'E-mail e/ou Senha diferentes! Verifique a Incompatibilidade e Repita a Operação'})
                    }
                } 
        }

        const [Sucesso, setSucesso] = useState("N");

        const func_Login = async () => {

            try{
            
                  await api.post("/Login_de_Usuarios" , {
                    email : Var_Input1,
                    senha : Var_Input2
                  })
                  .then((response) => { 
                        Swal.fire({icon: 'error',  title: 'Erro', text: 'E-mail e Senha Já Existem!'}) 
                  })                   
            
                  } catch(error){                      
                       func_Incluir();                              
                    }
                  }


        function func_Incluir(){

                Swal.fire({icon: 'question',  //success, error, warning, info, question  
                        title: 'Confirma', 
                        text: 'O Cadastramento do Usuario?',
                        showDenyButton: true,
                        denyButtonText:"Não",
                        confirmButtonText: "Sim",
                        confirmButtonColor: "blue",
                        
        
                    }).then(response => {
                        if(response.isConfirmed){                              
                            
                                api.post("/usuario_cadastrar" , { 

                                    email : Var_Input1,
                                    senha : Var_Input2,                                
                                    chave : ChaveUsuario                                                    

                                })                           

                                .then((retorno) => {                                    
        
                                        Swal.fire({icon: 'success',  title: 'Cadastramento', text: 'Realizado com Sucesso! Você Está Apto a Acessar o INDICATOR'})              
                                        
                                        setSucesso('S');                                                                    
                                        
                                })
                                .catch(err => console.log(err));                                                
        
                        } 
                        else 
                        if(response.isDenied){
                                Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                        }               
                    })                 
    
        }

return(
    <div id='css_principal_cad'>

                <AnimatedPage1>
        
                    <div id='css_2_cad'> 

                        <div id="id_perfil_5">                            
                            <p className='Nome_Campos_5'>Email</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao_5' type="text" onChange={(e)=>set_Var_Input1(e.target.value)}/>                            
                        </div> 

                        <div id="id_perfil_5">                            
                            <p className='Nome_Campos_5'>Senha</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao_5' type="text" onChange={(e)=>set_Var_Input2(e.target.value)}/>                            
                        </div>    

                        <div id="id_perfil_5">                            
                            <p className='Nome_Campos_5'>Repita o E-mail</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao_5' type="text" onChange={(e)=>set_Var_Input3(e.target.value)}/>                            
                        </div>   

                        <div id="id_perfil_5">                            
                            <p className='Nome_Campos_5'>Repita a Senha</p>
                            <input className="btn btn-outline-primary text-start" id='largura_botao_5' type="text" onChange={(e)=>set_Var_Input4(e.target.value)}/>                            
                        </div>  

                        <div id="id_perfil_5"></div> 
                        <div id="id_perfil_5"></div> 
                        <div id="id_perfil_5"></div>
                         
                   

                        <div id="id_perfil_5"> 
                                  <button className="btn btn-outline-primary me-3" id='largura_botao_6' onClick={(e) => func_Cadastrar()}>Cadastrar Sua Conta</button>
                                   {Sucesso == 'S' ? <Navigate to='/' /> : null}                              
                       </div> 

                       <div id="id_perfil_5"></div>   

                        <div id="id_perfil_5"> 
                              <Link className="btn btn-outline-primary me-3" id='largura_botao_6'  aria-current="page" to="/">Fechar</Link> 
                        </div>  

                    </div>    
                 
                </AnimatedPage1>
    </div>           
    )
}

export default Cadastro;





/*

import { useEffect, useState } from "react";

import "./cadastro.css";

import{useContext} from 'react';
import MeuContexto from "../../context/context";

import api from "../../services/api.js"
import Swal from 'sweetalert2'; //Alertas


import {Link} from "react-router-dom";

function Cadastro(){

    //const {ShowDock3, setShowDock3} = useContext(MeuContexto);

    const [show, setShow] = useState(false);   
   
    
    useEffect(() => {
                       
    }, []); 
   

        // Gerar String Aleatória
    function geraStringAleatoria(tamanho) {
        var stringAleatoria = '';
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
        for (var i = 0; i < tamanho; i++) {
            stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return stringAleatoria;

    }

    //STRING ALEATÓRIA
    console.log(geraStringAleatoria(4));
    
    //useEffect(() =>{
    //    func_Profissional();
    //},[]);

    const [Var_Input1, set_Var_Input1] = useState('');
    const [Var_Input2, set_Var_Input2] = useState('');
    const [Var_Input3, set_Var_Input3] = useState('');
    const [Var_Input4, set_Var_Input4] = useState('');
       

    const func_Cadastrar = async () => {

    //try{ 
        
    if ((Var_Input1 ==  Var_Input3) && (Var_Input2 ==  Var_Input4))
       {             
             Swal.fire({icon: 'success',  title: 'Conferido', text: 'Pode Continuar'})
             //setShowDock3(true)
             //func_Incluir();
       } 
           else
       {
              Swal.fire({icon: 'error',  title: 'Não Bate', text: 'Repita Porra!!!'})
       }
    }

          
            //Consulta Lista Profissionais
            await api.get("/profissao")
            .then((response) => { 
                setListaProfissional(response.data); 
                
                console.log(response.data)

            })           

            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                console.log(error);
           
            }
           
   

    function func_Incluir(){

        Swal.fire({icon: 'question',  //success, error, warning, info, question  
                title: 'Pergunta', 
                text: 'Realizar o Cadastramento do Usuario?',
                showDenyButton: true,
                denyButtonText:"Não",
                confirmButtonText: "Sim",
                confirmButtonColor: "blue",

            }).then(response => {
                if(response.isConfirmed){

                    /*
                        setRemoveLoading(false);

                        

                        api.post("/empresas_incluir" , {               
                            localidade: Var_Input2,
                            uf: Var_Input3
                        })
                        .then((retorno) => {  
                                
                                setRemoveLoading(true); 

                                Swal.fire({icon: 'success',  title: 'Inclusão', text: 'Realizada com Sucesso'})              
            
                        })
                        .catch(err => console.log(err));
                       
                       

                }else if(response.isDenied){
                        Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                }               
            }) 

    }



    return            

        <div className="container-fluid h-100 pt-4 sidebar p-4 ">

            <div className="d-inline">
                <h4>Cadastro de Usuario</h4>
            </div>

            
                <div>
                    <div className="css_tabela_3">     
                        
                        <div>                            
                            <p className='E-Mail'>E-mail</p>
                            <input type="text" placeholder='E-mail' onChange={(e)=>set_Var_Input1(e.target.value)}/>                            
                        </div> 

                        <div>                            
                            <p className='E-Mail'>Senha</p>
                            <input type="text" placeholder='senha'  onChange={(e)=>set_Var_Input2(e.target.value)} />                            
                        </div>

                        <div>                            
                            <p className='E-Mail'>Repita o E-mail</p>
                            <input type="text" placeholder='Confirmar E-mail'  onChange={(e)=>set_Var_Input3(e.target.value)}/>                            
                        </div>

                        <div>                            
                            <p className='E-Mail'>Confirme a Senha</p>
                            <input type="text" placeholder='Confirmar Senha'  onChange={(e)=>set_Var_Input4(e.target.value)}/>                                                         
                        </div>
                        
                        <div className="row align-items-end footer">
                        <div className="text-center">

                            <Link to="/" className="btn btn-outline-primary me-3">Cadastrar Uma Conta</Link>

                            <button className="btn btn-outline-primary me-3" onClick={(e) => func_Cadastrar()}>Cadastrar</button>  
                            <button className="btn btn-outline-primary me-3" >Fechar</button>  
                            
                        </div> 
                        </div>

                            
                    </div>
                
                    
                </div>

     </div>                    

            
}        

export default Cadastro;




*/
