import api from "../../services/api.js"

import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./pagamento.css";

import{useState} from 'react';

import DataTable from 'react-data-table-component';


function Pagamentos_Consulta(){

    const [dt_pedido, setDtPedido] = useState("");

    const [dt_pedido_2, setDtPedido_2] = useState("");

    const [dt_pedido_3, setDtPedido_3] = useState("");

    const [dt_pedido_4, setDtPedido_4] = useState("");

    const [lista, setLista] = useState([]);

    const [lista_2, setLista_2] = useState([]);

    const dataAtual = new Date();

    var Data_1 = ''; 

    var Data_2 = '';

    var Data_3 = ''; 

    var Data_4 = '';

     
    
const func_Receber_lista = async () => {   
    try{ 
                    
              await api.post("/listar_pagamentos" , { 
                data_inicio: Data_1,               
                data_fim: Data_2 
                })

              .then((response) => { 
                  setLista(response.data); 
                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

const func_Receber_lista_2 = async () => {   
        try{ 
                        
                  await api.post("/listar_pagamentos_2" , { 
                    data_inicio: Data_3,               
                    data_fim: Data_4 
                    })
    
                  .then((response) => { 
                      setLista_2(response.data); 
                                 
    
                  })            
                 } catch(error){          
                    console.log(error);
                    }       
          } 


const clickConsultar = async () => { 

    Data_1 = dt_pedido;
    Data_2 = dt_pedido_2;
    func_Receber_lista();

}

const clickConsultar_2 = async () => { 

    Data_3 = dt_pedido_3;
    Data_4 = dt_pedido_4;
    func_Receber_lista_2();

}

   

      const columns = [
  
        {
          name: 'PAGAMENTO',
          selector: row => row.desc_pagamento,
          width: "300px",   //largura
          sortable: false            //cria ordenação 
          
      }, 
      {
          name: 'VALOR',
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor),
          width: "120px",   //largura 
          right: true,
          sortable: false            //cria ordenação
      },        
      {
          name: 'DATA',          
          selector: row => row.dia,
          width: "150px",  
          right: true,
          sortable: false            //cria ordenação 
      }
  ]

  const columns_2 = [
   
    {
      name: 'PAGAMENTO',
      selector: row => row.desc_pagamento,
      width: "350px",   //largura
      sortable: false            //cria ordenação 
      
  }, 
  {
      name: 'VALOR',
      selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor),
      width: "130px",   //largura 
      right: true,
      sortable: false            //cria ordenação
  }
]

// Cria a Paginação
const paginationOptions = {
    rowsPerPageText: 'Registros por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
    };
  

return(
    <div id="tela_inicial_T2">
        <div id="tela_da_esquerda_T2">
        
            <h3>Pagamentos Detalhado</h3>
            
            <div id="tela_55_V9">
                <div id="css_500">   
                        <div id="css_501">
                             <input type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido}    
                        </div>                   
                </div>

                <div id="css_500">   
                        <div id="css_501">
                            <input type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido_2}   
                        </div>                   
                </div>

                           

                <div>
                       <button className="btn btn-outline-primary" id="largura_botoes"  onClick={clickConsultar} type="button">Consultar</button>
                </div>
            
            </div>
                
                    
            <div id="css_grid_V3">                        
                    <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
            </div>         
                 

        </div>

        <div id="tela_da_direita_T2">
            
           
        <div id="css_62_F3">
                
                <div>
                    
                    <h3>Pagamentos por Tipo</h3>
                                            
                </div>

                <div>
                     <Link to="/sub_menu" className="btn btn-outline-primary" id="largura_btn_50_F3" >Sair</Link>
                     
                </div>                        
                    
        </div>
            
            <div id="tela_55_V9B">

                <div id="css_500">   
                        <div id="css_501">
                             <input type="date" onChange={(e) => setDtPedido_3(e.target.value)} value={dt_pedido_3} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido_3}    
                        </div>                   
                </div>

                <div id="css_500">   
                        <div id="css_501">
                            <input type="date" onChange={(e) => setDtPedido_4(e.target.value)} value={dt_pedido_4} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido_4}   
                        </div>                   
                </div>

                           

                <div>
                       <button className="btn btn-outline-primary" id="largura_botoes"  onClick={clickConsultar_2} type="button">Consultar</button>
                </div>
            
            </div>

                
                    
            <div id="css_grid_V3">                        
                    <DataTable  columns={columns_2}                            
                        data={lista_2}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
            </div> 

            

        </div>

</div>
)

}

export default Pagamentos_Consulta;




