import {useState} from 'react';

import{use} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useActionState } from "react";

import { useFormStatus} from 'react-dom';

import { useEffect } from "react";

import {useRef} from 'react';

import {Link} from "react-router-dom";

import { Form } from './components/Form'
import { UseFetchExemple } from './components/useExemplo.jsx'
import { Actions_Exemplo } from './Actions.jsx';

function Estudo() { 
    
//converter para money    
const currencyMask = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1. $2");
    value = value.replace(/(?=(\d{3})+ (\D))\B/g, ",");
    e.target.value = value;
    alert(e);
}

    const {nome, setNome, id_usuario} = use(MeuContexto);

    //----------ACAO 1------------------------------------------

    const [recebido, setRecebido] = useState([]);    

    function Acao_1(ObterDados){
        const Var_1 = ObterDados.get("caixa_de_texto_1")        
        setRecebido(Var_1)         
        acao();     // essa acao executa o Ref      
    }

    //-----------AÇÃO 2------------------------------------------

        const Funcao_a_Executar = (prevState, Aqui) => {
            const Var_2 = Aqui.get("input_2")            
                return {
                    valor_1: Var_2 + " É ESSE",
                    valor_2: Var_2 + " LUIZA",
                    valor_3: Var_2*2
                }               
        }
        const [Resultado, Acao_2] = useActionState(Funcao_a_Executar, { valor_1: "VAI VIR", valor_2: "MARINA"})

    //prevState - é o valor anterior se se precisar
    //------------------------------------------------------------

    const [recebido_3, setRecebido_3] = useState('');

    async function Acao_3(ObterDados){

        await new Promise(resolve => setTimeout(resolve, 2500))

        const Var_3 = ObterDados.get("caixa_de_texto_3")
        
        setRecebido_3(Var_3) 
               
    }

    //---------------------------------------------------------------
    //Uso de Variaveis e Variaveis de Contexto
        const [valor_deste_Campo, setValor_deste_Campo] = useState('Valor Inicial');

        useEffect(() =>{        
                setNome('La Vida é uma Merda');   
                //setValor_deste_Campo(id_usuario);   //leva valor da Variavel Contexto para o Value do INPUT                                 
            },[]);  

        const Frase = nome;       
        let Frase_2 = 'Fu GG';

        const func_Mudar_Nome = () => {            
            setNome('Viva la Vida');      //setNome e nome são var de contexto           
        }

    //------O CÓDIGO ABAIXO SERVE PARA MANTER O VALOR DIGITADO NO CAMPO QUANDO SE APERTA A TECLA ENTER----------

   
    const onChangeCampo = e => {
        setValor_deste_Campo(e.target.value);                        
    };
    
    const [valor_deste_Campo2, setValor_deste_Campo2] = useState('Valor Inicial');

    const onChangeCampo2 = e => {
        setValor_deste_Campo2(e.target.value);                        
    };
    

    //---------------------------------------------------------------------
    const ref_inpit_2 = useRef();

    const acao = () => {
        if (ref_inpit_2.current) {
            ref_inpit_2.current.focus();
            ref_inpit_2.current.style.backgroundColor = 'orange';
            //elemento.current.value = 'Texto Recebido de useRef'; 
        }
    }
    //-------------------------------------------------------------------------

    const myinput_20 = useRef();

    const inputfocus_20 = () => {
        alert("Tou Aqui")
        myinput_20.current.focus();            
    }

//-------------------------------------------------------------------------

const Funcao_ActionState = (prevState, Aqui) => {

    const Valor = Aqui.get("Numero_Digitado")

        //const Valor_2 = '';

        {/*
        try{
        
        api.post("/traz_comissionado" , {
             cod_empregado: Valor
        })
        .then((response) => { 
            Valor_2=response.data.nome_empregado; 
            return {
                Resultado: Valor_2
            }               
        })     
        } catch(error){                                
                      Swal.fire({icon: 'error',  title: 'Erro', text: 'Código do Comissionado Inválido!'}) ;                        
                   }
        }
        */}      

    
       return {
           Resultado: Valor*3
        }  

}

const [Traz, Acao] = useActionState(Funcao_ActionState, { Resultado: ""})

//-------------------------------------------------------------------------------------

const [show, setShow] = useState(false)
const [show2, setShow2] = useState(false)
const [show3, setShow3] = useState(false)

    return (
        <>
            <button type="submit" onClick={func_Mudar_Nome}>Mudar Nome</button>
            {nome}
            <br></br>
            {Frase}
            <br></br>
            {Frase_2}
            

            <form action={Acao_1}>
                <br/>
                <label>TAREFA 1</label>
                <br/>                
                <input type="text" autoFocus name="caixa_de_texto_1"
                 onChange={onChangeCampo} value={valor_deste_Campo} /> 

                <br/>                
                <button type="submit">Executar</button>
                <br/>                
                {recebido}                
                <br/>
            </form>  

            

            <form action={Acao_2}>
                <label>TAREFA 2</label>
                <br/>                
                
                <input type="text" name="input_2"
                 onChange={onChangeCampo2}  value={valor_deste_Campo2}  ref={ref_inpit_2} />


                <br/>                
                <button type="submit">Executar</button>
                <br/>  

                <h2>{Resultado.valor_1}</h2>                
                <h2>{Resultado.valor_2}</h2>   
                <h2>{Resultado.valor_3}</h2>

            </form>    

            <form action={Acao_3}>
                <label>TAREFA 3</label>
                  <br/>                
                <input type="text" name="caixa_de_texto_3" onChange={onChangeCampo2}  value={valor_deste_Campo2} />
                  <br/>                
                <BOTAO/>
                  <br/>                
                <h1>{recebido_3}</h1>
                  <br/>                              
            </form>

            <div>                                                                  
                <Link to="/FuncHome" className="btn btn-outline-primary" id="largura_botoes" >Pagina Home</Link>                                                              
            </div>

            <div>
                 <br></br>

                 <h3>Resultado sem UseState</h3>

                 <form action={Acao}>
                      <input type="text" name="Numero_Digitado"/>
                      <h2>{Traz.Resultado}</h2> 
                </form>

            </div>

            <br></br>

                 <button onClick={() => setShow(!show)}>Chama Form</button>
                     {show && <Form />} 

            <br></br> 
            
                 <button onClick={() => setShow2(!show2)}>Chama Pokemon</button>
                     {show2 && <UseFetchExemple />}

                     <br></br> 
            
            <button onClick={() => setShow3(!show3)}>Chama Actions Exemplo</button>
                     {show3 && <Actions_Exemplo />}                
            
            
        </>
    )


}


function BOTAO(){

        const { pending } = useFormStatus();
    
        return(
            <div>
                <button type='submit' disabled={pending}>
                    {pending ? "Enviando..." : "Cadastrar"}
                </button>

                <br></br>

                {pending && <apan>Enviando seu Formulario...</apan>}

            </div>

            )   

}

export default Estudo

