
import { useState } from "react";
import Logo from "../../assets/abelha7.png";
import {Navigate} from "react-router-dom";
import "./navbar.css";





function Navbar(){

    const [linkar, setLinkar] = useState('');

    function func_Linka_Comissao(){
        setLinkar('Pagina_Encerrar');
        } 


    return <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-black ps-4 pe-1">
    <div className="container-fluid">
        <a className="navbar-brand" href="/">

            <img className="mb-1" id="abelha" src="imagens/abelha.png" alt=""/>

            
        </a>

        <div className="nav-item me-3">                  

                    <button className="btn btn-outline-primary" onClick={func_Linka_Comissao} type="button" id="css_botao_sair">Saída</button>
                     {linkar === 'Pagina_Encerrar' ? <Navigate to='/encerramento' /> : null} 
                    
        </div>      
      

    </div>
</nav>
}

export default Navbar;


// <Link className="btn btn-outline-primary" aria-current="page" to="/encerramento">Saída</Link>