import api from "../../services/api.js"

import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./historico.css";

import{useState} from 'react';

import DataTable from 'react-data-table-component';

import Swal from 'sweetalert2';    //Alertas

import { Dock } from "react-dock";

import {useRef} from 'react';

import { useEffect } from "react";




function Historico_Clientes(){

    const [dt_pedido, setDtPedido] = useState("");

    const [dt_pedido_2, setDtPedido_2] = useState("");

    const [total_venda, setTotal_venda] = useState("0,00");
    const [total_comissao, setTotal_comissao] = useState("0,00");

    const [lista, setLista] = useState([]);

    const [lista_2, setLista_2] = useState([]);

    const [nome_profissional, setNome_profissional] = useState("")

    const [show, setShow] = useState(false);

    useEffect(() =>{

        myinput.current.focus();

    },[]);

    //Só para focar nos inputs
    const myinput = useRef();

    

      


    const columns = [

        {
            cell: (row) => <>                       
       
                 <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar( row.cod, row.nome )}  id="botao_selecionar">X</button>
                  
            </>,
            width: "70px",   //largura            
            left: true   //Colocar Botão a Direita
          }, 
          {
            //name: 'CÓDIGO',
            selector: row => row.cod,
            width: "65px",   //largura
            compact: true,
            sortable: false            //cria ordenação 
            
        }, 
        {
            //name: 'CLIENTE',
            selector: row => row.nome,
            width: "300px",   //largura 
            compact: true,
            sortable: false            //cria ordenação
        }

    ]

 

    const [codigo_cliente, setCodigo_cliente] = useState("")
    const [nome_cliente, setNome_cliente] = useState("")

    function clickEditar(cod, nome){

        setCodigo_cliente(cod);    
        setNome_cliente(nome);
        
        
        try{ 
                        
            api.post("/listar_historico" , {                             
                nome_que_quiser: cod
              })

            .then((response) => {                 
                 setLista_2(response.data);
              })            
              } catch(error){          
              console.log(error);
              }       
        
    } 

    
// Cria a Paginação
const paginationOptions = {
    rowsPerPageText: 'Registros por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
    };
    



const columns_2 = [
 
    {
      name: 'DATA',
      selector: row => row.DT,
      width: "120px",   //largura
      //compact: true,      
      right: true,
      sortable: true            //cria ordenação 
      
  }, 
  {
      name: 'SERVIÇO',
      selector: row => row.produto,
      //maxwidth: "150px",   //largura 
      width: "400px",

      compact: true,   
      sortable: true            //cria ordenação
  },      
  {
      name: 'PAGO',          
      selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor_pago),
      //width: "80px", 
      //compact: true,      
      right: true
  },       
  {
      name: 'PROFISSIONAL',          
      selector: row => row.profi,
      width: "170px",  
      compact: true,
      //right: true,
      sortable: true            //cria ordenação 
  }

]

var Var_nome_cliente='';

function Acao_01(ObterDados){   
    Var_nome_cliente = ObterDados.get("input_cliente")
    func_Receber_lista_Cliente();     
}

const func_Receber_lista_Cliente = async () => {   
    try{ 
              Var_nome_cliente = Var_nome_cliente;               
              await api.get("/listar_clientes?nome=" +  Var_nome_cliente)
              .then((response) => { 
                  setLista(response.data);                     
              })          
        } catch(error){          
                console.log(error);
       }          
    } 



return(


    <div id="tela_inicial_V1"> 
        

        <div id="tela_da_esquerda_V1">
        
          
            
            <div>

                <form action={Acao_01}>

                    <div id="tela_55_V1">

                        <div>
                            <small className="text-secondary" id="css_400">Nome do Cliente</small>
                            
                            <input type="text" name="input_cliente" autoFocus  ref={myinput}/> 
                        </div>                                         

                        <div>
                            <button className="btn btn-outline-primary" id="largura_btn_50" type="submit">Consultar</button>
                            
                        </div>


                    </div>

                </form>
            
            </div>
                <br></br>                
                    
            <div>                        
                    <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
            </div>         
                 

        </div>

        <div id="tela_da_direita_V1">

            
                <br></br>
                <div id="css_organiza">
                    <br></br>
                    
                    <h2>Cliente: {nome_cliente}</h2> 
                    <Link to="/sub_menu" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>
                </div>

                <br></br>
                    
                <div>                        
                    <DataTable  columns={columns_2}                            
                        data={lista_2}    //dados de um DB
                        pagination = {true}         //Põe a paginação 
                        paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                </div> 

            

        </div>

    </div>
)

}

export default Historico_Clientes;

