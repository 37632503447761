import api from "../../services/api.js"

import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./pagamento.css";


import{use, useState} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import Swal from 'sweetalert2'; //Alertas

import {useRef} from 'react';

//import moment from "moment";

import DataTable from 'react-data-table-component';




function Pagamentos(){

    const { id_usuario} = use(MeuContexto); 

    const [lista, setLista] = useState([]);

    const [produto, setProduto] = useState('');

    const [ip_produto, setIp_produto] = useState(''); 


    const dataAtual = new Date();

    const dataFormatada = dataAtual.toString();

    var Data_1 = ''; 
    var Data_2 = '';
    var DT = '';

    const [valor_deste_Campo, setValor_deste_Campo] = useState('Valor Inicial');

    //const dataFormatada = dataAtual.toLocaleDateString();
    //const dataFormatada = dataAtual.toDateString()
    //const dataFormatada = dataAtual.toString();
    //const dataFormatada = dataAtual.toUTCString();
           
    const myinput = useRef();    

    const inputfocus = () => {
        myinput.current.focus();              
    }

    const myinput_2 = useRef();

    const inputfocus_2 = () => {
        myinput_2.current.focus();              
    }


    const [recebido, setRecebido] = useState([]);
    

    useEffect(() =>{

        inputfocus(); 
       
        DT = dataFormatada; 

        func_Pega_Data(DT);
        
        func_Receber_lista();

        setValor_deste_Campo(id_usuario);

        //setRecebido(id_usuario);

    },[]);

    //useEffect(() =>{  
    //    setRecebido(id_usuario);
    //},[valor_deste_Campo]);

    const func_Pega_Data = (DT) => {
        let Mes ='';
        if (DT.substring(4, 7) == 'Jan') {Mes='01'};
        if (DT.substring(4, 7) == 'Feb') {Mes='02'};
        if (DT.substring(4, 7) == 'Mar') {Mes='03'};
        if (DT.substring(4, 7) == 'Apr') {Mes='04'};
        if (DT.substring(4, 7) == 'May') {Mes='05'};
        if (DT.substring(4, 7) == 'Jun') {Mes='06'};
        if (DT.substring(4, 7) == 'Jul') {Mes='07'};
        if (DT.substring(4, 7) == 'Aug') {Mes='08'};
        if (DT.substring(4, 7) == 'Sep') {Mes='09'};
        if (DT.substring(4, 7) == 'Oct') {Mes='10'};
        if (DT.substring(4, 7) == 'Nov') {Mes='11'};
        if (DT.substring(4, 7) == 'Dec') {Mes='12'};
        
        //alert(DT.substring(11, 15) + "-" + Mes + "-" + DT.substring(8, 10)); 

        Data_1=DT.substring(11, 15) + "-" + Mes + "-" + DT.substring(8, 10);
        Data_2=Data_1;
    }   

    const [lista_2, setLista_2] = useState([]);

    const func_Pagamentos = async (Var_1) => {

        try { 
                Var_1 = Var_1; 
                await api.get("/traz_pagamento?pagamento=" + Var_1)
                  .then((response) => { 
                     setLista_2(response.data);                                                              
                  })                  
            } catch(error){          
                    console.log(error);
        }       
     }


     function Acao_01(ObterDados){ 
        const Var_1 = ObterDados.get("input_pagamento");
        func_Pagamentos(Var_1); 
        
     } 

     const [detalhes, setDetalhes] = useState('nada a dizer');

     function Acao_02(ObterDados){
        const Var_2 = ObterDados.get("input_valor");    //Var_2 é o valor a incluir e ip_produto está disponível
             
        cadastra_pagamento(Var_2, ip_produto);
        inputfocus();       
        
     }     

     function Acao_3(ObterDados){
         const Var_1 = ObterDados.get("input_detalhe")               
         setRecebido(Var_1) 
         inputfocus_2(); 
         //acao();       
     }

     const myinput_7 = useRef();

     const acao = () => {
        myinput_7.current.focus();
     }

     async function cadastra_pagamento(Var_2, ip_produto){

        var Var_Saldo='0';

        
        // Pega em Tab_contabil o saldo ultimo
        try{
                    await api.post("/traz_saldo")     
                    .then((response) => {                 
                        //setSaldo(response.data.saldo); 
                        Var_Saldo = response.data.saldo; 
                        Var_Saldo = Var_Saldo - Var_2;           
                    })               
            } catch(error){          
                console.log(error);
            }

          
        // cadastra pagamento em tab_contabil
        try{
              await api.post("/cadastrar_saidas" , { 
                    saldo: Var_Saldo,
                    pagamento: Var_2,
                    cod_tipo_pagamento: ip_produto,
                    detalhe_pagamento: recebido
                    })
                                    

            .then((retorno) => {                 
                //V_Id_Contabil = retorno.data.id_contabil;    //Pra trazer o codigo do registro Incluido               
                Swal.fire({icon: 'success',  title: 'Inclusão', text: 'realizada com Sucesso!'}) ; 
            })            

            } catch(error){          
                console.log(error);
            }             
            
       
}

const func_Receber_lista = async () => {   
    try{ 
                    
              await api.post("/listar_pagamentos" , { 
                data_inicio: Data_1,               
                data_fim: Data_2 
                })

              .then((response) => { 
                  setLista(response.data); 
                  
                  func_Receber_lista();

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

   

      const columns = [
        {
          cell: (row) => <>    
               <button className="btn btn-outline-primary" type="button" onClick={() => clickEstornar(row.cod_tipo_pagamento, row.valor)} id="botao_selecionar">Extornar</button>
          </>,
          width: "120px",   //largura 
          //compact: true,
          right: true   //Colocar Botão a Direita
        }, 
        {
          name: 'PAGAMENTO',
          selector: row => row.desc_pagamento,
          width: "300px",   //largura
          compact: true,  
          sortable: false            //cria ordenação 
          
      }, 
      {
          name: 'VALOR',
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor),
          width: "140px",   //largura 
          compact: true,  
          right: true,
          sortable: false            //cria ordenação
      },        
      {
          name: 'DATA',          
          selector: row => row.dia,
          width: "140px",  
          compact: true,  
          right: true,
          sortable: false            //cria ordenação 
      }
      ,        
      {
          //name: 'DATA',          
          selector: row => row.cod_tipo_pagamento,
          compact: true, 
          width: "0px",         
          sortable: false            //cria ordenação 
      }
  ]

  const columns_2 = [
    {
      cell: (row) => <>    
           <button className="btn btn-outline-primary" type="button" onClick={() => clickTrazIdPagamento(row.id_pagamento, row.pagamento)}  id="botao_selecionar">X</button>
      </>,
      width: "50px",   //largura 
      left: true   //Colocar Botão a Direita
    }, 
    {
      name: 'CÓDIGO',
      selector: row => row.id_pagamento,
      width: "120px",   //largura
      //compact: true,  
      sortable: false            //cria ordenação       
    }, 
    {
      name: 'PAGAMENTO',
      selector: row => row.pagamento,
      //width: "300px",   //largura
      compact: true,  
      sortable: false            //cria ordenação       
    }

]

function clickTrazIdPagamento(id_pagamento, desc_pagamento) {
    setIp_produto(id_pagamento);
    setProduto(desc_pagamento);
    acao();    
}

async function clickEstornar(cod_tipo_pagamento, valor){

    var Var_Saldo='0';
    var Var_Valor=(-1)*valor;

    
    // Pega em Tab_contabil o saldo ultimo
    try{
                await api.post("/traz_saldo")     
                .then((response) => {                 
                    //setSaldo(response.data.saldo); 
                    Var_Saldo = response.data.saldo; 
                    Var_Saldo = Var_Saldo - Var_Valor;           
                })               
        } catch(error){          
            console.log(error);
        }



    // cadastra Extorno em tab_contabil
    try{
          await api.post("/cadastrar_saidas" , { 
                saldo: Var_Saldo,
                pagamento: Var_Valor,
                cod_tipo_pagamento: cod_tipo_pagamento
                })
                                

        .then((retorno) => {                 
            //V_Id_Contabil = retorno.data.id_contabil;    //Pra trazer o codigo do registro Incluido               
            Swal.fire({icon: 'success',  title: 'Extorno', text: 'realizado com Sucesso!'}) ; 
        })            

        } catch(error){          
            console.log(error);
        }             
        
   
}

const onChangeCampo = e => {
    setValor_deste_Campo(e.target.value);                        
};



return(
    <div id="tela_inicial_T2">
        <div id="tela_da_esquerda_T2">
                <br></br> 
                <h3>Lançar Pagamentos</h3> 
                <br></br> 
                {/* <h5>{dataFormatada}</h5> */}       
                <br></br>
                <form action={Acao_01}> 
                    <div>
                        <small className="text-secondary">Nome do Pagamento</small> 
                        <br></br>
                        <input type="text" name="input_pagamento" ref={myinput}/> 

                    </div>
                </form>

                               
                <h5>Código: {ip_produto}</h5>                
                <h5>Serviço: {produto}</h5>                
                <br></br>



                <form action={Acao_3}>
                    <small className="text-secondary">Detalhe</small> 
                    <br></br>              
                    <input type="text" name="input_detalhe"
                        onChange={onChangeCampo} value={valor_deste_Campo}  ref={myinput_7} />             

                </form> 

                    <h5>Detalhe: {recebido}</h5>
                    <br/>             

                <form action={Acao_02}> 
                    <div>
                        <small className="text-secondary">Valor do Pagamento</small>
                        <br></br>
                        <input type="text" name="input_valor" ref={myinput_2}/>
                    </div>
                </form>

                <br></br>

               
                
                <div>                        
                    <DataTable  columns={columns_2}                            
                        data={lista_2}    //dados de um DB
                        //pagination = {true}         //Põe a paginação 
                        //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                </div>  


         </div>

         <div id="tela_da_direita_T2">
                <div id="css_separa_div">
                    <div>
                           <h3>Pagamentos do Dia</h3>
                    </div>
                    <div>
                            <Link to="/principal" className="btn btn-outline-primary" id="largura_botoes" >SAIR</Link>
                    </div>

                </div>
                                   
                    
                <div>                        
                    <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        //pagination = {true}         //Põe a paginação 
                        //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                </div> 

         </div>

    </div>
)

}

export default Pagamentos;




