export function Form () {
    return (
        <div>
            <link
                rel='stylesheet'
                href='https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.min.css'
            />
            <form>
                <input type='text' />
                <button type='submit'>Enter</button>
            </form>
        </div>
    )
}