import { useState, useActionState } from "react";
import { func_Atualiza_Nome } from "./AtualizaNome.js";

export function Actions_Exemplo () {
    const [result, setResult] = useState(null)

    const func_Recebe_Resultado = async (previousState, recebe_do_form) => {
        const Var_Nome = recebe_do_form.get('input_nome')

        const Recebido = await func_Atualiza_Nome(Var_Nome)

        if (Recebido) return Recebido
        setResult(Var_Nome)
        return null
       
    }

    const [Recebido, Acao, isPending] = useActionState(func_Recebe_Resultado)

    return (
        <form action={Acao}>
            <input
                name='input_nome'
                disabled={isPending}
                placeholder='Digite o Nome'            
            />

            <button disabled={isPending}>
                {isPending ? 'Carregando...' : 'Atualizar'}
            </button>

            {Recebido && <p>{Recebido}</p>}

            {result && !Recebido && <p> O Nome é {result}</p>}

        </form>
    )

}