import{use} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Swal from 'sweetalert2';
import {Navigate} from "react-router-dom";
import api from "../../services/api";
import { useState } from 'react';
import { useActionState } from "react";
import { useFormStatus} from 'react-dom';
import {useRef} from 'react';
import { mask, unMask } from 'remask';  //mascaras
import DataTable from 'react-data-table-component';

export function Indicar_Clientes() {

    const { nome, Logado, cod_cliente_cont, fone } = use(MeuContexto)

    const myinput = useRef(); 
    const inputfocus = () => {
        myinput.current.focus();              
    }

    const myinput_2 = useRef();
    const inputfocus_2 = () => {
        myinput_2.current.focus();              
    }

    const myinput_3 = useRef();
    const inputfocus_3 = () => {
        myinput.current.value = '';
        myinput_2.current.value = ''; 
        myinput_3.current.value = '';
        myinput_3.current.focus();              
    }
    
    useEffect(() =>{

        const Var_Cod_Indicador = cod_cliente_cont;

        if (Logado == false){
                Swal.fire({icon: 'error',  title: 'Atenção', text: 'O Usuario Não Está Logado!'})                                                   
            }        

    },[]);

   //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
    const [var_celular, setVar_Celular] = useState("");

    const onChangeCelular = e => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 99999-9999"                
        ]);
        setVar_Celular(maskedValue);        
    };
   //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
        
        const [var_CPF, setVar_CPF] = useState("");

        const onChangeCPF = e => {
            const originalValue = unMask(e.target.value);
            const maskedValue = mask(originalValue, [
                "999.999.999-99"                
            ]);
            setVar_CPF(maskedValue);        
        };
   //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

    const [var_Nome_Cliente, setVar_Nome_Cliente] = useState("");

    const onChange_Nome_Cliente = e => {
        setVar_Nome_Cliente(e.target.value);        
    };

   //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
    
    const func_incluir_cliente = async (previousState, traz) => {        
            
        const Var_Nome = traz.get('input_nome');       
        const Var_Celular = traz.get('input_celular');
        const Var_CPF = traz.get('input_cpf');
        const Var_Cod_Indicador = cod_cliente_cont;
                 
      
      if ( Var_Celular == '' || Var_CPF == '' || Var_Nome == '') 
        {
            inputfocus_3();
            Swal.fire({icon: 'error',  title: 'Operação Inválida!', text: 'Campo Obrigatório Não Prenchido!'}) ;           
            var Retorno_Cadastro;
            var Retorno_Grid; 
            var Retorno_Celular;
            var Retorno_CPF; 
        
        } else 
        {

            var strCPF = Var_CPF.match(/\d/g).join("");  //tira os pontos e traço do CPF
            const Retorno_Teste_CPF =  await TestaCPF(strCPF);
            
            if (!Retorno_Teste_CPF) { 
                inputfocus_2();
                Swal.fire({icon: 'error',  title: 'CPF', text: 'Inválido'}); 
            };            

            const Retorno_Celular = await func_filha_Cons_Celular(Var_Celular);

            if (Retorno_Celular!=undefined) { 
                    inputfocus(); 
                    Swal.fire({icon: 'error',  title: 'Operação Inválida!', text: 'Celular Já Dadastrado! É Necessário que seja um Cliente Novo!'}) ; 
            };        

            const Retorno_CPF = await func_filha_Cons_CPF(Var_CPF);

                if (Retorno_CPF!=undefined) { 
                    inputfocus_2(); 
                    Swal.fire({icon: 'error',  title: 'Operação Inválida!', text: 'CPF Já Cadastrado! É Necessário que seja um Cliente Novo!'}) ; 
                };
                
            const Retorno_Tab_Indicado = await func_filha_Ind_Cel_CPF(Var_Celular, Var_CPF);               

                if (Retorno_Tab_Indicado=='JA_INDICADO') { 
                    inputfocus(); 
                    Swal.fire({icon: 'error',  title: 'Operação Inválida!', text: 'CPF ou Celular de Cliente Já Indicado Anteriormente!'}) ; 
            };

            var Retorno_Cadastro;

            var Retorno_Grid;
            
            if (Retorno_Celular==undefined && Retorno_CPF==undefined && Retorno_Tab_Indicado=='NAO_INDICADO' && Retorno_Teste_CPF)    
            {             
                Retorno_Cadastro = await func_filha_Cadastrar(Var_Nome, Var_Celular, Var_CPF, Var_Cod_Indicador);

                Retorno_Grid = await func_GRID(Var_Cod_Indicador); 

                Swal.fire({icon: 'success',  title: 'Indicação', text: 'Realizada com Sucesso!'})
            } 
            
        }

        return {              
                CPF: Retorno_CPF,
                celular: Retorno_Celular,
                cadastro: Retorno_Cadastro,
                lista: Retorno_Grid,
        }        
       
    }

   const [leva, Acao_01] = useActionState(func_incluir_cliente, { Retorno_Celular: "", Retorno_Cadastro: "", Retorno_CPF:"", Retorno_Grid:[]})

//--------------------------------------------------------------------------------------------------------
  const [linkar, setLinkar] = useState('');

  function func_Voltar_Menu(){        
    setLinkar('menu');
  }
//-----------------------------------------------------------------------------


    return (    
        
        <div>

            {Logado === false ? <Navigate to='/encerrar' /> : null } 
            
                        <h3>Indicação de Clientes</h3>            
                        <br/>
                        <p>{nome}</p>
                        <p>{fone}</p>
           
                         <button type="button" onClick={func_Voltar_Menu}>SAIR</button>
                         {linkar === 'menu' ? <Navigate to='/Clientes_Menu' /> : null}           
            

                            <form action={Acao_01}> 
                                <div> 
                                    <br/>
                                    <h6>Indique Um Novo Cliente</h6>
                                    <br/>
                                    <small className="text-secondary">Nome do Indicado</small>       
                                    <br/>                                  
                                    <input type="text" name="input_nome" autoFocus onChange={onChange_Nome_Cliente} Value={var_Nome_Cliente} ref={myinput_3}/> 
                                    <br/>                            
                                    <small className="text-secondary">CPF</small>       
                                    <br/>                                  
                                    <input type="text" name="input_cpf" onChange={onChangeCPF} value={var_CPF} ref={myinput_2} />                                               
                                    <br/>                            
                                    <small className="text-secondary">Celular</small>       
                                    <br/>                                  
                                    <input type="text" name="input_celular" onChange={onChangeCelular} value={var_celular} ref={myinput}/>                                               
                                    <br/>  
                                    <br/>                                                                   
                                    <BOTAO/> 
                                    
                                    <h6>Celular: {leva.celular}</h6>  
                                    <h6>CPF: {leva.CPF}</h6> 
                                    <h6>Inclusao: {leva.cadastro}</h6>                               
                                    <br/>                                     
                                                                
                                    {leva.lista}                                                         

                                </div>

                            </form>                     

        </div> 
    
    ) 
    
}

//------------------------------------------------------------------------
function BOTAO(){    
    const { pending } = useFormStatus();        
    return(
       <div>
           <button type='submit' disabled={pending}>
               {pending ? "Executando..." : "Indicar"}
           </button> 
           
       </div>    
       )   
}
//--------------------------------------------------------
const func_filha_Cons_Celular = async (Var_Celular) => {

    const newText1 = Var_Celular.replace('-','');
    const newText2 = newText1.replace('(','');
    const newText3 = newText2.replace(')','');
    const Var_Celular_Limpo = newText3.replace(' ','');

    var Valor;
 
    try{        
        await api.post("/Consulta_Celular" , {
                X01: Var_Celular_Limpo
        })
        .then((response) => {
           
               if (response.data.celular==undefined)  
                {                           
                   // bla bla bla                 
                } 
                else
                {                                         
                    Valor = response.data.celular                                                   
                } 

        })     
        } catch(error){                             
                      Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                     
        } 

        return Valor;            

}
//--------------------------------------------------------------
const func_filha_Cons_CPF = async (Var_CPF) => {

    const newText4 = Var_CPF.replace('-','');
    const newText5 = newText4.replace('.','');
    const Var_CPF_Limpo = newText5.replace('.',''); 

    var Valor;
 
    try{        
        await api.post("/Consulta_CPF" , {
                X01: Var_CPF_Limpo
        })
        .then((response) => {
           
               if (response.data.CPF==undefined)  
                {                           
                                
                } 
                else
                {   
                    //Swal.fire({icon: 'error',  title: 'CPF!', text: 'Já Cadastrado!'}) ;                      
                    Valor = response.data.CPF                                     
                } 

        })     
        } catch(error){                             
                      Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                     
        } 

        return Valor;            

}
//----------------------------------------------------------------------------------
const func_filha_Cadastrar = async (Var_Nome, Var_Celular, Var_CPF, Var_Cod_Indicador) => {    

    var Valor;    
 
    try{ 

    await api.post("/cadastrar_indicado" , { 
    VAR01: Var_Nome,
    VAR02: Var_Celular,
    VAR03: Var_CPF,
    VAR04: Var_Cod_Indicador 
    })                          

    .then((response) => { 
        
            Valor = response.data.id_indicado;    //Pra trazer o codigo do registro Incluido            
            
    })
    } catch(error){                             
        Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                     
    }    

    //func_GRID(Var_Cod_Indicador);    
    
    return Valor;

} 
//-----------------------------------------------------------------------------------------------
const func_GRID = async (Var_Cod_Indicador) => {

    var Valor;
 
    try{        
       
        await api.get("/listar_indicados?VAR01=" +  Var_Cod_Indicador)
       
        .then((response) =>{

            Valor=response.data;           

        }) 

        } catch(error){          
            console.log(error);
        }

        const columns = [

            {
                name: 'Data',
                selector: row => row.DT,
                width: "120px",   //largura
                sortable: false            //cria ordenação 
                
            }, 
            {
                name: 'Indicado',
                selector: row => row.nome_indicado,
                width: "250px",   //largura 
                sortable: false            //cria ordenação
            },        
            {
                name: 'Celular',          
                selector: row => row.celular_indicado,
                width: "150px",  
                sortable: false            //cria ordenação 
            }
        ] 

        return(                             
                   <div>

                    <DataTable  columns={columns}                            
                        data={Valor}    //dados de um DB
                        // pagination = {true}         //Põe a paginação 
                        // paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                                    
                    </div>            
              )
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const func_filha_Ind_Cel_CPF = async (Var_Celular, Var_CPF) => {

    const newText1 = Var_Celular.replace('-','');
    const newText2 = newText1.replace('(','');
    const newText3 = newText2.replace(')','');
    const Var_Celular_Limpo = newText3.replace(' ','');

    const newText4 = Var_CPF.replace('-','');
    const newText5 = newText4.replace('.','');
    const Var_CPF_Limpo = newText5.replace('.','');

    var Valor;
 
    try{        
        await api.post("/Consulta_Tab_Indicado" , {
                X01: Var_Celular_Limpo,
                X02: Var_CPF_Limpo
        })
        .then((response) => {            
           
               if (response.data.CPF==undefined)  
                {                           
                    Valor = 'NAO_INDICADO';                 
                } 
                else
                {                                         
                    Valor = 'JA_INDICADO';                                                   
                } 

        })     
        } catch(error){                             
                      Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                     
        } 

        return Valor;            

}
//============================================================================================
function TestaCPF(strCPF) {

    var Soma;
    var Resto;

    var i;

    Soma = 0;
  if (strCPF == "00000000000") return false;

  for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

  Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}
//===================================================================================