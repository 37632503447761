import { useEffect, useState } from "react";
import { Dock } from "react-dock";
import "./dock_profissao.css";

import{use} from 'react';
import MeuContexto from "../../context/context";
import api from "../../services/api.js"

import Logo from "../../assets/abelha7.png";




function Dock_Profissao(){

    const {ShowDock1, setShowDock1, setProfissao} = use(MeuContexto);

    const [show, setShow] = useState(false); 
   
   
    useEffect(() => {
        setShow(ShowDock1);        
        //console.log('No Dock: ' + ShowDock1);        
    }, [ShowDock1]); 
    
    useEffect(() =>{
        func_Profissional();

    },[]);
    
    const [listaProfissional, setListaProfissional] = useState([]);

    const func_Profissional = async () => {
    try{            

            //Consulta Lista Profissionais
            await api.get("/profissao")
            .then((response) => { 
                setListaProfissional(response.data); 
                
                console.log(response.data)

            })

            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                console.log(error);
        }
    }

    function clickEditor(e){

        setProfissao(e.profissao);

        setShowDock1(false);

        //alert('Profissional: ' + e.profissao + ' ID: ' + e.cod_profissao);            
    }

    return <Dock position="right"
                 isVisible={show}
                 fluid={false}
                 size={380} 
                 
                 /* 
                 onVisibleChange={(visible) => {
                    setShow(visible);
                 }} 
                 */                
                

                 >
            

            <div id='css_hotel_primeiro'>


                    <div className="container-fluid">
                        <div id="cabeca">
                            <div>
                                <a className="navbar-brand" href="/">
                                    <img className="mt-1" src={Logo} alt="Delivery Mais" />
                                </a>
                            </div>
                            <div id="espaco_acima_botao"> 
                                        <button className="btn btn-outline-primary me-3" onClick={() => setShowDock1(false)} id="css_botao_sair6" >Fechar</button>                 
                                        
                            </div>
                        </div> 
                        <div id="centralizar">                   
                            <h4>Selecione sua Atividade</h4> 
                        </div>
                    </div>
                
                    <div id='css_profissao_primeiro'>



                        <table className="css_tabela_3">
                            <thead>
                        
                                <tr>
                                    <th id="ocultar_1">Código</th>                                    
                                    <th> </th>                                    
                                </tr>
                            </thead>

                            <div id='teste_4'>
                                {
                                    listaProfissional.map((e, i) =>                                        
                                         
                                            <tr onClick={() => clickEditor(e)} key={i}>                                            
                                                <td id="ocultar_1">{e.cod_profissao}</td>                                            
                                                <td className="btn btn-outline-primary me-3" id='largura_botao_7'>{e.profissao}</td>                                           
                                             </tr>                                    
                                                                  
                                    )
                                }
                            </div>

                        </table>


                        
                    </div>

            </div>                    

            </Dock>
            }

export default Dock_Profissao;


