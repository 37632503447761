
import {Link} from "react-router-dom";

import "./lista_produtos.css";

import api from "../../services/api.js"
import{use, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
//import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

//import Navbar_comissao from "../navbar/navbar_comissao.jsx";

import DataTable from 'react-data-table-component';

import { Dock } from "react-dock";

import { mask, unMask } from 'remask';  //mascaras


function Lista_Produtos() { 
    
    

    const {setFocus, show4, setShow4, nome_produto_cont, setProduto_cont, cod_produto_cont, setCod_produto_cont} = use(MeuContexto);

   
    const [linkar, setLinkar] = useState('');

//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

var Var_nome_produto='';

function Acao_2(ObterDados){
   
    Var_nome_produto = ObterDados.get("input_nome_produto")  

    func_Receber_lista();    
    
}

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const [lista, setLista] = useState([]);

const func_Receber_lista = async () => {   
    try{ 
              Var_nome_produto = '%' + Var_nome_produto + '%'; 

              //Consulta detalhe clientes indicados
              await api.get("/listar_produtos?produto=" +  Var_nome_produto)
              .then((response) => { 
                  setLista(response.data);                     
              })          
        } catch(error){          
                console.log(error);
    }       
 } 

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const columns = [

    {
        cell: (row) => <>                       
   
             <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar(row.cod_produto, row.produto)}  id="botao_selecionar">Selecionar</button>
             {linkar === 'Voltar_Vendas' ? <Navigate to='/vendas' /> : null}     

        </>,
        width: "180px",   //largura 
        left: true   //Colocar Botão a Direita
      }, 
      {
        //name: 'CÓDIGO',
        selector: row => row.cod_produto,
        width: "150px",   //largura
        sortable: false            //cria ordenação 
        
    }, 
    {
        //name: 'SERVIÇO',
        selector: row => row.produto,
        width: "400px",   //largura 
        sortable: false            //cria ordenação
    },        
    {
        //name: 'PREÇO',          
        selector: row => row.preco_venda,
        width: "180px",  
        sortable: false,            //cria ordenação 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.preco_venda)
    }
]

// Cria a Paginação
const paginationOptions = {
rowsPerPageText: 'Registros por Página',
rangeSeparatorText: 'de',
selectAllRowsItem: true,
selectAllRowsItemText: 'Todos'
};

//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo 
 //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
const [codigo_produto, setCodigo_produto] = useState('');
const [nome_produto, setNome_produto] = useState('');


function clickEditar(cod_produto, produto){   
  
    setCodigo_produto(cod_produto);    
  
    setNome_produto(produto);

    setShow4(false);

    setFocus("3");

    setLinkar('Voltar_Vendas'); 

}

setProduto_cont(nome_produto);
console.log(nome_produto_cont);

setCod_produto_cont(codigo_produto);
console.log(cod_produto_cont);




 //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo


 function clickFechar() {

    setCodigo_produto('');
    setNome_produto('');    
    setShow4(false)
    setFocus("3");

}

    
return <Dock position="right"
    isVisible={show4}
    fluid={false}
    size={1000}     
    >

{/* <div className="container-fluid h-100 pt-4 sidebar p-4"></div> */}

<div className="container-fluid h-100 pt-4 sidebar p-4 "> 

         
        
        <div id="ajuste11">
            <div>                       
                <h2 id="css_texto_inicial">Selecione o Serviço</h2>                                                   
            </div> 
            
            <div>                             

                <Link to="/vendas" className="btn btn-outline-primary me-3" id="ajuste12" onClick={clickFechar} >Sair</Link>
            </div>  
        </div>
    
                          

        
        <div>
        
            <form action={Acao_2}> 

                <h6>Serviço</h6>

                <input className="btn btn-outline-primary text-start" id='largura_botao' type="text" autoFocus name='input_nome_produto'/>     
                   
                 
            </form> 

        </div>
             <h6></h6>             
        <div>
        </div> 

        <div>                        
            <DataTable  columns={columns}                            
                data={lista}    //dados de um DB
                pagination = {true}         //Põe a paginação 
                paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                no noDataComponent={"Nenhum Registro Encontrado"}
                fixedHeader={true}  //fixa o cabeçalho se for true
                selectableRowsHighlight  //escurece a linha quando o maus em cima
                highlightOnHover         //junto com o de cima                            
             /> 
        </div> 

                            
       
</div>

</Dock>


}

export default Lista_Produtos;