
import {Link} from "react-router-dom";

import "./listar_devedores.css";

import api from "../../services/api.js"
import{useContext, useState} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
//import Footer from "../footer/footer";

import Swal from 'sweetalert2';    //Alertas

import {Navigate} from "react-router-dom";

import DataTable from 'react-data-table-component';

function Lista_Devedores() { 
    
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

useEffect(() =>{

    func_Receber_lista();
    
   
},[]);

//kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

const [lista, setLista] = useState([]);

const func_Receber_lista = async () => {
   
    try{            
             
              await api.get("/listar_devedores")
              .then((response) => { 
                  setLista(response.data);                     
              })    
             

          //Se der ERRO em qualquer das Consultas
        } catch(error){          
                console.log(error);
    }  
        
 } 

//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
//ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

const columns = [

    {
        cell: (row) => <>                       
   
             <button className="btn btn-outline-primary" type="button" onClick={() => clickEditar(row.id_contabil)}  id="botao_selecionar">Excluir</button>
             

        </>,
        width: "120px",   //largura 
        left: true   //Colocar Botão a Direita
      }, 
      {
        name: 'DATA',
        selector: row => row.dia,
        width: "150px",   //largura
        sortable: false            //cria ordenação 
        
    }, 
    {
        name: 'CLIENTE',
        selector: row => row.cliente,
        width: "250px",   //largura 
        sortable: false            //cria ordenação
    },        
    {
        name: 'VALOR',          
        selector: row => row.valor,
        width: "180px",  
        sortable: false,            //cria ordenação 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor)
    },        
    {
        //name: 'VALOR',          
        selector: row => row.id_contabil,
        width: "0px",  
        sortable: false            //cria ordenação 
    }
]


//oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo 
 //oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo


function clickEditar(e){                     
    
    Swal.fire({icon: 'question',  //success, error, warning, info, question 
            title: 'Quitar', 
            text: 'Esse Pagamento?',
            showDenyButton: true,
            denyButtonText:"Não",
            confirmButtonText: "Sim",
            confirmButtonColor: "blue",

        }).then(response => {
            if(response.isConfirmed){
  
                            Swal.fire({icon: 'success',  title: 'Pagamento', text: 'Registrado como Quitado'}); 
                            
                            alteracao(e);                                    

            }else if(response.isDenied){
                    //Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
            }               
        }) 

}

async function alteracao(e){

    await api.put("/Atualiza_Quitacao", {               
        foda_se: e
        
        })
        .then((retorno) => {
        //alert("Alteração Realizada")
        })
        .catch(err => console.log(err));        
        
        func_Receber_lista();

    }

 //ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

 return (

    <div id="devedores_principal"> 

        <div id="Separa_Lista_Botao">
            <div> 
                    <h3>Lista de Devedores</h3> 
                    <br></br>      

            </div>
            <div>
                                                                    
                    <Link to="/sub_menu" className="btn btn-outline-primary" id="largura_botoes" >Sair</Link>  
                    <br></br>                       
                                                                    
            </div>           
        </div>
        
 
            

        <div>                        
            <DataTable  columns={columns}                            
                data={lista}    //dados de um DB
                //pagination = {true}         //Põe a paginação 
                //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                no noDataComponent={"Nenhum Registro Encontrado"}
                fixedHeader={true}  //fixa o cabeçalho se for true
                selectableRowsHighlight  //escurece a linha quando o maus em cima
                highlightOnHover         //junto com o de cima                            
             /> 
        </div>                             
       
     </div>

)
}

export default Lista_Devedores;