
import axios from "axios";

// const URL = "http://192.168.0.24:3001";  // La em casa
//const URL = "http://18.233.4.85:3000";   // Na Nuvem AWS 

const URL =  "http://bhoras.com.br:3000";     //esse link leva ao BD Nuvem - AWS 

//const URL =  "http://localhost:3000"; 

const api = axios.create({
  baseURL: URL,
  auth: {
      username: "ocelio",
      password: 'brumaLU2@#$'
  }
});

export default api;

//---------------------------------------------------------------------

export const URL_2 = 'http://bhoras.com.br:3000';

const username = 'ocelio';
const password = 'brumaLU2@#$';

export const headers_2 = {
  'Content-Type': 'application/json', 
  'Authorization': "Basic " + btoa(`${ username}:${password}`)
};

//----------------------------------------------------------------------




