import {useState} from 'react';
import api from "../../services/api.js"
import {Link} from "react-router-dom";
import "./incluir.css";
import Loading from "../../components/loading.jsx"

import Swal from 'sweetalert2';


function Incluir(){
             
        
        const [Var_Input2, set_Var_Input2] = useState("");
        const [Var_Input3, set_Var_Input3] = useState("");

        const [removeLoading, setRemoveLoading] = useState(true);

     
        function func_Incluir(){

            Swal.fire({icon: 'question',  //success, error, warning, info, question  
                    title: 'Pergunta', 
                    text: 'Deseja realmente Incluir esse registro?',
                    showDenyButton: true,
                    denyButtonText:"Não",
                    confirmButtonText: "Sim",
                    confirmButtonColor: "blue",

                }).then(response => {
                    if(response.isConfirmed){

                            setRemoveLoading(false);

                            api.post("/empresas_incluir" , {               
                                localidade: Var_Input2,
                                uf: Var_Input3
                            })
                            .then((retorno) => {  
                                    
                                    setRemoveLoading(true); 

                                    Swal.fire({icon: 'success',  title: 'Inclusão', text: 'Realizada com Sucesso'})              
                
                            })
                            .catch(err => console.log(err));
                           

                    }else if(response.isDenied){
                            Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
                    }               
                }) 

        }
    
        return <div>              

            <div className="Elemento_Fundo">

                <h1>Incluir</h1>  
                <Link to="/" className="btn-voltar">Voltar</Link>                        
                <br></br>
                <br></br>


                <h3>Nome da Empresa a Incluir</h3> 
                <input type="text" onChange={(e)=>set_Var_Input2(e.target.value)} />                                        
                <br></br> <br></br>

                <h3>UF da Empresa a Incluir</h3> 
                <input type="text" onChange={(e)=>set_Var_Input3(e.target.value)} />                                        
                <br></br> <br></br>

                <button type="button" className="btn-voltar" onClick={func_Incluir}>Incluir Registro</button>
                <br></br> <br></br>  

                        <div className="Elemento_Frente">
                        {!removeLoading && <Loading />}
                        </div>    

            </div> 

    
        </div>

}

export default Incluir;









