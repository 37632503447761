export const func_Atualiza_Nome = (name) => {
    //simula pedido
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (name.length < 3) {
                resolve('Nome com menos de 3 letras')
                return
            }
            resolve()
        },3000)
    })   
}

export const getData = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                success: true,
                data: 'ok'
            })
        }, 2000)
    })
}