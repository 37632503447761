
import {Link} from "react-router-dom"
import "./teste-2.css";

import Footer from "../footer/footer.jsx";

import AnimatedPage1 from "../../AnimatedPage1.jsx";



function Teste_2(){

   

    return (
        
    <div>

        <AnimatedPage1> 
        
        <section  id="id_css">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 css-teste2">
                      <img src="imagens/botao-x.png"/>
                      <h3>Fácil de Usar</h3>
                      <p>Tudo é importante na Vida</p>
                    </div>

                    <div className="col-lg-6 css-teste3">

        
                       <img src="imagens/sair77.png"/>
                       <h3>Não há nada difícil</h3>
                       <p>É só partir para o abraço</p>  


                    </div>                     

                </div> 
            </div> 
        </section>

        <div id="css_voltar">
            <Link to="/" className="btn btn-warning">Voltar</Link> 
            <Link to="/" className="btn btn-primary">Em Frente</Link>




        </div>

        <div id="css_q">
            <div id="css_q1">Q1 xxxx yyyy de verdade</div> 
            <div id="css_q2">Q2 xxxx yyyy</div>  
            <div id="css_q3">Q3 xxxx yyyy</div>   
  
        </div>

        </AnimatedPage1> 

        <Footer />                
         
    </div> 

    )
}

export default Teste_2;
