import{use} from 'react';
import MeuContexto from '../../context/context.jsx';
import { useEffect } from "react";
import Swal from 'sweetalert2';
import {Navigate} from "react-router-dom";
import api from "../../services/api";
import {useState } from 'react';
import { useFormStatus} from 'react-dom';
import {useRef} from 'react';

//--------------------------------------------------------------------

export function Alterar_Senha() {

    const { nome, Logado, fone, cod_cliente_cont } = use(MeuContexto)

    const myinput_1 = useRef();    

    const inputfocus_1 = () => {
        myinput_1.current.focus();              
    }
//-----------------------------------------------------------------------
    useEffect(() =>{            
        if (Logado == false){
                Swal.fire({icon: 'error',  title: 'Atenção', text: 'O Usuario Não Está Logado!'})                                                  
           } 
           inputfocus_1();                   
    },[]);     

//--------------------------------------------------------------------------------------------------------
const [linkar, setLinkar] = useState('');

function func_Voltar_Menu(){        
  setLinkar('menu');
}
//--------------------------------------------------------------------------------------------------------
 
const Acao_01 = async (traz) => {         
            
    const Var_Senha_1 = traz.get('input_senha_1');       
    const Var_Senha_2 = traz.get('input_senha_2');

    if ( Var_Senha_1 == Var_Senha_2)
    {
        const Resultado = await func_Senha_Alterar(Var_Senha_1, cod_cliente_cont); 

        if (Resultado=='ALTERADO') {              
            Swal.fire({icon: 'success',  title: 'Senha', text: 'Alterada com Sucesso!'}) 
            func_Voltar_Menu();
        };     

    }  
    else
    {
        inputfocus_1();
        Swal.fire({icon: 'error',  title: 'Repita a Operação', text: 'A Senha de Confirmação foi Diferente'}); 
    }        
   
}

//------------------------------------------------------------------------------------------


return(
    <div>
        <h4>Página de Alteração de Senha!</h4>

        {Logado === false ? <Navigate to='/encerrar' /> : null } 

        <br/>
        <p>{nome}</p>  
        <p>{fone}</p>           
        <br/>

        <button type="button" onClick={func_Voltar_Menu}>SAIR</button>
        {linkar === 'menu' ? <Navigate to='/Clientes_Menu' /> : null} 
        <br/>

        <form action={Acao_01}>         

            <small className="text-secondary">Senha</small>       
            <br/>                                  
            <input type="text" name="input_senha_1"  ref={myinput_1}/>                                               
            <br/>                            
            <small className="text-secondary">Confirmar Senha</small>       
            <br/>                                  
            <input type="text" name="input_senha_2"/>                                               
            <br/>  
            <br/>                                                                   
            <BOTAO/>
            {linkar === 'menu' ? <Navigate to='/Clientes_Menu' /> : null} 

        </form> 

    

    </div>   
) 

}

//------------------------------------------------------------------------
function BOTAO(){    
    const { pending } = useFormStatus();        
    return(
       <div>
           <button type='submit' disabled={pending}>
               {pending ? "Salvando..." : "Salvar"}
           </button> 
           
       </div>    
       )   
}
//--------------------------------------------------------
const func_Senha_Alterar = async (Var_Senha_1, cod_cliente_cont) => {    

    var Valor;  
 
    try{ 

        await api.put("/Altera_Senha_Cliente" , { 
        VAR01: Var_Senha_1,
        VAR02: cod_cliente_cont

        })             

        .then((response) => {         
                Valor = response.data.leva_pro_Front;    //Pra trazer o codigo do registro Incluido           
        })

    } catch(error){                             
        Swal.fire({icon: 'error',  title: 'ERRO !', text: 'Verifique sua Conexão a Internet...'}) ;                     
    }       
    
    return Valor;

}
//----------------------------------------------------