


import { Dock } from "react-dock";
import "./dock_hotel.css";
import{use} from 'react';

import MeuContexto from "../../context/context";
import api from "../../services/api.js"
import Navbar_Hotel from "../navbar/navbar_hotel";

import { useEffect, useState } from "react";

import Logo from "../../assets/abelha7.png";



function Dock_Hotel(){

    const {ShowDock2, setShowDock2, setHotel} = use(MeuContexto);
   

    const [show, setShow] = useState(false);   
   
    useEffect(() => {
        setShow(ShowDock2);        
        //console.log('No Dock: ' + ShowDock1);        
    }, [ShowDock2]); 

    
    
    useEffect(() =>{
       func_Hotel();
    },[]);

    
    const [ListaHotel, setListaHotel] = useState([]);

    const func_Hotel = async () => {
    try{            

            //Consulta Lista Profissionais
            await api.get("/empresas?uf=")     //se tirar ?uf=ce pega todos os UF 
            .then((response) => { 
                setListaHotel(response.data); 
                
                console.log(response.data)

            })

            //Se der ERRO em qualquer das Consultas
            } catch(error){          
                console.log(error);
        }
    }

    function clickEditor3(e){

        setHotel(e.id_localidade);

        setShowDock2(false);

        //alert('id: ' + e.id_localidade + ' Hotel: ' + e.localidade);            
    }

    return <Dock position="right"
                 isVisible={show}
                 fluid={false}
                 size={380} 
                 
                        
                 

                 >
            

            <div id='css_hotel_primeiro'>

                    {/* <Navbar_Hotel/> */}


                    <div className="container-fluid">
                        <div id="cabeca">
                            <div>
                                <a className="navbar-brand" href="/">
                                    <img className="mt-1" src={Logo} alt="Delivery Mais" />
                                </a>
                            </div>
                            <div id="espaco_acima_botao"> 
                                        <button className="btn btn-outline-primary me-3" onClick={() => setShowDock2(false)} id="css_botao_sair6" >Voltar</button>                 
                            </div>
                        </div> 
                        <div id="centralizar">                   
                            <h4>Selecione o seu Hotel</h4> 
                        </div>
                    </div>



                    <div>                        

                        <table className="css_tabela_33">
                            <thead>
                                <tr>
                                    <th id="ocultar_2">Código</th>                                    
                                    <th></th> 
                                    <th></th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ListaHotel.map((e, i) => 
                                        <tr onClick={() => clickEditor3(e)} key={i}>                                            
                                            <td id="ocultar_2">{e.id_localidade}</td>                                            
                                            <td className="btn btn-outline-primary me-3" id='margenzinha'>{e.localidade} - {e.uf}</td>  
                                                                                  
                                        </tr>                                   
                                    )
                                }
                            </tbody>
                        </table>




                        
                    </div>

            </div>                    

            </Dock>
            }

export default Dock_Hotel;


