import api from "../../services/api.js"

import {Navigate} from "react-router-dom";

import {Link} from "react-router-dom";

import "./comissoes.css";

import{useState} from 'react';

import DataTable from 'react-data-table-component';

import Swal from 'sweetalert2';    //Alertas

import { Dock } from "react-dock";

import {useRef} from 'react';




function Comissoes(){

    const [dt_pedido, setDtPedido] = useState("");

    const [dt_pedido_2, setDtPedido_2] = useState("");

    const [total_venda, setTotal_venda] = useState("0,00");
    const [total_comissao, setTotal_comissao] = useState("0,00");

    const [lista, setLista] = useState([]);

    const [lista_2, setLista_2] = useState([]);

    const [nome_profissional, setNome_profissional] = useState("")

    const [show, setShow] = useState(false);

       

    var Data_1 = ''; 

    var Data_2 = ''; 
    
    
            //Só para focar nos inputs
            const myinput_7 = useRef(); 
   
            const inputfocus_2 = () => {
                myinput_7.current.focus();              
            }
    
const func_Receber_lista = async () => {   
    try{ 
                    
              await api.post("/listar_comissoes" , { 
                data_inicio: Data_1,               
                data_fim: Data_2 
                })

              .then((response) => { 
                  setLista(response.data);
                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

      const func_Receber_Totais = async () => {   
        try{ 
                        
                  await api.post("/Traz_Total_Venda_Comissao" , { 
                    data_inicio: Data_1,               
                    data_fim: Data_2 
                    })
    
                  .then((response) => {
                       
                       //setTotal_venda(response.data.venda_total);
                       setTotal_venda(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.venda_total));

                       //setTotal_comissao(response.data.comissao_total); 
                       setTotal_comissao(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.comissao_total));
                                
    
                  })            
                 } catch(error){          
                    console.log(error);
                    }       
          } 



const clickConsultar = async () => { 
    Data_1 = dt_pedido;
    Data_2 = dt_pedido_2;
    await func_Receber_lista();    
    await func_Receber_Totais();
}

const clickConsultar_2 = async (e) => { 
    Data_1 = dt_pedido;
    Data_2 = dt_pedido_2;    
    await func_Receber_lista_2(e);    
    //await func_Receber_Totais();
}

const func_Receber_lista_2 = async (e) => {   
    try{ 

        //pegar o nome
        var PosInicial=e.indexOf("NOME")+4
        var PosFinal=e.indexOf(" FIM")     
        setNome_profissional(e.substring(PosInicial, PosFinal))

        
              //alert(e);
                    
              await api.post("/listar_comissoes_detalhada" , { 
                data_inicio: Data_1,               
                data_fim: Data_2,
                foder_e_bom: e
                })

              .then((response) => { 
                  setLista_2(response.data);                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

   

const columns = [
        {
          cell: (row) => <>    
               <button className="btn btn-outline-primary" type="button" onClick={() => clickConsultar_2(row.cod_empregado + 'NOME' + row.empregado + ' FIM')} id="botao_selecionar">X</button>
          </>,
          width: "65px",   //largura 
          //compact: true,
          right: true   //Colocar Botão a Direita
        }, 
        {
          name: 'PROFISSIONAL',
          selector: row => row.empregado,
          compact: true,
          width: "150px",   //largura
          sortable: true            //cria ordenação 
          
      }, 
      {
          name: 'VENDA',
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.venda),
          width: "120px",   //largura 
          compact: true,
          right: true,
          sortable: true            //cria ordenação
      },        
      {
          name: 'COMIS.',          
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.comissao),
          //compact: true,
          width: "120px",  
          right: true,
          sortable: true            //cria ordenação 
      },
                 
      {
          name: 'cod_empregado',  
          compact: true,        
          selector: row => row.cod_empregado,
          width: "0px"         
                
      }
    

]

const [id_contabil, setId_Contabil] = useState("")
const [cliente, setCliente] = useState("")
const [servico, setServico] = useState("")
const [comissao, setComissao] = useState("")

const clickExecutar = async (e) => { 

        inputfocus_2();   

        setShow(true);

        //pegar o Código
        var PosInicial=e.indexOf("inicio")+6
        var PosFinal=e.indexOf("cliente")     
        setId_Contabil(e.substring(PosInicial, PosFinal))

        //pegar o Cliente
        var PosInicial=e.indexOf("cliente")+7
        var PosFinal=e.indexOf("servico")     
        setCliente(e.substring(PosInicial, PosFinal))

        //pegar o Serviço
        var PosInicial=e.indexOf("servico")+7
        var PosFinal=e.indexOf("comissao")     
        setServico(e.substring(PosInicial, PosFinal))

        //pegar o Serviço
        var PosInicial=e.indexOf("comissao")+8
        var PosFinal=e.indexOf("FIM")     
        //setComissao(e.substring(PosInicial, PosFinal))

        setComissao(Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.substring(PosInicial, PosFinal)))
     



}

const columns_2 = [
    {
      cell: (row) => <>    
           <button className="btn btn-outline-primary" onClick={() => clickExecutar("inicio" + row.id_contabil  + "cliente" + row.nome + "servico" + row.produto + "comissao" + row.comissao + "FIM")} type="button" id="botao_selecionar">Extornar</button>
      </>,
      width: "100px",   //largura 
      compact: true,
      right: true   //Colocar Botão a Direita
    }, 
    {
      name: 'DATA',
      selector: row => row.data_comissao,
      width: "100px",   //largura
      compact: true,      
      right: true,
      sortable: true            //cria ordenação 
      
  }, 
  {
      name: 'CLIENTE',
      selector: row => row.nome,
      maxwidth: "250px",   //largura 
      //compact: true,   
      sortable: true            //cria ordenação
  },        
  {
      name: 'SERVIÇO',          
      selector: row => row.produto,
      maxwidth: "400px",   
      compact: true,    
      sortable: true            //cria ordenação 
  },       
  {
      name: 'COMIS.',          
      selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.comissao),
      width: "130px", 
      //compact: true, 
      right: true,
      sortable: true            //cria ordenação 
  }
  ,       
  {
      name: '',          
      selector: row => row.id_contabil,
      width: "0px",  
      compact: true,
      right: true,
      sortable: true            //cria ordenação 
  }


]

function Acao_02(ObterDados){ 
    
        var Var_2 = ObterDados.get("input_comissionado")       
                         
        func_traz_comissionado(Var_2);        
   
}

const [Var_input7, setVar_input7] = useState("");
const [Var_input8, setVar_input8] = useState("");

const func_traz_comissionado = async (Var_2) => {

    try{
    
      await api.post("/traz_comissionado" , {
           cod_empregado: Var_2
      })
      .then((response) => {                                                   
          
        setVar_input7(response.data.cod_empregado); 
        setVar_input8(response.data.nome_empregado); 
       
          
      }) 

        } catch(error){    
                            clickFechar()                        
                            Swal.fire({icon: 'error',  title: 'Erro', text: 'Código do Comissionado Inválido!'}) ;
            }

 }

async function Altera_Comissionado() {

    clickFechar();

   // alert("cod_comissionado_novo: " + Var_input7 + " id_contabil " + id_contabil)

    Swal.fire({icon: 'question',  //success, error, warning, info, question  
    title: 'Confirma', 
    text: 'O Repasse da Comissão?',
    showDenyButton: true,
    denyButtonText:"Não",
    confirmButtonText: "Sim",
    confirmButtonColor: "blue",             

}).then(response => {
    if(response.isConfirmed){ 
            
     //---------------------------------------------------------------------------

     alteracao();   
     

    //------------------------------------------------------------------------                                                

    } 
    else 
    if(response.isDenied){
            Swal.fire({icon: 'info',  title: 'Tudo bem', text: 'Operação Abortada'})
    }               
}) 

async function alteracao(){
    await api.put("/Atualiza_Cod_Comissionado", {               
        cod_comissionado: Var_input7,
        id_contabil: id_contabil
        })
        .then((retorno) => {
        //alert("Alteração Realizada")
        })
        .catch(err => console.log(err));
        
        
        await clickConsultar();
        //
        setLista_2('');        

}
     


}



function Entra_tela(){

    return <Dock position="right"
            isVisible={show}
            fluid={false}
            size={700}     
            >
            
            <br></br>

            <div id="css_400" className="container-fluid h-100 pt-4 sidebar p-4 ">
                <h4>Cliente: {cliente}</h4>
                <h4>Serviço: {servico}</h4>
                <h4>Comissão: {comissao}</h4>
                <br></br>                
                <h4>Para transferir a comissão acima para outro Profissional,</h4>
                <h4>Digite o Códico do Novo Profissional e tecle ENTER.</h4>                
                <h4>Em seguida, pressione o Botão TRANSFERIR</h4>
                <br></br>
                
                <form action={Acao_02}> 
                               
                    <small className="text-secondary">Código do Profissional</small>            
                    <br></br>
                    <input type="text" name="input_comissionado" defaultValue='' autoFocus ref={myinput_7}/>
                            
                </form>

                <br></br>
                <h4>Código: {Var_input7} </h4>
                <br></br>
                <h4>Código: {Var_input8} </h4>
                <br></br> 
                
                <br></br>
                <br></br>
                <button className="btn btn-outline-primary" onClick={() => Altera_Comissionado()} type="button" id="botao_selecionar">TRANSFERIR</button>                    
                     
                <br></br>
                <br></br>
                <button className="btn btn-outline-primary" onClick={() => clickFechar()} type="button" id="botao_selecionar">Fechar</button>
                  
            </div>
    </Dock>

 }  
 
 function clickFechar(){
     setShow(false)   
 }




  

return(


    <div id="tela_inicial_V1">        
        

        <div id="tela_da_esquerda_V1">
            

            <div id="tela_55_V1">
                  <div>
                        <h4>Vendas</h4> 
                        <h4>{total_venda}</h4>   
                 </div> 
                 <div>
                         <h4>Comissões</h4>  
                         <h4>{total_comissao}</h4>   
                 </div> 

            </div>

            <br></br>
            <br></br>
            

           
          
            
            <div id="tela_55_V1">
                <div id="css_500">   
                        <div id="css_501">
                             <input type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido}    
                        </div>                   
                </div>

                <div id="css_500">   
                        <div id="css_501">
                            <input type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido_2}   
                        </div>                   
                </div>

                           

                <div>
                       <button className="btn btn-outline-primary" id="largura_btn_50"  onClick={clickConsultar} type="button">Consultar</button>
                </div>


            
            </div>
                <br></br>

                
                    
                <div>                        
                    <DataTable  columns={columns}                            
                        data={lista}    //dados de um DB
                        //pagination = {true}         //Põe a paginação 
                        //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                        no noDataComponent={"Nenhum Registro Encontrado"}
                        fixedHeader={true}  //fixa o cabeçalho se for true
                        selectableRowsHighlight  //escurece a linha quando o maus em cima
                        highlightOnHover         //junto com o de cima                            
                    /> 
                </div>         
                 

        </div>

        <div id="tela_da_direita_V1">

            <Entra_tela /> 
                <br></br>
                <div id="css_organiza">
                    <br></br>
                    
                    <h2>Profissional: {nome_profissional}</h2> 
                    <Link to="/principal" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>
                </div>

            <br></br>
                    
                    <div>                        
                        <DataTable  columns={columns_2}                            
                            data={lista_2}    //dados de um DB
                            //pagination = {true}         //Põe a paginação 
                            //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                            no noDataComponent={"Nenhum Registro Encontrado"}
                            fixedHeader={true}  //fixa o cabeçalho se for true
                            selectableRowsHighlight  //escurece a linha quando o maus em cima
                            highlightOnHover         //junto com o de cima                            
                        /> 
                    </div> 

            

        </div>

    </div>
)

}

export default Comissoes;

